import React, { useState, useEffect } from "react";
import "../../assets/css/seller-add-products.css";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import {
  GetSellerProductAttributes,
  GetSellerProductSpecificAttributes,
  PostSellerProduct,
} from "../../network/Network";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CircleLoad from "../../components/Loader/CircleLoad";
import InputField from "../../components/Form/InputField";
import { GrAddCircle } from "react-icons/gr";
import { RiCloseCircleLine, RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import AddAttributeValueModal from "../../components/Modal/AddAttributeValueModal";

import Select from "react-select";

//  for testing puprose
import { KeyboardEventHandler } from "react";
import CreatableSelect from "react-select/creatable";
import SellerAttributes from "../../components/Seller/SellerAttributes";
import ProductVariantCombination from "../../components/Seller/ProductVariantCombination";
//  for testing puprose

const Seller_add_product = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const CategoriesData = useSelector((state) => state.AuthReducer.categories);
  const [loading, setLoading] = useState(false);
  const [selectCategory, setSelectCategory] = useState();
  const [selectSubCategory, setSelectSubCategory] = useState();
  const [logo, setLogo] = useState("");
  const [logoFileupload, setLogoFileupload] = useState("");
  const [multiImageFiles, setMultiImageFiles] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [slug, setSlug] = useState();
  const [checkState, setCheckState] = useState(false);
  const [stock, setStock] = useState(0);
  const [userData, setUserData] = useState({
    name: "",
    unitPrice: "",
    purchasePrice: "",
    discount: "",
    description: "",
    shortDescription: "",
  });

  // Dynamic Product Variations Start
  const [attributeData, setAttributeData] = useState([]);
  const [getMainAttributeData, setMainAttributeData] = useState([]);
  const [getAttributeValuesData, setAttributeValuesData] = useState([]);
  const [variantData, setVariantData] = useState([
    {
      type: "",
      price: "",
      qty: "",
      sku: "",
    },
  ]);
  const [getAttributeWithValues, setAttributeWithValues] = useState([]);
  const [variations, setVariations] = useState([]);
  const [isOpenAttributeValuetModal, setIsOpenAttributeValuetModal] =
    useState(false);
  const [attributeValueMessage, setAttributeValueMessage] = useState();
  const [attributeValueLoad, setAttributeValueLoad] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [getAttributesChildren, setAttributesChildren] = useState([]);
  const [processedOptions, setProcessedOptions] = useState([]);
  const [choices, setChoices] = useState([
    {
      type: "",
      options: [],
    },
  ]);

  const [getIsNew, setIsNew] = useState([
    {
      type: "",
      options: [
        {
          value: "",
          isNew: false,
        },
      ],
    },
  ]);

  const [getFinalAttValues, setFinalAttValues] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);
  const [children, setChildren] = useState([]);
  const [inputValues, setInputValues] = useState([]);

  const [attributeMenu, setAttributeMenu] = useState(false);
  // Dynamic Product Variations End

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  }

  const HandleLogoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setLogo(file);
      setLogoFileupload(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setMultiImageFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
      Array.from(e.target.files).map((file) => {
        setUploadImages((preVal) => {
          return [...preVal, file];
        });
      });
    }
  };

  const RemoveMultiImg = (e, photo, index) => {
    multiImageFiles.splice(index, 1);
    setMultiImageFiles([...multiImageFiles]);
  };
  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <li>
          {" "}
          <img
            src={photo}
            alt=""
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
          <RiCloseCircleLine
            style={{ position: "absolute" }}
            onClick={(e) => RemoveMultiImg(e, photo, index)}
          />
        </li>
      );
    });
  };

  // OverAllDynamic Product Variations Functionility Start
  const getAttributeValues = (value) => {
    setAttributesChildren(value);
  };

  // Generate Variations ( Combinations ) Start
  const generateVariations = () => {
    const generatedVariations = [];

    const propertyOptions = getAttributeWithValues.map((obj) => {
      const property = Object.keys(obj)[0];
      const values = obj[property];
      return { property, values };
    });

    const generateCombinations = (options, currentCombination = []) => {
      if (options.length === 0) {
        generatedVariations.push(currentCombination);
        return;
      }
      const { property, values } = options[0];
      values.forEach((value) => {
        const newCombination = { ...currentCombination };

        if (Array.isArray(newCombination[property])) {
          newCombination[property].push(value);
        } else {
          newCombination[property] = [value];
        }

        generateCombinations(options.slice(1), newCombination);
      });
    };

    generateCombinations(propertyOptions);

    setVariations(generatedVariations);
  };

  useEffect(() => {
    generateVariations();
  }, [getAttributeWithValues, choices]);
  // Generate Variations ( Combinations ) End

  useEffect(() => {
    let total = 0;
    if (variantData?.length > 0) {
      for (let i = 0; i < variantData?.length; i++) {
        total = total + parseInt(variantData[i]?.qty);
      }
    }
    setStock(total);
  }, [variantData]);

  const variantDataHandler = (e, index) => {
    const { name, value } = e.target;
    setVariantData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [name]: value,
        type: combArray[index]?.type,
        sku: combArray[index]?.type,
      };
      return newData;
    });
  };

  const createAttributeValueHandler = (e) => {
    e.preventDefault();
    setIsOpenAttributeValuetModal(false);
  };

  useEffect(() => {
    GetSellerProductAttributes(Token)
      .then((res) => {
        setAttributeData(res?.data?.response?.data);
        const getAttribute = res?.data?.response?.data?.map((attr) => {
          const attributeLabel =
            attr?.name?.charAt(0)?.toUpperCase() + attr?.name?.slice(1);
          return {
            value: attr?.name,
            label: attributeLabel,
          };
        });
        setMainAttributeData(getAttribute);
      })
      .catch((err) => {});
  }, []);

  const attributeMainHandler = (option, index) => {
    // Check if the option has already been processed
    if (processedOptions.includes(index)) {
      return; // Exit the function if it has already been processed
    }

    // Add the index to the processedOptions state
    setProcessedOptions([...processedOptions, index]);

    // Rest of the function logic...
    GetSellerProductSpecificAttributes(Token)
      .then((res) => {
        const result = res?.data?.response?.data;
        const filteredResults = result?.filter(
          (attribute) => attribute?.name === option
        );

        const removeArrayFilteredResults = Object.assign(
          {},
          ...filteredResults
        );

        const setAttributesOptions =
          removeArrayFilteredResults?.att_values?.map((attr) => {
            const attributeLabel =
              attr?.name?.charAt(0)?.toUpperCase() + attr?.name?.slice(1);
            return {
              value: attr?.name,
              label: attributeLabel,
            };
          });

        setAttributeValuesData((prevData) => {
          const newData = [...prevData];
          newData[index] = {
            ...newData[index],
            setAttributesOptions,
          };
          return newData;
        });

        const filiteredAttribute = result?.filter(
          (attribute) => attribute?.name === option
        );

        const removeArrayFiliteredAttribute = Object.assign(
          {},
          ...filiteredAttribute
        );
        const finalAttValues = removeArrayFiliteredAttribute?.att_values?.map(
          (attValues) => {
            return {
              value: attValues?.name,
              label: attValues?.name,
            };
          }
        );

        setFinalAttValues(finalAttValues);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const generateDataForVariations =
      getAttributesChildren !== undefined &&
      getAttributesChildren?.map((attribute, index) => {
        return {
          generatedvarients: attribute?.map((value, index2) => {
            return value?.label;
          }),
        };
      });
    setAttributeWithValues(generateDataForVariations);
  }, [getAttributesChildren]);

  // filter choices and getIsNew on the basis of selected options
  useEffect(() => {
    let updatedChoices = [...choices];
    // Convert type to lowercase before comparison
    const selectedTypes = selectedOption
      ? selectedOption.map((option) => option.value.toLowerCase())
      : [];

    // Filter out choices that do not have a matching type in selectedOption
    updatedChoices = updatedChoices.filter((choice) =>
      selectedTypes.includes(choice.type.toLowerCase())
    );

    // Update choices state with the filtered choices
    setChoices(updatedChoices);

    let updatedGetIsNew = [...getIsNew];
    updatedGetIsNew = updatedGetIsNew.filter((IsNew) =>
      selectedTypes.includes(IsNew.type.toLowerCase())
    );

    setIsNew(updatedGetIsNew);
  }, [selectedOption]);

  const handleSelectChange2 = (index, selectedOptions, attributeType) => {
    const updatedGetIsNew = [...getIsNew];
    updatedGetIsNew[index] = {
      type: attributeType?.label,
      options: selectedOptions?.map((selectedOptionValue) => {
        return {
          value: selectedOptionValue?.value,
          isNew:
            selectedOptionValue?.__isNew__ !== undefined
              ? selectedOptionValue?.__isNew__
              : false,
        };
      }),
    };
    setIsNew(updatedGetIsNew);

    const updatedChoice = [...choices];
    updatedChoice[index] = {
      type: attributeType?.label,
      options: selectedOptions?.map((selectedOptionValue) => {
        return selectedOptionValue?.value;
      }),
    };
    setChoices(updatedChoice);
    setValue(selectedOptions);

    const removeIsNewKey = (arr) => {
      return arr.map((subArr) => {
        return subArr.map((obj) => {
          const { __isNew__, ...rest } = obj;
          return rest;
        });
      });
    };

    const updatedState = [...getAttributesChildren];
    updatedState[index] = selectedOptions;
    // const result = removeIsNewKey(updatedState);
    setAttributesChildren(updatedState);
    generateVariations();
  };

  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleMenuOpen = (index) => {
    setAttributeMenu(index);
  };

  const handleMenuClose = () => {
    setAttributeMenu(null);
  };

  const handleInputChange = (newValue, index) => {
    setInputValues((prev) => {
      const newValues = [...prev];
      newValues[index] = newValue;
      return newValues;
    });
  };

  const handleKeyDown = (event, index) => {
    if (!inputValues[index]) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValues((prev) => {
          const newValues = [...prev];
          const optionValue = createOption(inputValues[index]);

          if (typeof optionValue === "string") {
            newValues[index] = optionValue;
          }

          return newValues;
        });
        setInputValue("");
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // OverAllDynamic Product Variations Functionility End

  // Hit Create Product Api
  const PostProductHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!userData?.name) {
      toast.error("Product title is required");
      setLoading(false);
      return;
    }
    if (!selectCategory) {
      toast.error("Please select your product category");
      setLoading(false);
      return;
    }
    if (!selectSubCategory) {
      toast.error("Please select your product sub-category");
      setLoading(false);
      return;
    }
    if (
      userData?.shortDescription?.length < 15 ||
      userData?.shortDescription?.length > 50
    ) {
      toast.error(
        "Short description length must be between 15 to 50 characters"
      );
      setLoading(false);
      return;
    }

    if (selectedOption.length < 1) {
      toast.error("Please add atleast one variation");
      setLoading(false);
      return;
    }

    if (!logo) {
      toast.error("Please add product thumbnail");
      setLoading(false);
      return;
    }
    if (variantData.length < 1) {
      toast.error("Please add variation details ");
      setLoading(false);
      return;
    }

    if (uploadImages?.length <= 0) {
      toast.error("Please add product images");
      setLoading(false);
      return;
    }
    if (!userData?.unitPrice) {
      toast.error("Please enter unit price");
      setLoading(false);
      return;
    }
    if (!userData?.purchasePrice) {
      toast.error("please enter old price");
      setLoading(false);
      return;
    }
    if (!userData?.description) {
      toast.error("Please Enter product description");
      setLoading(false);
      return;
    }
    if (!checkState) {
      toast.error("Please check our condition");
      setLoading(false);
      return;
    }

    let data = new FormData();
    data.append("category_id", selectCategory);
    data.append("sub_category_id", selectSubCategory);
    data.append("title", userData?.name);
    data.append("slug", userData?.name?.replace(/\s/g, "-"));
    data.append("short_description", userData?.shortDescription);
    data.append("image", logo);
    uploadImages.map((singlepic) => {
      data.append("multi_images[]", singlepic);
    });
    data.append("description", userData?.description);
    data.append("old_price", userData?.purchasePrice);
    data.append("latest_price", userData?.unitPrice);
    data.append("stock", stock);
    data.append("variations", JSON.stringify(variantData));
    data.append("choices", JSON.stringify(choices));
    PostSellerProduct(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setLoading(false);
        navigate("/seller-Inventory");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const defaultOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];
  const dummyFun = (index, selectedOptions) => {
    setValue(selectedOptions);
    const updatedState = [...children];
    updatedState[index] = selectedOptions;
    setChildren(updatedState);
  };

  const options = [...defaultOptions, ...value]; // Combine defaultOptions and value
  const dummyArray = [1];

  useEffect(() => {
    MakeCombo();
  }, [getIsNew]);

  const [combArray, setCombArray] = useState([]);

  const MakeCombo = () => {
    function generateProductCombinations(
      getIsNew,
      currentIndex = 0,
      currentCombination = {},
      combinations = []
    ) {
      if (currentIndex === getIsNew?.length) {
        const combination = {
          type: Object.keys(currentCombination)
            .map((key) => currentCombination[key])
            .join("-"),
        };
        combinations?.push(combination);
        return;
      }

      const { type, options } = getIsNew[currentIndex];

      for (let i = 0; i < options.length; i++) {
        const { value } = options[i];

        const updatedCombination = {
          ...currentCombination,
          [type]: value,
        };

        generateProductCombinations(
          getIsNew,
          currentIndex + 1,
          updatedCombination,
          combinations
        );
      }

      return combinations;
    }

    const productCombinations = generateProductCombinations(getIsNew);
    setCombArray(productCombinations);
  };

  return (
    <>
      <SellerHeader />

      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <div className="products_adding">
                <div className="heading_wrapper">
                  <h3>Add New Products</h3>
                </div>
                {/* //  for testing puprose */}

                {/* {dummyArray?.map((product, index) => {
									return (
										<CreatableSelect
											components={components}
											inputValue={inputValue}
											isClearable
											isMulti
											menuIsOpen={attributeMenu}
											onChange={(newValue) => dummyFun(index, newValue)}
											onInputChange={(newValue) => setInputValue(newValue)}
											onKeyDown={(e) => {
												handleKeyDown(e, index);
											}}
											onMenuOpen={handleMenuOpen}
											onMenuClose={handleMenuClose}
											options={options}
											placeholder="Type Attribute Value and press enter..."
											value={children[index]}
											labelKey="label"
										/>
									);
								})} */}
                {/* //  for testing puprose */}

                <form action="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="general_info">Product Name/Title</label>
                        <InputField
                          type="text"
                          value={userData?.name}
                          onChange={handleChange}
                          placeholder="Product Name/Title"
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="general_info">Slug</label>
                        <InputField
                          type="text"
                          value={userData?.name?.replace(/\s/g, "-")}
                          onChange={(e) => setSlug(e.target.value)}
                          placeholder="slug"
                          name="slug"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="general_info">Short Description</label>
                        <InputField
                          type="text"
                          value={userData?.shortDescription}
                          onChange={handleChange}
                          placeholder="Short Description"
                          name="shortDescription"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="select_category">
                          Select categories{" "}
                        </label>
                        <select
                          id="select_category"
                          className="form-control"
                          onChange={(e) => {
                            setSelectCategory(e.target.value);
                          }}
                        >
                          <option selected disabled>
                            Select Category
                          </option>
                          {CategoriesData?.map((item, index) => {
                            return (
                              <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="sub_category">
                          Select Sub-categories
                        </label>

                        <select
                          id="select_category"
                          className="form-control"
                          onChange={(e) => {
                            setSelectSubCategory(e.target.value);
                          }}
                        >
                          <option selected disabled>
                            Select Sub Category
                          </option>
                          {CategoriesData?.map((item, index) => {
                            return (
                              <>
                                {item?.id == selectCategory
                                  ? item?.sub_category?.map((inner, index) => {
                                      return (
                                        <option value={inner?.id} key={index}>
                                          {inner?.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <h5>Select Attributes</h5>

                        {/* <SellerAttributes
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                          getMainAttributeData={getMainAttributeData}
                        /> */}

                        <div>
                          <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            isMulti={true}
                            className="dropdown"
                            options={getMainAttributeData}
                          />
                        </div>
                        <div className="row mt-4 mb-4">
                          {selectedOption !== undefined &&
                          selectedOption?.length > 0
                            ? selectedOption?.map((option, index) => {
                                attributeMainHandler(option?.value, index);
                                return (
                                  <>
                                    <div className="col-md-6">
                                      <div className="files_flex">
                                        <div>
                                          <InputField
                                            type="text"
                                            value={option?.label}
                                            placeholder="variant"
                                            name="variant"
                                            disabled={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="files_flex">
                                        <div>
                                          <CreatableSelect
                                            key={index}
                                            components={components}
                                            inputValue={inputValues[index]}
                                            isClearable
                                            isMulti
                                            onChange={(newValue) =>
                                              handleSelectChange2(
                                                index,
                                                newValue,
                                                option
                                              )
                                            }
                                            onInputChange={(newValue) =>
                                              handleInputChange(newValue, index)
                                            }
                                            onKeyDown={(e) =>
                                              handleKeyDown(e, index)
                                            }
                                            menuIsOpen={attributeMenu === index}
                                            onMenuOpen={() =>
                                              handleMenuOpen(index)
                                            }
                                            onMenuClose={handleMenuClose}
                                            // options={
                                            //   getAttributeValuesData[index]
                                            //     ?.setAttributesOptions
                                            // }
                                            placeholder="Type Attribute Value to add new value ..."
                                            labelKey="label"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div>
                        {/* new vesion  */}
                        <div className="row">
                          {combArray != undefined && combArray?.length > 0 ? (
                            <>
                              {combArray?.map((item, index) => {
                                return (
                                  <>
                                    <ProductVariantCombination
                                      item={item}
                                      index={index}
                                      variantData={variantData}
                                      variantDataHandler={variantDataHandler}
                                    />
                                  </>
                                );
                              })}
                            </>
                          ) : null}
                        </div>

                        {/* {console.log("variations", variations)}
                        <div className="row">
                          {(variations !== undefined) &
                          (variations?.length > 0) &
                          (variations[0]?.generatedvarients?.length > 0)
                            ? variations?.map((varient, i) => {
                                const variantItem = varient[i] || {};
                                const varientLength =
                                  Object?.keys(varient).length;
                                let addVarientValues = "";
                                for (let i = 0; i < varientLength; i++) {
                                  addVarientValues += `${i > 0 ? "-" : ""}${
                                    varient[Object?.keys(varient)[i]]
                                  }`;
                                }
                                return (
                                  <>
                                    <div className="col-md-4">
                                      <div className="files_flex">
                                        <div>
                                          <label>Variant</label>
                                          <InputField
                                            type="text"
                                            value={addVarientValues}
                                            placeholder="variant"
                                            name="variant"
                                            disabled={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="files_flex">
                                        <div>
                                          <label>Price</label>
                                          <InputField
                                            type="number"
                                            value={variantData["price"]}
                                            onChange={(e) =>
                                              variantDataHandler(e, i)
                                            }
                                            placeholder="Price"
                                            name="price"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="files_flex">
                                        <div>
                                          <label>Quantity</label>
                                          <InputField
                                            type="number"
                                            value={variantData["quantity"]}
                                            onChange={(e) =>
                                              variantDataHandler(e, i)
                                            }
                                            placeholder="Quantity"
                                            name="quantity"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div>
                                        <label>Sku</label>
                                        <InputField
                                          type="text"
                                          value={variantData["sku"]}
                                          onChange={(e) =>
                                            variantDataHandler(e, i)
                                          }
                                          placeholder="sku"
                                          name="sku"
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div> */}
                      </div>
                    </div>

                    <hr />
                    <div className="col-md-6">
                      <div className="files_flex">
                        <div className="custom-file">
                          <label>Add Pictures</label>
                          <input
                            type="text"
                            placeholder="Upload pictures"
                            className="form-control"
                          />
                          <input
                            type="file"
                            className="custom-file-input"
                            id="attachments"
                            accept="image/*"
                            onChange={handleImageUpload}
                            multiple={true}
                          />
                        </div>
                        <ul id="fileList" className="multi-imglist">
                          {renderPhotos(multiImageFiles)}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="files_flex">
                        <div className="custom-file">
                          <label>Add Thumbnail</label>
                          <input
                            type="text"
                            placeholder="Upload pictures"
                            className="form-control"
                          />
                          <input
                            type="file"
                            className="custom-file-input"
                            id="attachments"
                            accept="image/*"
                            onChange={HandleLogoUpload}
                            multiple={false}
                          />
                          <div className="mt-3">
                            {logo ? (
                              <img
                                src={logoFileupload}
                                className="image-fluid image-width"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                                alt=""
                              />
                            ) : null}
                          </div>
                        </div>
                        <ul id="fileList-two" className="file-list"></ul>
                      </div>
                    </div>
                    <hr />
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="UnitPrice">Unit Price</label>
                        <InputField
                          type="number"
                          value={userData?.unitPrice}
                          onChange={handleChange}
                          placeholder="Unit Price"
                          name="unitPrice"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="PurchasePrice">Old Price</label>
                        <InputField
                          type="number"
                          value={userData?.purchasePrice}
                          onChange={handleChange}
                          placeholder="Old Price"
                          name="purchasePrice"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="quantity">Quantity</label>
                        <InputField
                          type="number"
                          value={stock}
                          disabled={true}
                          placeholder="Quantity"
                          name="quantity"
                        />
                      </div>
                    </div>
                    <hr />

                    <div className="col-md-12">
                      <div className="text-editor">
                        <label>Product Details</label>
                        <textarea
                          name="description"
                          placeholder="Product Descriptions"
                          className="form-control"
                          value={userData?.description}
                          onChange={handleChange}
                          id=""
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="bottom_bar">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckChecked1"
                            // checked
                            value={checkState}
                            onChange={(e) => setCheckState(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked1"
                          >
                            I Confirm that the product and it specifications are
                            correct and same as product
                          </label>
                        </div>
                        <div className="button-group">
                          <button
                            className="btn"
                            onClick={(e) => PostProductHandler(e)}
                            disabled={loading}
                          >
                            {loading ? <CircleLoad /> : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SellerFooter />
      <AddAttributeValueModal
        isOpenAttributeValuetModal={isOpenAttributeValuetModal}
        setIsOpenAttributeValuetModal={setIsOpenAttributeValuetModal}
        attributeValueMessage={attributeValueMessage}
        setAttributeValueMessage={setAttributeValueMessage}
        attributeValueLoad={setAttributeValueLoad}
        SendHandler={createAttributeValueHandler}
      />
    </>
  );
};

export default Seller_add_product;
