import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../../constants";
import { useSelector } from "react-redux";

const Footer = () => {
	const SiteSettingsRedux = useSelector(
		(state) => state.AuthReducer.siteSettings,
	);
	const Token = useSelector((state) => state.AuthReducer.token);
	const d = new Date();
	let year = d.getFullYear();
	return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-lg-4">
						<div className="footer-widget-wrapper">
							<div className="logo-wrapper">
								<figure>
									<img src={logo} className="img-fluid" alt="" />
								</figure>
							</div>
							<div className="footer-content-wrapper">
								<h5>Apricot Market</h5>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="footer-widget-2-wrapper">
							<div className="footer-content-wrapper">
								<div className="heading-wrapper">
									<h5>Useful Links</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										<li>
											<Link to="/">Home</Link>
										</li>
										<li>
											<Link to="/about">About us</Link>
										</li>
										<li>
											<Link to="/shop">Shop</Link>
										</li>
								
										<li>
											<Link to="/contact">Contact us</Link>
										</li>
										{/* <li>
											<Link href="/cart">Cart</Link>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="footer-widget-2-wrapper">
							<div className="footer-content-wrapper">
								<div className="heading-wrapper">
									<h5>Help Center</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										<li>
											<Link to="/faqs">Faqs</Link>
										</li>
										<li>
											<Link to="/policy">Privacy Policy</Link>
										</li>
										<li>
											<Link to="/legal-policy">Legal Policy</Link>
										</li>
										<li>
											<Link to="/Terms">Terms And Conditions</Link>
										</li>
										<li>
											<Link to="/levels">Seller levels at Apricot</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-2">
						<div className="footer-widget-2-wrapper">
							<div className="footer-content-wrapper">
								<div className="heading-wrapper">
									<h5>Apricot Business</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										{Token ? null : (
											<li>
												<Link to="/sign-up-seller">Become A Seller</Link>
											</li>
										)}

										<li>
											<Link to="/Advertise">Advertise With us</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
							<p>{SiteSettingsRedux?.copyright}</p>
								{/* <p>© {year} Apricot. All Rights Reserved</p> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
