import React from "react";
import CircleLoad from "../Loader/CircleLoad";
import { Modal } from "reactstrap";
import InputField from "../Form/InputField";

const AddAttributeValueModal = (props) => {
	const {
		isOpenAttributeValuetModal,
		setIsOpenAttributeValuetModal,
		attributeValueMessage,
		setAttributeValueMessage,
		attributeValueLoad,
		SendHandler,
	} = props;
	return (
		<Modal
			isOpen={isOpenAttributeValuetModal}
			toggle={() => {
				setIsOpenAttributeValuetModal(false);
			}}
			className="custom-modal modal-width orderView-Modal"
		>
			<div id="staticBackdrop">
				<div>
					<div className="modal-body text-center">
						<h4>Add New Attribute value</h4>
						<form action="">
							<div className="form-group">
								<InputField
									type="text"
									value={attributeValueMessage}
									onChange={(e) => setAttributeValueMessage(e.target.value)}
									placeholder="Color Name"
									name="name"
								/>
							</div>
							<div className="form-group mt-3">
								<button
									className="btn form-control"
									onClick={(e) => SendHandler(e)}
									// disabled={attributeValueLoad}
									disabled={false}
								>
									{"Add New Attribute"}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default AddAttributeValueModal;
