import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import Countdown from "react-countdown";
import {
  banner_img,
  Category1,
  Category2,
  Category3,
  Category4,
  Category5,
  Category6,
  Category7,
  Category8,
  pro1,
  pro2,
  pro3,
  pro4,
} from "../../constants";
import {
  GetAllProductsApi,
  GetHomeBanner,
  PostAddWishList,
} from "../../network/Network";
import { useSelector } from "react-redux";
import NewArrival from "../../components/ProductbyFilter/NewArrival";
import BestSeller from "../../components/ProductbyFilter/BestSeller";
import FeaturedProduct from "../../components/Featured/FeaturedProduct";
import { Link, useNavigate } from "react-router-dom";
import { ImageUrl } from "../../network/ApiUrl";
import CircleLoad from "../../components/Loader/CircleLoad";
import { toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";

const Home = () => {
  const [featuredProducts, setFeaturedProducts] = useState();
  const navigate = useNavigate();
  const CategoriesData = useSelector((state) => state.AuthReducer.categories);
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const Token = useSelector((state) => state.AuthReducer.token);

  $(document).ready(function () {
    $(
      '.product-card-wrapper .product-content-wrapper .counter-total-wrapper input[type="number"]'
    ).change(function () {
      if (this.val() < 0) {
        // console.log(this.val());
      }
    });
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const saverssettings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
        },
      },
    ],
  };

  const catesettings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const seller_category = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    if (isLogin && userReduxData?.user_type == 2) {
      navigate("/seller-dashboard");
    }
  }, []);

  useEffect(() => {
    GetHomeBanner()
      .then((res) => {
        setBanner(res?.data?.response?.data);
      })
      .catch((err) => {
      });
  }, []);

  useEffect(() => {
    GetAllProductsApi()
      .then((res) => {
        setFeaturedProducts(res?.data?.response?.data?.data);
      })
      .catch((err) => {
      });
  }, []);

  const AddWishListHandler = (e, id) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      product_id: id,
    };
    PostAddWishList(data, Token)
      .then((res) => {
        setLoading(false);
        // setActive(!active);
        toast.success("Product Add to your Wishlist");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Header Header_Cate={true} setCate_id={false} setSearchText={false} />
      {/* banner starts here */}
      <section className="banner">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-slides-wrapper">
                <Slider {...settings}>
                  {banner?.map((item) => {
                    const backgroundImageStyle = {
                      backgroundImage: `url(${ImageUrl}/uploads/slider/${item?.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    };
                    return (
                      <>
                        <div
                          style={backgroundImageStyle}
                          className="single-banner-slide-wrapper"
                          key={item?.id}
                        >
                          <div>
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-10">
                                  <div className="for-banner-flex">
                                    <div className="banner-content-wrapper">
                                      {/* <h5>BE PART OF THE CHANGE</h5> */}
                                      <h1>{item?.text}</h1>
                                      {/* <p>
                                      New arrivals with naturre fruits, juice
                                      milks, essential for summer
                                    </p> */}
                                      <Link to="/shop" className="btn">
                                        Shop Now
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner ends here */}
      {/* Browse category starts here */}
      <section className="brwose-category">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-wrapper">
                <h3>Browse by Category</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="category-slides-wrapper">
                <Slider {...catesettings}>
                  {CategoriesData?.map((item, index) => {
                    return (
                      <>
                        <div key={index} className="single-slide-wrapper">
                          <div className="slider-img-wrapper">
                            <figure>
                              <img
                                src={`${ImageUrl}${"/uploads/category/"}${
                                  item?.image
                                }`}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                          </div>
                          <div className="slide-content-wrapper">
                            <h5
                              onClick={() =>
                                navigate("/shop", {
                                  state: {
                                    paramCat_id: item?.id,
                                    type: "cate",
                                  },
                                })
                              }
                            >
                              {item?.name}
                            </h5>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Browse category ends here */}
      {/* top savers starts here */}
      <section className="top-savers">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="saver-heading-wrapper">
                <h2>Top Saver Today</h2>
                {/* <span className="btn">
                  {" "}
                  <ruby>Expires in:</ruby>{" "}
                  <span className="timer">
                    <Countdown date={Date.now() + 100000000} />
                  </span>
                </span> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <Slider {...saverssettings}>
                  {/* <FeaturedProducts /> */}
                  {featuredProducts?.map((item, index) => {
                    if (item?.is_feature === 1) {
                      return (
                        // <FeaturedProduct
                        //   itemId={item?.id}
                        //   imageSrc={pro1}
                        //   brandName={item?.title}
                        //   title={item?.title}
                        //   price={item?.latest_price}
                        //   sold={item?.stock}
                        // />
                        <div className="col-lg-4">
                          <div className="product-card-wrapper">
                            <div className="product-img-wrapper">
                              <Link to={`${"/product"}/${item?.id}`}>
                                <figure>
                                  <img
                                    src={item?.image_url}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </figure>
                              </Link>
                            </div>
                            <div className="product-content-wrapper">
                              <h5>{item?.title}</h5>
                              <span className="ice">{item?.title}</span>
                              <div className="rating-wrapper">
                                <ul>
                                  <Rating
                                    size={20}
                                    readonly={true}
                                    allowFraction={true}
                                    initialValue={item?.average_rating}
                                  />
                                  <li className="total-rating">
                                    <span>{`(${
                                      item?.average_rating != null
                                        ? item?.average_rating
                                        : 0
                                    })`}</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>${item?.latest_price}</h4>
                              {/* <div className="progress-wrapper">
                                <div className="progress"></div>
                              </div> */}
                              {/* <div className="sold">
                                <h6>Sold: 30/40</h6>
                              </div> */}
                              <a
                                onClick={(e) => AddWishListHandler(e, item?.id)}
                                className="btn"
                                disabled={loading}
                              >
                                {loading ? <CircleLoad /> : "Add To Wishlist"}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </Slider>
              </div>
            </div>
            {/* <div className="col-lg-3">
							<div className="off-wrapper">
								<div className="heading-wrapper">
									<h3>15% OFF</h3>
									<h5>
										For new member sign <br /> up at the first time
									</h5>
								</div>
								<div className="off-form-wrapper">
									<form action="">
										<div className="form-group">
											<input
												type="email"
												placeholder="email"
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<input
												type="Password"
												placeholder="Password"
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<input
												type="Password"
												placeholder="Re-type Password"
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<button className="btn" type="submit">
												Register Now
											</button>
										</div>
									</form>
								</div>
							</div>
						</div> */}
          </div>
        </div>
      </section>

      <BestSeller />

      <NewArrival />

      <Footer />
    </>
  );
};

export default Home;
