import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { orderimg1 } from "../../constants";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/refund-order.css";
import { useSelector } from "react-redux";
import { PostUserReturnOrder } from "../../network/Network";
import CircleLoad from "../../components/Loader/CircleLoad";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const Refund_Order = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const location = useLocation();
  const RefundProduct = location?.state;
  const [loading, setLoading] = useState(false);
  const [uploadImages, setUploadImages] = useState([]);
  const [multiImageFiles, setMultiImageFiles] = useState([]);
  const [refundData, setRefundData] = useState({
    reason: "",
    note: "",
  });

  function handleChange(e) {
    const value = e.target.value;
    setRefundData({
      ...refundData,
      [e.target.name]: value,
    });
  }

  const RefundSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!refundData?.note || !refundData?.reason) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (uploadImages?.length <= 0) {
      toast.error("Please Upload your product image");
      setLoading(false);
      return;
    }

    if (refundData?.reason.length < 10) {
      toast.error("The message must be at least 10 characters");
      setLoading(false);
      return;
    }
    let data = new FormData();
    data.append("order_detail_id", RefundProduct?.item?.id);
    // data.append("order_product_id", RefundProduct?.product_id);
    data.append("note", refundData?.note);
    data.append("reason", refundData?.reason);
    // data.append("image", logo );
    uploadImages.map((singlepic) => {
      // data.append("images[]", JSON.stringify(singlepic));
      data.append("images[]", singlepic);
    });
    PostUserReturnOrder(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setLoading(false);
        navigate("/return-request");
        setRefundData({
          reason: "",
          note: "",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setMultiImageFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).forEach((file) => URL.revokeObjectURL(file));

      const uploadedFiles = Array.from(e.target.files);
      setUploadImages((prevImages) => prevImages.concat(uploadedFiles));
    }
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <li>
          {" "}
          <img
            src={photo}
            alt=""
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </li>
      );
    });
  };

  return (
    <>
      <Header Header_Cate={false} />
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* refund order starts here */}
              <div className="refund-order-wrapper">
                <div className="heading-wrapper">
                  <h4>Refund Order</h4>
                </div>
                <div className="refund-fill-form-wrapper">
                  <div className="heading-wrapper">
                    <h5>Fill The Refund Form</h5>
                  </div>
                  <div className="refund-product-form-wrapper">
                    <div className="refund-product-wrapper">
                      <div className="product-head-wrapper">
                        <h5>Products</h5>
                      </div>
                      <div className="product-refund-wrapper">
                        <div className="img-wrapper">
                          <figure>
                            <img
                              src={RefundProduct?.item?.product?.image_url}
                              className="img-fluid"
                              alt=""
                            />
                          </figure>
                        </div>
                        <div className="refund-content-wrapper">
                          <div className="head-date-wrapper">
                            <h5>{RefundProduct?.item?.product?.title}</h5>
                            {/* <h6>Delivery Date</h6> */}
                          </div>
                          <div className="size-wrapper">
                            <div className="size-ql-wrapper">
                              <ul>
                                <li> {RefundProduct?.item?.variant?.sku}</li>
                                <li>Quantity {RefundProduct?.item?.qty}</li>
                              </ul>
                            </div>
                            {/* <div className="size-date-wrapper">
                              <span>10/11/22</span>
                            </div> */}
                          </div>
                          <div className="price-wrapper">
                            <h5>RS : ${RefundProduct?.item?.price}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="refund-form-wrapper">
                        <form>
                          <div className="form-group">
                            <label htmlFor="">Return Reason</label>
                            <input
                              className="form-control"
                              type="text"
                              name="reason"
                              placeholder="Return Reason"
                              value={refundData?.reason}
                              onChange={handleChange}
                            />
                            {/* <select name="" className="form-control" id="">
															<option value="Select Return Reason" selected>
																Select Return Reason
															</option>
														</select> */}
                          </div>
                          <div className="form-group">
                            <label htmlFor="">Upload Picture</label>
                            <label className="tag" htmlFor="inputTag">
                              <div className="fiel-wrapper">
                                <span>Add Product Image</span>
                                <i className="fa fa-paperclip"></i>
                              </div>
                              <input
                                id="inputTag"
                                type="file"
                                name=""
                                className="form-comtrol"
                                accept="image/*"
                                onChange={handleImageUpload}
                                multiple={true}
                              />
                              <ul id="fileList" className="multi-imglist">
                                {renderPhotos(multiImageFiles)}
                              </ul>
                            </label>
                          </div>
                          <div className="form-group">
                            <label htmlFor="">Comment</label>
                            <textarea
                              name="note"
                              className="form-control"
                              id=""
                              cols="30"
                              rows="10"
                              placeholder="Type Your Issue"
                              value={refundData?.note}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn"
                              onClick={(e) => RefundSubmit(e)}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircleLoad />
                              ) : (
                                "Confirm Refund Order"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* refund order ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* My adresses ends here */}
      <Footer />
    </>
  );
};

export default Refund_Order;
