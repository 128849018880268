import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/Thanks.css";
import { Link, useLocation } from "react-router-dom";

const Thank_you = () => {
	const location = useLocation()
	const ParamData = location?.state?.data

	return (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header ends here */}
			{/* thank yoou starts here */}
			<section className="thankyou-sec">
				<div className="container">
					<div className="head">
						<h1>Thank You for Shopping!!</h1>
						<h4>Your Order is confirmed</h4>
					</div>
					<div className="order-nmber">
						<h4>OrderNo#{ParamData?.id}</h4>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-2"></div>
						<div className="col-lg-6 col-md-8">
							<div className="order-detail">
								<div className="shipment-address">
									<div className="title">
										<h5>Shipment Address</h5>
									</div>
									<ul className="address">
										<li className="country">
											<span className="property">Country:</span>
											<span className="value">{ParamData?.address?.country}</span>
										</li>
										<li className="country">
											<span className="property">City:</span>
											<span className="value">{ParamData?.address?.city}</span>
										</li>
										<li className="address">
											<span className="property">Address:</span>
											<span className="value">
											{ParamData?.address?.address}, {ParamData?.address?.street}
											</span>
										</li>
										<li className="phone">
											<span className="property">Phone:</span>
											<span className="value">+{ParamData?.address?.phone}</span>
										</li>
										<li className="state">
											<span className="property">State:</span>
											<span className="value">{ParamData?.address?.state}</span>
										</li>
									</ul>
								</div>
								<div className="payment-detail">
									<div className="title">
										<h5>Payment details</h5>
									</div>
									<ul className="payment-total">
										<li className="item-total">
											<span className="property">Item Total :</span>
											<span className="value">${ParamData?.sub_total}</span>
										</li>
										<li className="delivery">
											<span className="property">Shipment &amp; Delivery</span>
											<span className="value">${ParamData?.shipping}</span>
										</li>
										<li className="item-total">
											<span className="property">Promo Applied :</span>
											<span className="value">-${ParamData?.discount_amount}</span>
										</li>
									</ul>
								</div>
								<div className="order-total">
									<h3 className="property">Order Total</h3>
									<h3 className="value">${parseInt(ParamData?.sub_total) + parseInt(ParamData?.shipping) - parseInt(ParamData?.discount_amount) }</h3>
								</div>
							</div>
							<Link to="/shop" className="btn" type="button">
								Continue Shopping
							</Link>
						</div>
						<div className="col-lg-3 col-md-2"></div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer ends here */}
		</>
	);
};

export default Thank_you;
