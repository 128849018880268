import {
  ADD_TO_CART,
  CART_CLEAR,
  DELETE_CART_ITEM,
  INCREMENT_QUANTITY,
  DECREMENT_QUANTITY,
  TOP_PRODUCTS,
  GALLERY_IMG,
  UPDATE_ADD_TO_CART,
  DELIVERY_DATES,
} from "../Types";

const initialState = {
  cartData: [],
  galleryData: [],
  deliveryDates: [],
};
const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      return {
        ...state,
        cartData: [...state.cartData, action.payload],
      };
    }
    case UPDATE_ADD_TO_CART: {
      return {
        ...state,
        cartData: action.payload,
      };
    }
    case CART_CLEAR:
      return {
        ...state.cartData,
        cartData: [],
      };
    // case DELETE_CART_ITEM: {
    //   let deleted_index = state.cartData.findIndex(
    //     (cartData) => cartData?.productitem?.id === action.payload
    //   );
    //   state.cartData.splice(deleted_index, 1);
    //   return {
    //     ...state,
    //   };
    // }

    case DELETE_CART_ITEM: {
      const deletedIndex = action.payload;
      return {
        ...state,
        cartData: [
          ...state.cartData.slice(0, deletedIndex),
          ...state.cartData.slice(deletedIndex + 1),
        ],
      };
    }

    case INCREMENT_QUANTITY: {
      let UpdateCart = state.cartData.map((curElem,index) => {
        // if (curElem.productitem?.id === action.payload) {
        //   return { ...curElem, qty: curElem.qty + 1 };
        // }
        if (index === action.payload) {
          return { ...curElem, qty: curElem.qty + 1 };
        }
        return curElem;
      });

      return { ...state, cartData: UpdateCart };
    }

    case DECREMENT_QUANTITY: {
      let UpdateCart = state.cartData
        .map((curElem,index) => {
          if (index === action.payload) {
            return { ...curElem, qty: curElem.qty - 1 };
          }
          return curElem;
        })
        .filter((curElem) => {
          return curElem?.qty !== 0;
        });
      return { ...state, cartData: UpdateCart };
    }

    case GALLERY_IMG: {
      return Object.assign({}, state, {
        ...state,
        galleryData: action.payload,
      });
    }

    case DELIVERY_DATES: {
      return Object.assign({}, state, {
        ...state,
        deliveryDates: action.payload,
      });
    }

    default:
      return state;
  }
};
export default CartReducer;
