import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { sellerimg, seller_img } from "../../constants";
import "../../assets/css/level.css";
import { GetBadgeLevel } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import { useSelector } from "react-redux";

const Levels = () => {
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const [levelData, setLevelData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetBadgeLevel()
      .then((res) => {
        setLevelData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {userReduxData?.user_type == 2 ? <SellerHeader /> : <Header />}
      {/* level pageHeader starts here */}
      <section className="about_pageheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pageheader-content-wrapper">
                <h2>Seller levels at Apricot</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* level pageHeader ends here */}
      {/* levels sec starts here */}
      <section className="levels-sec">
        <div className="container">
          <div className="row box-row">
            <div className="col-lg-5">
              <div className="heading-wrapper">
                <h2 className="text-end">What are seller levels?</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="level-description">
                <div className="text-section">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iusto veniam debitis doloribus officia ad, dolor consequatur
                    saepe aperiam nihil facilis consectetur corrupti distinctio
                    nesciunt fugiat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {spinLoad ? (
            <>
              {[0, 1, 2, 3, 4]?.map((item, index) => {
                return (
                  <div className="col-lg-12 mt-3" key={index}>
                    <Skeleton count={5} />
                  </div>
                );
              })}
            </>
          ) : (
            levelData?.map((levelDetail,index) => {
              return (
                <>
                  <div className="row box-row" key={index}>
                    <div className="col-lg-5">
                      <div className="heading-wrapper">
                        <h2>
                          {" "}
                          <span>
                            <img src={sellerimg} alt="" />
                          </span>{" "}
                          {levelDetail?.name}
                        </h2>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="level-description">
                        {/* <div className="text-section">
                          <h3>Overview</h3>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Iusto veniam debitis doloribus officia ad,
                            dolor consequatur saepe aperiam nihil facilis
                            consectetur corrupti distinctio nesciunt fugiat.
                          </p>
                        </div> */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: levelDetail?.description,
                          }}
                        />
                      </div>
                      {/* <div className="level-description">
                        <div className="text-section">
                          <h3>You’ll get to:</h3>
                          <ul>
                            <li>Lorem, ipsum dolor sit amet</li>
                            <li>Lorem, ipsum dolor sit amet consectetur</li>
                            <li>
                              Lorem, ipsum dolor sit amet consectetur adipisi
                            </li>
                            <li>Lorem, ipsum dolor sit</li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </>
              );
            })
          )}

          {/* <div className="row box-row">
            <div className="col-lg-5">
              <div className="heading-wrapper">
                <h2>
                  {" "}
                  <span>
                    <img src={sellerimg} alt="" />
                  </span>{" "}
                  Level 1
                </h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="level-description">
                <div className="text-section">
                  <h3>Overview</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iusto veniam debitis doloribus officia ad, dolor consequatur
                    saepe aperiam nihil facilis consectetur corrupti distinctio
                    nesciunt fugiat.
                  </p>
                  <ul>
                    <li>Lorem, ipsum dolor sit amet</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                  </ul>
                </div>
              </div>
              <div className="level-description">
                <div className="text-section">
                  <h3>You’ll get to:</h3>
                  <ul>
                    <li>Lorem, ipsum dolor sit amet</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="row box-row">
            <div className="col-lg-5">
              <div className="heading-wrapper">
                <h2>
                  {" "}
                  <span>
                    <img src={sellerimg} alt="" />
                  </span>{" "}
                  Level 2
                </h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="level-description">
                <div className="text-section">
                  <h3>Overview</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iusto veniam debitis doloribus officia ad, dolor consequatur
                    saepe aperiam nihil facilis consectetur corrupti distinctio
                    nesciunt fugiat.
                  </p>
                  <ul>
                    <li>Lorem, ipsum dolor sit amet</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                  </ul>
                </div>
              </div>
              <div className="level-description">
                <div className="text-section">
                  <h3>You’ll get to:</h3>
                  <ul>
                    <li>Lorem, ipsum dolor sit amet</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row box-row">
            <div className="col-lg-5">
              <div className="heading-wrapper">
                <h2>
                  {" "}
                  <span>
                    <img src={sellerimg} alt="" />
                  </span>{" "}
                  Best Seller
                </h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="level-description">
                <div className="text-section">
                  <h3>Overview</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iusto veniam debitis doloribus officia ad, dolor consequatur
                    saepe aperiam nihil facilis consectetur corrupti distinctio
                    nesciunt fugiat.
                  </p>
                  <ul>
                    <li>Lorem, ipsum dolor sit amet</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                  </ul>
                </div>
              </div>
              <div className="level-description">
                <div className="text-section">
                  <h3>You’ll get to:</h3>
                  <ul>
                    <li>Lorem, ipsum dolor sit amet</li>
                    <li>Lorem, ipsum dolor sit amet consectetur</li>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisi</li>
                    <li>Lorem, ipsum dolor sit</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* levels sec ends here */}
      {/* Footer starts here */}
      {userReduxData?.user_type == 2 ? <SellerFooter /> : <Footer />}
      {/* Footer ends here */}
    </>
  );
};

export default Levels;
