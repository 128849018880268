import PublicRoutes from "./routes/PublicRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetAllCategories, GetSubCategories } from "./network/Network";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllCategoriesData, SubCategoriesData } from "./redux/actions/AuthActions";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/Custom.css";

function App() {
  const dispatch = useDispatch();
  const CategoriesData = useSelector((state) => state.AuthReducer.categories);

  // Sub Categories
  useEffect(() => {
    GetSubCategories()
      .then((res) => {
        dispatch(SubCategoriesData(res?.data?.response?.data));
      })
      .catch((err) => {
      });
  }, []);

  //   Get cate Data
  const memoizedVal = useMemo(() => {
    GetAllCategories()
      .then((res) => {
        dispatch(AllCategoriesData(res?.data?.response?.data));
      })
      .catch((err) => {
      });
  }, []);

  return (
    <>
      <PublicRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
