import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/banner-ad-form.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import InputField from "../../components/Form/InputField";
import StripeForm from "../../components/PaymentMethod/StripeForm";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { PostUpdateBanner } from "../../network/Network";
import { toast } from "react-toastify";
import { ImageUrl } from "../../network/ApiUrl";

const EditBanner = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const SiteSetting = useSelector((state) => state.AuthReducer.siteSettings);
  const location = useLocation();
  const paramData = location?.state?.item;
  const new_date = new Date().toISOString().slice(0, 10);
  const [logo, setLogo] = useState(paramData?.image ? paramData?.image : "");
  const [logoFileupload, setLogoFileupload] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(paramData?.price ? paramData?.price : 0);
  const [userData, setUserData] = useState({
    text: paramData?.text ? paramData?.text : "",
    link: paramData?.link ? paramData?.link : "",
    start_date: "",
    end_date: "",
  });

  const styling = {
    width: "100%",
    backgroundImage:
      "linear-gradient( to bottom, #71966d, #6a9166, #648b5f, #5d8659, #578152 )",
    color: "#fff",
    marginTop: "20px",
    fontFamily: "muli-bold",
    textTransform: "uppercase",
    fontSize: "14px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
  };

  useEffect(() => {
    setTimeout(async () => {
      await setUserData({
        ...userData,
        ["start_date"]: paramData?.start_date,
        ["end_date"]: paramData?.end_date,
      });
    }, 2000);
  }, []);

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
    if (e.target.name == "start_date" || e.target.name == "end_date") {
      TotalDaysHandler();
    }
  }

  const HandleBannerUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setLogo(file);
      setLogoFileupload(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const TotalDaysHandler = () => {
    var start = new Date(
      userData?.start_date ? userData?.start_date : paramData?.start_date
    );
    var end = new Date(
      userData?.end_date ? userData?.end_date : paramData?.end_date
    );
    var time = Math.abs(end - start);
    var days = Math.ceil(time / (1000 * 60 * 60 * 24));
    // console.log(
    // 	"Total number of days between dates  <br>" +
    // 		start +
    // 		"<br> and <br>" +
    // 		end +
    // 		" is: <br> " +
    // 		days,
    // );
    if (days) {
      return days;
    }
  };

  const PostProductHandler = (e, stripe_token) => {
    e.preventDefault();
    setLoading(true);
    setPrice(
      userData?.start_date && userData?.end_date
        ? SiteSetting?.bannerFee * TotalDaysHandler()
        : 0
    );
    if (!userData?.text) {
      toast.error("Please enter banner text");
      setLoading(false);
      return;
    }
    if (userData?.text?.length < 8 || userData?.text?.length > 30) {
      toast.error("Text length must between 8 to 30 characters ");
      setLoading(false);
      return;
    }
    if (!userData?.link) {
      toast.error("Please enter your link");
      setLoading(false);
      return;
    }
    if (!logo) {
      toast.error("Please upload your banner image");
      setLoading(false);
      return;
    }

    if (!price) {
      toast.error("Please select banner end date");
      setLoading(false);
      return;
    }
    let data = new FormData();
    data.append("text", userData?.text);
    data.append("link", userData?.link);
    data.append("image", logo);
    data.append(
      "start_date",
      userData?.start_date ? userData?.start_date : paramData?.start_date
    );
    data.append(
      "end_date",
      userData?.end_date ? userData?.end_date : paramData?.end_date
    );
    data.append("is_active", 1);
    data.append("added_by", userReduxData?.user_type == 2 ? "seller" : "user");
    data.append("token", stripe_token);
    data.append("price", price);
    PostUpdateBanner(paramData?.id, data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setLoading(false);
        navigate("/view-banner-ads");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!Token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {userReduxData?.user_type == 2 ? <SellerHeader /> : <Header />}

      <section className="banner-ad-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                  <div className="banner-main-head-wrapper">
                    <h2>Banner Ads</h2>
                  </div>
                  <div className="banner-form-wrapper">
                    <div className="banner-add-heading-wrapper">
                      <h3>Edit Your Brand</h3>
                    </div>
                    <div className="per-day-wrapper">
                      <span className="property">
                        Per Day Charges Your Brand Promotion :
                      </span>
                      <span className="value">$ {SiteSetting?.bannerFee}</span>
                    </div>
                    <form action="">
                      <div className="form-group row">
                        <div className="col-lg-6">
                          <label htmlFor="">Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="start_date"
                            id=""
                            defaultValue={paramData?.start_date}
                            value={userData?.start_date}
                            min={new_date}
                            onChange={handleChange}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">End Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="end_date"
                            id=""
                            defaultValue={paramData?.end_date}
                            value={userData?.end_date}
                            min={new_date}
                            onChange={handleChange}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Upload Banner Image</label>
                        <label className="tag" htmlFor="inputTag">
                          <div className="fiel-wrapper">
                            <span>Attach File</span>
                            <i className="fa fa-paperclip"></i>
                          </div>
                          <input
                            id="inputTag"
                            type="file"
                            name=""
                            className="form-comtrol"
                            accept="image/*"
                            onChange={HandleBannerUpload}
                            multiple={false}
                          />
                          <span id="imageName"></span>
                        </label>
                        <div className="img-cont">
                          {logoFileupload ? (
                            <img
                              src={logoFileupload}
                              className="image-fluid image-width"
                              alt=""
                            />
                          ) : (
                            <img
                              src={`${ImageUrl}${"/uploads/slider/"}${logo}`}
                              className="image-fluid image-width"
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Your Brand Link </label>
                        <InputField
                          type="text"
                          value={userData?.link}
                          onChange={handleChange}
                          placeholder="Paste Here"
                          className="form-control"
                          name="link"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Text </label>
                        <InputField
                          type="text"
                          value={userData?.text}
                          onChange={handleChange}
                          placeholder="Text Here"
                          className="form-control"
                          name="text"
                        />
                      </div>
                      <div className="adver-wrapper">
                        <ul>
                          <li>
                            <span className="property">
                              Advertise Banner Duration :
                            </span>
                            <span className="value">
                              {" "}
                              {/* {userData?.start_date
                                ? userData?.start_date
                                : paramData?.start_date && userData?.end_date
                                ? userData?.end_date
                                : paramData?.end_date
                                ? TotalDaysHandler()
                                : 0} */}
                              {TotalDaysHandler()}
                            </span>
                          </li>
                          <li>
                            <span className="property">Total Ads Price :</span>
                            <span className="value">
                              ${" "}
                              {/* {userData?.start_date
                                ? userData?.start_date
                                : paramData?.start_date && userData?.end_date
                                ? userData?.end_date
                                : paramData?.end_date
                                ? SiteSetting?.bannerFee * TotalDaysHandler()
                                : price} */}
                              {SiteSetting?.bannerFee * TotalDaysHandler()}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="form-group">
                        <StripeForm
                          styling={styling}
                          paynow="Pay Now"
                          PaymentHandler={PostProductHandler}
                          loading={loading}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      {/* Banner ad form ends here */}
      {userReduxData?.user_type == 2 ? <SellerFooter /> : <Footer />}
    </>
  );
};

export default EditBanner;
