import React, { useEffect, useState } from "react";
import {
  cat12,
  total_orders,
  total_products,
  total_user,
} from "../../constants";
import "../../assets/css/seller-dashboard.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import Customer_profile from "./Seller-sidebar/Customer_profile";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import {
  GetSellerOrders,
  GetSellerProfile,
  OnBoardCompleteApi,
} from "../../network/Network";
import { SellerProfile } from "../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import NoRecord from "../../components/NoRecord/NoRecord";
import Skeleton from "react-loading-skeleton";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";

const Seller_dashboard = () => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const [sellerProfile, setSellerProfile] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [search, setSearch] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [sellerOrders, setSellerOrders] = useState([]);
  const location = useLocation();
  const value = queryString.parse(location.search);
  const UserOnBoardKey = value.token;

  useEffect(() => {
    GetSellerProfile(Token)
      .then((res) => {
        dispatch(SellerProfile(res?.data?.response?.data));
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setSpinLoad(true);
    GetSellerOrders(currentPage, search, Token)
      .then((res) => {
        setSellerOrders(res?.data?.response?.data?.data?.reverse());
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);

  const allReviews = userReduxData?.Ratings;

  useEffect(() => {
    if (UserOnBoardKey) {
      OnBoardStatus();
    }
  }, []);

  const OnBoardStatus = () => {
    let data = {
      user_id: UserOnBoardKey,
    };
    OnBoardCompleteApi(data, Token)
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <>
      <SellerHeader />

      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
              <Customer_profile
                profileReviews={{
                  allReviews: allReviews,
                  isOnboarding: userReduxData?.seller_info?.onboarding,
                }}
              />
            </div>
            <div className="col-lg-8">
              <Seller_tabs_list />
              <div className="row" style={{ marginTop: "30px" }}>
                {/* <!-- seller dashboard stats starts here --> */}
                <div className="col-lg-4">
                  <div className="dashboard-stats-wrapper">
                    <h6 className="item-label">Total Orders </h6>
                    <div className="stats-and-number">
                      <h2>{userReduxData?.seller_info?.total_orders}</h2>
                      <img src={total_orders} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="dashboard-stats-wrapper">
                    <h6 className="item-label">Total Products</h6>
                    <div className="stats-and-number">
                      <h2>{userReduxData?.seller_info?.total_products}</h2>
                      <img src={total_products} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="dashboard-stats-wrapper">
                    <h6 className="item-label">Total Customer</h6>
                    <div className="stats-and-number">
                      <h2>{userReduxData?.seller_info?.totalCustomers}</h2>
                      <img src={total_user} alt="" />
                    </div>
                  </div>
                </div>
                {/* <!-- seller dashboard stats ends here --> */}
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="recent-orders-wrapper">
                    <div className="content-wrapper">
                      <h5>Recent Orders</h5>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">Billing Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Items</th>
                            <th scope="col"> Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spinLoad ? (
                            <tr>
                              {[0, 1, 2, 3]?.map((item, index) => {
                                return (
                                  <div className="mt-3" key={index}>
                                    <Skeleton count={5} />
                                  </div>
                                );
                              })}
                            </tr>
                          ) : sellerOrders?.length > 0 ? (
                            <>
                              {sellerOrders?.slice(0, 8)?.map((item) => {
                                return (
                                  <tr key={item?.id}>
                                    <th className="num" scope="row">
                                      #{item?.id}
                                    </th>
                                    <td className="name">
                                      {item?.user?.name} {item?.user?.last_name}
                                    </td>
                                    <td className="price">
                                      ${item?.sellerSubTotal}
                                    </td>
                                    <td className="item">
                                      {item?.order_details?.length}
                                    </td>
                                    <td className="shipment">
                                      {item?.order_details.length > 0
                                        ? item?.order_details[0]?.status == 0
                                          ? "Pending"
                                          : item?.order_details[0]?.status == 1
                                          ? "Process"
                                          : item?.order_details[0]?.status == 2
                                          ? "Shipped"
                                          : item?.order_details[0]?.status == 3
                                          ? "Completed"
                                          : item?.order_details[0]?.status == 4
                                          ? "Cancel"
                                          : null
                                        : ""}
                                    </td>
                                    <td className="details">
                                      <Link
                                        to={`/seller-order-details/${item?.id}`}
                                      >
                                        View Details
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <NoRecord />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_dashboard;
