import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CircleLoad from "../../components/Loader/CircleLoad";
import { logout } from "../../redux/actions/AuthActions";
import { ClearCart } from "../../redux/actions/CartActions";

const Logout = () => {
	const dispatch = useDispatch();
	const [logout_Load, setLogout_Load] = useState(false);

	// const LogoutHandler = (e) => {
	// 	setLogout_Load(true)
	// 	e.preventDefault();
	// 	setTimeout(async () => {
	// 	  let x = await dispatch(logout());
	// 	  dispatch(ClearCart())
	//       window.location.href = "/";
	// 	  setLogout_Load(false)
	// 	}, 600);
	//   };

	const LogoutHandler = (e) => {
		setLogout_Load(true);
		e.preventDefault();
		setTimeout(async () => {
			let x = await dispatch(logout());
			setLogout_Load(false);
			window.location.href = "/sign-in";
		}, 600);
	};

	return (
		<>
			{/* Logout wrapper */}
			<div className="logout-wrapper">
				<button className="logout-btn" onClick={(e) => LogoutHandler(e)}>
					{logout_Load ? <CircleLoad /> : "Log Out"}
				</button>
			</div>
			{/* Logout wrapper */}
		</>
	);
};

export default Logout;
