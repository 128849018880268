import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "../Pages/About/About";
import Forgot_Password from "../Pages/Auth/Forgot_Password";
import New_password from "../Pages/Auth/New_password";
import OTP from "../Pages/Auth/OTP";
import Sign_in from "../Pages/Auth/Sign_in";
import Sign_up from "../Pages/Auth/Sign_up";
import Sign_up_seller from "../Pages/Auth/Sign_up_seller";
import Checkout from "../Pages/Checkout/Checkout";
import Contact from "../Pages/Contact/Contact";
import Faq from "../Pages/Faq/Faq";
import Home from "../Pages/Home/Home";
import Advertise from "../Pages/Policies-page/Advertise";
import Privacypolicy from "../Pages/Policies-page/Privacy-policy";
import Terms from "../Pages/Policies-page/Terms";
import Seller_add_product from "../Pages/Seller-profile/Seller_add_product";
import Seller_banner_ad from "../Pages/Seller-profile/Seller_banner_ad";
import Seller_customer from "../Pages/Seller-profile/Seller_customer";
import Seller_dashboard from "../Pages/Seller-profile/Seller_dashboard";
import Seller_edit_info from "../Pages/Seller-profile/Seller_edit_info";
import Seller_info from "../Pages/Seller-profile/Seller_info";
import Seller_Inventory from "../Pages/Seller-profile/Seller_Inventory";
import Seller_orders from "../Pages/Seller-profile/Seller_orders";
import Seller_order_detail from "../Pages/Seller-profile/Seller_order_detail";
import Seller_refund_detail from "../Pages/Seller-profile/Seller_refund_detail";
import Seller_Refund_Orders from "../Pages/Seller-profile/Seller_Refund_Orders";
import Seller_Refund_Requests from "../Pages/Seller-profile/Seller_Refund_Requests";
import Seller_Reviews from "../Pages/Seller-profile/Seller_Reviews";
import Product_cart from "../Pages/Shop/Product_cart";
import Product_page from "../Pages/Shop/Product_page";
import Seller_store from "../Pages/Shop/Seller_store";
import Shop from "../Pages/Shop/Shop";
import Thank_you from "../Pages/Shop/Thank_you";
import Banner_ad from "../Pages/Subscription/Banner_ad";
import Banner_ads_form from "../Pages/Subscription/Banner_ads_form";
import Subscription_payment from "../Pages/Subscription/Subscription_payment";
import Subscription_Type from "../Pages/Subscription/Subscription_Type";
import MyAddresses from "../Pages/User_profile/MyAddresses";
import Myorder from "../Pages/User_profile/Myorder";
import MyorderDetail from "../Pages/User_profile/MyorderDetail";
import MyProfile from "../Pages/User_profile/MyProfile";
import MyWishlist from "../Pages/User_profile/MyWishlist";
import Payment from "../Pages/User_profile/Payment";
import Refund_Order from "../Pages/User_profile/Refund_Order";
import Return_detail from "../Pages/User_profile/Return_detail";
import Return_Requests from "../Pages/User_profile/Return_Requests";
import Security from "../Pages/User_profile/Security";
import Share from "../Pages/User_profile/Share";
import View_Banner_Ad from "../Pages/User_profile/View_Banner_Ad";
import Seller_product_inventory_detail from "../Pages/Seller-profile/Seller_product_inventory_detail";
import Seller_account from "../Pages/Seller-profile/Seller_account";
import Seller_accept_refund from "../Pages/Seller-profile/Seller_accept_refund";
import Seller_reject_refund from "../Pages/Seller-profile/Seller_reject_refund";
import EditBanner from "../Pages/Subscription/EditBanner";
import Levels from "../Pages/levels/Levels";
import ProtectedRoutes from "../../src/routes/ProtectedRoutes";
import SellerProtectedRoutes from "./SellerProtectedRoutes";
import SellerUpdateProduct from "../Pages/Seller-profile/SellerUpdateProduct";
import SellerChangePassword from "../Pages/Seller-profile/SellerChangePassword";
import LegalPolicy from "../Pages/Policies-page/LegalPolicy";
import SellerOrderDetail2 from "../Pages/Seller-profile/SellerOrderDetail2";

const PublicRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/product/:ID" element={<Product_page />} />
          <Route path="/policy" element={<Privacypolicy />} />
          <Route path="/legal-policy" element={<LegalPolicy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Advertise" element={<Advertise />} />
          <Route path="/faqs" element={<Faq />} />

          <Route path="/sign-in" element={<Sign_in />} />
          <Route path="/sign-up" element={<Sign_up />} />
          <Route path="/sign-up-seller" element={<Sign_up_seller />} />
          <Route path="/forgot-password" element={<Forgot_Password />} />
          <Route path="/otp-code" element={<OTP />} />
          <Route path="/new-password" element={<New_password />} />
          {/* <Route path="/Subscription_Type" element={<Subscription_Type />} />
					<Route
						path="/Subscription_payment"
						element={<Subscription_payment />}
					/> */}

          {/* <Route path="/banner-ad" element={<Banner_ad />} /> */}
          <Route path="/levels" element={<Levels />} />

          <Route path="/seller-store/:ID" element={<Seller_store />} />
          <Route path="/cart" element={<Product_cart />} />

          {/* both for seller and user */}
          <Route path="/banner-ad-form" element={<Banner_ads_form />} />
          <Route path="/edit-ad-form" element={<EditBanner />} />

          {/* User Profile starts here */}
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/my-address" element={<MyAddresses />} />
            <Route path="/change-password" element={<Security />} />
            <Route path="/my-wishlist" element={<MyWishlist />} />
            <Route path="/my-order" element={<Myorder />} />
            <Route path="/order-detail/:ID" element={<MyorderDetail />} />
            <Route path="/refund-order-form" element={<Refund_Order />} />
            <Route path="/return-request" element={<Return_Requests />} />
            <Route path="/return-detail/:ID" element={<Return_detail />} />
            <Route path="/view-banner-ads" element={<View_Banner_Ad />} />
            {/* <Route path="/banner-ad-form" element={<Banner_ads_form />} /> */}
            {/* <Route path="/edit-ad-form" element={<EditBanner />} /> */}
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/thank_you" element={<Thank_you />} />
            <Route path="/share" element={<Share />} />
          </Route>

          {/* <Route path="/Payment" element={<Payment />} /> */}

          {/* User Profile ends here */}

          {/* Seller dashboard starts here */}
          <Route path="/" element={<SellerProtectedRoutes />}>
            <Route path="/seller-dashboard" element={<Seller_dashboard />} />
            <Route path="/seller-account" element={<Seller_account />} />
            <Route
              path="/seller-change-password"
              element={<SellerChangePassword />}
            />
            <Route path="/seller-customer" element={<Seller_customer />} />
            <Route path="/seller-info" element={<Seller_info />} />
            <Route
              path="/seller-business-info"
              element={<Seller_edit_info />}
            />
            <Route
              path="/seller-add-product"
              element={<Seller_add_product />}
            />
            <Route
              path="/seller-edit-product/:ID"
              element={<SellerUpdateProduct />}
            />

            <Route path="/seller-Inventory" element={<Seller_Inventory />} />
            <Route
              path="/seller-inventory-product/:ID"
              element={<Seller_product_inventory_detail />}
            />

            <Route path="/seller-order" element={<Seller_orders />} />
            <Route
              path="/seller-order-details/:ID"
              element={<Seller_order_detail />}
            />
            <Route
              path="/seller-refund-order"
              element={<Seller_Refund_Orders />}
            />
            <Route
              path="/seller-refund-details/:ID"
              element={<SellerOrderDetail2 />}
            />
            <Route
              path="/seller-refund-form/:ID"
              element={<Seller_refund_detail />}
            />
            <Route
              path="/seller-refund-request"
              element={<Seller_Refund_Requests />}
            />

            <Route path="/seller-reviews" element={<Seller_Reviews />} />

            <Route path="/seller-banner-ads" element={<Seller_banner_ad />} />
            {/* <Route path="/banner-ad-form" element={<Banner_ads_form />} /> */}
          </Route>

          <Route
            path="/seller-accept-refund/:ID"
            element={<Seller_accept_refund />}
          />
          <Route
            path="/seller-reject-refund/:ID"
            element={<Seller_reject_refund />}
          />
          {/* Seller dashboard ends here */}

          {/* <Route path="/testing" element={<Return_detail />} /> */}
        </Routes>
      </Router>
    </>
  );
};

export default PublicRoutes;
