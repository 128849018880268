import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/sign-in.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputField from "../../components/Form/InputField";
import CircleLoad from "../../components/Loader/CircleLoad";
import { toast } from "react-toastify";
import { login } from "../../redux/actions/AuthActions";
import SignupLinkModal from "../../components/Modal/SignupLinkModal";

const Sign_in = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const location = useLocation();
  const paramData = location?.state?.data;

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (Token) {
      Navigate("/");
    }
  }, []);

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  }

  const handleRememeberMe = (event) => {
    setChecked(!checked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = rememberMe ? localStorage.getItem("email") : "";
    const password = rememberMe ? localStorage.getItem("password") : "";
    setUserData({
      email: email,
      password: password,
    });
    setChecked(rememberMe);
  }, []);

  const SignInHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!userData?.email || !userData?.password) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userData?.email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    } else {
      localStorage.setItem("rememberMe", checked);
      localStorage.setItem("email", checked ? userData?.email : "");
      localStorage.setItem("password", checked ? userData?.password : "");

      let data = {
        email: userData?.email,
        password: userData?.password,
      };
      setTimeout(async () => {
        // setLoading(false);
        let x = await dispatch(login(data, Navigate, setLoading));
      }, 600);
    }
  };

  return (
    <>
      {/* Header starts here */}
      <Header />
      {/* Header ends here */}
      {/* sign in starts here */}
      <section className="sign-in">
        <div className="container">
          <div className="row ">
            <div className="col-lg-4">
              <div className="sign-in-form-wrapper">
                <div className="heading-wrapper">
                  <h2>Sign In</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy aliquyam erat
                  </p>
                </div>
                <div className="sign-form-wrapper">
                  <form>
                    <div className="form-group">
                      <label htmlFor="">Email Address</label>

                      <InputField
                        type="text"
                        value={userData?.email}
                        onChange={handleChange}
                        placeholder="Email"
                        name="email"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Password</label>
                      <InputField
                        type="password"
                        value={userData?.password}
                        onChange={handleChange}
                        placeholder="**********"
                        name="password"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            value={checked}
                            onChange={(e) => handleRememeberMe(e)}
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            Remember Me
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="forgot-wrapper">
                          <Link to="/forgot-password">Forgot Password</Link>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn"
                        onClick={(e) => SignInHandler(e)}
                        disabled={loading}
                      >
                        {loading ? <CircleLoad /> : "Sign In"}
                      </button>
                    </div>
                    <div className="form-group">
                      <div className="sign-up-tag-line-wrapper">
                        <p>
                          Don't Have An Account{"? "}
                          <a href="/sign-up">( Sign Up )</a>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-7 align-self-center">
              <div className="join-seller-wrapper">
                <div className="join-content-wrapper">
                  <h3>
                    Join As An <span>APRICOT</span> Seller Program
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  </p>
                  <a href="/sign-up-seller" className="btn">
                    Become A Seller
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* sign in ends here */}
      {/* Footer starts here */}
      <Footer />
      {/* Footer ends here */}
    </>
  );
};

export default Sign_in;
