import React, { useEffect, useState } from "react";
import "../../assets/css/seller-dashboard.css";
import "../../assets/css/seller-refund-details.css";
import "../../assets/css/seller-order-details.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { orderimg1, return_pic } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetSellerRefundOrderDetail,
  GetUserRefundOrderDetails,
  GetUserRefundOrderDetailsSeller,
} from "../../network/Network";
import moment from "moment";
import { ImageUrl } from "../../network/ApiUrl";
import Skeleton from "react-loading-skeleton";

const Seller_refund_detail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [refundOrderData, setRefundOrderData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  let currentUrl = window.location.href.split("/");

  useEffect(() => {
    setSpinLoad(true);
    GetSellerRefundOrderDetail(currentUrl[4], Token)
      .then((res) => {
        setRefundOrderData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <Seller_tabs_list />

              <div
                className="return-detail-wrapper"
                style={{ paddingTop: "0px" }}
              >
                {spinLoad ? (
                  <div className="mt-3">
                    <Skeleton count={8} />
                  </div>
                ) : (
                  <div className="order-return-wrapper">
                    <div className="order-id-wrapper">
                      <span>
                        Order #{refundOrderData?.order_detail?.order_id} was
                        placed on{" "}
                        {moment(
                          refundOrderData?.order_detail?.updated_at
                        ).format("MMMM Do YYYY, h:mm a")}
                      </span>
                      <p className="m-0">
                        Status:{" "}
                        {refundOrderData?.status == 0
                          ? "Pending"
                          : refundOrderData?.status == 1
                          ? "accepted"
                          : refundOrderData?.status == 2
                          ? "rejected"
                          : refundOrderData?.status == 3
                          ? "Product Received"
                          : refundOrderData?.status == 4
                          ? "voucher generate"
                          : null}
                      </p>
                    </div>
                    <div className="return-details-wrapper">
                      <h5>Refund Detail</h5>
                    </div>
                    <div className="retun-pro-headi-wrapper">
                      <h5>Return Product</h5>
                    </div>
                    <div className="product-refund-wrapper">
                      <div className="img-wrapper">
                        <figure>
                          {/* <img src={orderimg1} className="img-fluid" alt="" /> */}
                          <img
                            src={
                              refundOrderData?.order_detail?.product?.image_url
                            }
                            className="img-fluid mr-2"
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="refund-content-wrapper">
                        <div className="head-date-wrapper">
                          <h5>
                            {refundOrderData?.order_detail?.product?.title}{" "}
                          </h5>
                          <h6>Purchase Date</h6>
                        </div>
                        <div className="size-wrapper">
                          <div className="size-ql-wrapper">
                            <ul>
                              <li>
                                Size{" "}
                                {refundOrderData?.order_detail?.variant?.sku}
                              </li>
                              <li>
                                Quantity {refundOrderData?.order_detail?.qty}
                              </li>
                            </ul>
                          </div>
                          <div className="size-date-wrapper">
                            <span>
                              {" "}
                              {moment(
                                refundOrderData?.order_detail?.updated_at
                              ).format("MMMM Do YYYY, h:mm a")}
                            </span>
                          </div>
                        </div>
                        <div className="price-wrapper">
                          <h5>${refundOrderData?.order_detail?.total}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="return-reason-wrapper">
                      <div className="reason-heading-wrapper">
                        <h5>Return Reason</h5>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                        placeholder={refundOrderData?.reason}
                      />
                    </div>
                    <div className="return-picctures-wrapper">
                      <div className="pictures-head-wrapper">
                        <h5>Return Products Pictures</h5>
                      </div>
                      <div className="pictures-wrapper">
                        {refundOrderData?.multimedia?.map((item, index) => {
                          return (
                            <img
                              key={item}
                              src={`${ImageUrl}/uploads/return-product/${item?.image}`}
                              className="img-fluid"
                              alt=""
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div className="comment-head-wrapper">
                      <h5>Comment</h5>
                    </div>
                    <div className="comment-wrapper">
                      <p>{refundOrderData?.note}</p>
                    </div>

                    {refundOrderData?.status == 1 ||
                    refundOrderData?.status == 2 ? null : (
                      <div className="app-btns-wrapper">
                        <a
                          onClick={() =>
                            navigate(
                              `/seller-reject-refund/${refundOrderData?.id}`
                            )
                          }
                          className="btn"
                        >
                          Reject
                        </a>
                        <a
                          onClick={() =>
                            navigate(
                              `/seller-accept-refund/${refundOrderData?.id}`
                            )
                          }
                          className="btn"
                        >
                          Accept
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_refund_detail;
