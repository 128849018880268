import React, { useState } from "react";
import "../../assets/css/seller-review.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { detail_order_img, product_inventory, star } from "../../constants";
import { useEffect } from "react";
import $ from "jquery";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { PostFeedBack, SellerReviews } from "../../network/Network";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../components/NoRecord/NoRecord";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import FeedBackModal from "../../components/Modal/FeedBackModal";
import { toast } from "react-toastify";

const Seller_Reviews = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [reviewsData, setReviewsData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [isOpenFeedbackModal, setIsOpenFeedbackModal] = useState(false);
  const [message, setMessage] = useState();
  const [selectedId, setSelectedId] = useState();
  const [feedLoad, setFeedLoad] = useState(false);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    SellerReviews(currentPage, Token)
      .then((res) => {
        setReviewsData(res?.data?.response?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(true);
      });
  }, [currentPage]);

  const SendFeedBack = (e) => {
    e.preventDefault();
    setFeedLoad(true);
    if (!message) {
      toast.error("Please Enter Your Feedback");
      setFeedLoad(false);
      return;
    }
    if (message?.length < 15) {
      toast.error("Feedback must be at least 15 characters");
      setFeedLoad(false);
      return;
    }
    let data = {
      id: selectedId,
      feedback: message,
    };
    PostFeedBack(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setFeedLoad(false);
        setIsOpenFeedbackModal(false);
        setMessage("");
        setSelectedId("");
      })
      .catch((err) => {
        setFeedLoad(false);
      });
  };

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8 refunr-ore pus">
              <Seller_tabs_list />

              <div className="cutomer-res" style={{ marginTop: "30px" }}>
                <div className="content-wrapper">
                  <h3>Customer Reviews</h3>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="product">
                          Product
                        </th>
                        <th scope="col" className="product-name">
                          Product Name
                        </th>
                        <th scope="col" className="customer-detail">
                          Customer Details
                        </th>
                        <th scope="col" className="review-rating">
                          Reviews & Rating
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {spinLoad ? (
                        <>
                          {[0, 1, 2, 3]?.map((item, index) => {
                            return (
                              <div className="mt-3" key={index}>
                                <Skeleton count={5} />
                              </div>
                            );
                          })}
                        </>
                      ) : reviewsData?.length > 0 ? (
                        reviewsData?.map((item, index) => {
                          return (
                            <tr>
                              <td className="pro-img" key={index}>
                                <img src={item?.product?.image_url} alt="" />
                              </td>
                              <td className="pro-name">
                                {item?.product?.title}
                              </td>
                              <td className="pro-details">
                                {item?.user?.email}
                              </td>
                              <td className="pro-reviews">
                                <div className="rev-wrapper">
                                  <div className="star-and-date">
                                    <Rating
                                      size={20}
                                      readonly={true}
                                      allowFraction={true}
                                      initialValue={
                                        item?.product?.average_rating
                                      }
                                      // ratingValue={}
                                    />
                                    <span className="date">
                                      {moment(item?.created_at).format(
                                        "MMMM Do YYYY, h:mm a"
                                      )}
                                    </span>
                                  </div>
                                  <div className="rev-content">
                                    <p>{item?.comment}</p>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={(e) => {
                                        setIsOpenFeedbackModal(true);
                                        setSelectedId(item?.id);
                                      }}
                                    >
                                      Send Feedback
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecord />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="paginations_results">
                  <div className="showing_results">
                    <p>
                      Showing <b>{reviewsData?.length}</b> <b>results</b>
                    </p>
                  </div>
                  <ul className="paginations">
                    <div className="pagination-container mt-5">
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SellerFooter />

      <FeedBackModal
        isOpenFeedbackModal={isOpenFeedbackModal}
        setIsOpenFeedbackModal={setIsOpenFeedbackModal}
        message={message}
        setMessage={setMessage}
        feedLoad={feedLoad}
        SendFeedBack={SendFeedBack}
      />
    </>
  );
};

export default Seller_Reviews;
