import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { ad1 } from "../../constants";
import { ImageUrl } from "../../network/ApiUrl";

const BannerCard = (props) => {
  const { item, DeleteBannerHandler, setTextCopy, copied, setCopied } = props;
  return (
    <>
      <div className="view-card-wrapper" key={item?.id}>
        <div className="ads-content-wrapper">
          <div className="ads-date-list-wrapper">
            <div className="single-date-wrapper">
              <div className="date">
                <h5>Start Date</h5>
                <h6>{item?.start_date}</h6>
              </div>
            </div>
            <div className="single-date-wrapper">
              <div className="date">
                <h5>End Date</h5>
                <h6>{item?.end_date}</h6>
              </div>
            </div>
            <div className="single-date-wrapper">
              <div className="date">
                <h5>Banner Price</h5>
                <h6>$ {item?.price}</h6>
              </div>
            </div>
          </div>
          <div className="ads-brand-link-wrapper">
            <label htmlFor="">Your Brand Link </label>

            <div className="fields-wrapper">
              <input
                type="text"
                placeholder="Lorem ipsum dolor sit amet, consectetur "
                className="form-control"
                value={item?.link}
                onChange={() => {
                  setTextCopy(item?.link);
                  setCopied(false);
                }}
              />
              <CopyToClipboard text={item?.link} onCopy={() => setCopied(true)}>
                <button className="btn"> Copy Link</button>
              </CopyToClipboard>
            </div>
            {copied ? <p style={{ color: "red" }}>Copied</p> : null}
          </div>
        </div>
        <div className="ads-img-wrapper">
          <div className="ad-img">
            <figure>
              <img
                src={`${ImageUrl}${"/uploads/slider/"}${item?.image}`}
                className="img-fluid"
                alt=""
              />
            </figure>
            <div className="btn-wrapper d-flex justify-content-between w-100 align-items-center px-3">
              <Link
                to="/edit-ad-form"
                state={{ item: item }}
                className="edit-btn"
              >
                Edit Ads
              </Link>
              <button
                className="delete"
                onClick={(e) => DeleteBannerHandler(e, item?.id)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
          <div className="expire-date-wrapper">
            <h6>Expire Date :</h6>
            <h6>{item?.end_date}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerCard;
