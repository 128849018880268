import React, { useState } from "react";
import "../../assets/css/seller-info.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import {
  cat12,
  detail_order_img,
  product_inventory,
  star,
} from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { PostSellerProfileUpdate } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import InputField from "../../components/Form/InputField";
import CircleLoad from "../../components/Loader/CircleLoad";
import { useNavigate } from "react-router-dom";
import { EditProfileData } from "../../redux/actions/AuthActions";
import { ramdomImage } from "../../constants/ConstantFunction";
import { ApiUrl, ImageUrl } from "../../network/ApiUrl";

const Seller_info = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [userData, setUserData] = useState({
    first_name: userReduxData?.name ? userReduxData?.name : "",
    last_name: userReduxData?.last_name ? userReduxData?.last_name : "",
    phone: userReduxData?.phone ? userReduxData?.phone : "",
    business_name: userReduxData?.seller_info?.business_name
      ? userReduxData?.seller_info?.business_name
      : "",
    city: userReduxData?.seller_info?.city
      ? userReduxData?.seller_info?.city
      : "",
    designation: userReduxData?.seller_info?.designation
      ? userReduxData?.seller_info?.designation
      : "",
    age: userReduxData?.seller_info.age ? userReduxData?.seller_info?.age : "",
    country: userReduxData?.seller_info?.country
      ? userReduxData?.seller_info?.country
      : "",
    business_type: userReduxData?.seller_info?.business_type
      ? userReduxData?.seller_info?.business_type
      : "",
  });
  const [editLoading, setEditLoading] = useState(false);
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  }

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  const EditProfileHandler = (e) => {
    e.preventDefault();
    setEditLoading(true);
    if (userData?.phone?.length < 10 || userData?.phone?.length > 17) {
      toast.error("The phone no limit is min 10 and max 17 digits");
      setEditLoading(false);
      return;
    }
    let data = new FormData();
    data.append("first_name", userData?.first_name);
    data.append("last_name", userData?.last_name);
    data.append("phone", userData?.phone);
    data.append("city", userData?.city);
    data.append("business_name", userData?.business_name);
    data.append("designation", userData?.designation);
    data.append("age", userData?.age);
    data.append("country", userData?.country);
    data.append("business_type", userData?.business_type);
    data.append("image", newFile);
    PostSellerProfileUpdate(data, Token)
      .then((res) => {
        setEditLoading(false);
        toast.success(res?.data?.message);
        navigate("/seller-account");
        dispatch(EditProfileData(res?.data?.response?.data));
      })
      .catch((e) => {
        setEditLoading(false);
        if (e.response.data.statusCode === 401) {
          toast?.error(e.response.data.message);
          if (e.response.data.errors.length > 0 && e.response.data.message) {
            toast.error(e.response.data.message);
          }
        } else if (e.response.data.statusCode === 409) {
          e.response.data.errors.map((val) => {
            toast.error(val);
          });
          if (e.response.data.errors.length === 0 && e.response.data.message) {
            toast.error(e.response.data.message);
          }
        } else if (
          e.response.data.statusCode === 422 ||
          e.response.data.statusCode === 405
        ) {
          toast.error(e.response.data.errors);
        } else if (
          e.response.data.statusCode === 400 &&
          e.response.data.errors
        ) {
          for (let key in e.response.data.errors) {
            if (e.response.data.errors.hasOwnProperty(key)) {
              toast.error(e.response.data.errors[key][0]);
            }
          }

          if (e.response.data.errors.length === 0 && e.response.data.message) {
            toast.error(e.response.data.message);
          }
        } else if (e.response.data.statusCode === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.error(e.message);
        }
        return data;
      });
  };

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8 refunr-ore pus">
              <Seller_tabs_list />
              <div className="seller-info-wrapper">
                <div className="edit-head-wrapper">
                  <h6>Add Personal Info</h6>
                </div>
                <div className="profile-row-wrapper">
                  <div className="profile-img-wrappper">
                    <figure>
                      {uploadLoading ? (
                        <img src={fileupload} alt="" />
                      ) : userReduxData?.image == null ? (
                        <img
                          src={`${ramdomImage(
                            `${userReduxData?.name}{""}${userReduxData?.last_name}`
                          )}`}
                          className="image-fluid image-width"
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${
                            fileupload
                              ? fileupload
                              : `${ImageUrl}/${"uploads"}/${"seller"}/${
                                  userReduxData?.image
                                }`
                          }`}
                          className="image-fluid image-width"
                          alt=""
                        />
                      )}
                    </figure>
                    {/* <img src={cat12} alt="" /> */}
                    <div className="profile-upload-wrapper">
                      <h6>Jpeg or Png maximum size (5mb)</h6>
                      <button className="btn">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          multiple={false}
                          className="profile-upload"
                        />
                        Browse image
                      </button>
                    </div>
                  </div>
                  {/* <div className="processing-wrapper">
                    <h5>Processing</h5>
                    <i className="fa fa-spinner" aria-hidden="true"></i>
                  </div> */}
                </div>
                <div className="info-form-wrappe">
                  <form action="">
                    <div className="form-group row">
                      <div className="col-lg-5">
                        <label htmlFor="First Name:">First Name</label>
                        <InputField
                          type="text"
                          value={userData?.first_name}
                          onChange={handleChange}
                          placeholder="First Name"
                          name="first_name"
                        />
                      </div>
                      <div className="col-lg-2  px-0"></div>
                      <div className="col-lg-5">
                        <label htmlFor="First Name:">Last Name</label>
                        <InputField
                          type="text"
                          value={userData?.last_name}
                          onChange={handleChange}
                          placeholder="Last Name"
                          name="last_name"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">Age</label>
                        <InputField
                          type="number"
                          value={userData?.age}
                          onChange={handleChange}
                          placeholder="Age"
                          name="age"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">City</label>
                        <InputField
                          type="text"
                          value={userData?.city}
                          onChange={handleChange}
                          placeholder="City"
                          name="city"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">Country</label>
                        <InputField
                          type="text"
                          value={userData?.country}
                          onChange={handleChange}
                          placeholder="Country"
                          name="country"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-5">
                        <label htmlFor="First Name:">Phone</label>
                        <InputField
                          type="text"
                          value={userData?.phone}
                          onChange={handleChange}
                          placeholder="Phone"
                          name="phone"
                        />
                      </div>
                      <div className="col-lg-2 px-0"></div>
                      {/* <div className="col-lg-5">
												<label htmlFor="First Name:">Email</label>
												<input
													type="text"
													className="form-control"
													placeholder="john@examplecom"
												/>
											</div> */}
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">
                          Company / Business name
                        </label>
                        <InputField
                          type="text"
                          value={userData?.business_name}
                          onChange={handleChange}
                          placeholder="business_name"
                          name="business_name"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">Business Type</label>
                        <InputField
                          type="text"
                          value={userData?.business_type}
                          onChange={handleChange}
                          placeholder="Business Type"
                          name="business_type"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">Designation</label>
                        <InputField
                          type="text"
                          value={userData?.designation}
                          onChange={handleChange}
                          placeholder="Designation"
                          name="designation"
                        />
                      </div>
                    </div>
                    <div
                      className="form-group row"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="col-lg-8">
                        {/* <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr,
                          </label>
                        </div> */}
                      </div>
                      <div className="col-lg-4">
                        <div className="btn-wrap text-end">
                          <button
                            className="btn"
                            onClick={(e) => EditProfileHandler(e)}
                            disabled={editLoading}
                          >
                            {editLoading ? <CircleLoad /> : "Save Changes"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_info;
