import React, { useEffect, useState } from "react";
import "../../assets/css/Seller_Inventory.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { detail_order_img, product_inventory } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetSellerRefundOrders } from "../../network/Network";
import ReactPaginate from "react-paginate";
import NoRecord from "../../components/NoRecord/NoRecord";
import Skeleton from "react-loading-skeleton";

const Seller_Refund_Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [refundOrderData, setRefundOrderData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetSellerRefundOrders(currentPage, Token)
      .then((res) => {
        setRefundOrderData(res?.data?.response?.data);
        //   const total = res?.data?.total;
        //   const limit = res?.data?.per_page;
        //   setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <SellerHeader />

      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8 refunr-ore ref-or">
              <Seller_tabs_list />
              <div className="sellers_orders">
                <div className="heading_wrapper d-flex justify-content-between">
                  <h4>Refund Orders</h4>
                  <div className="add-upload-wrapper">
                    {/* <button
                      className="add-products"
                      onclick="location.href='seller-add-product.php'"
                    >
                      Refund Requests
                    </button> */}
                  </div>
                </div>

                <div className="table-resposnive mt-4">
                  <table className="table">
                    <thead>
                      <tr className="topbar">
                        <th scope="col" className="Status">
                          Order ID
                        </th>
                        <th scope="col" className="billingname">
                          Billing Name
                        </th>
                        <th scope="col" className="amount">
                          Amount
                        </th>
                        <th scope="col" className="Price">
                          Item
                        </th>
                        <th scope="col" className="Stock">
                          Refund Request Status
                        </th>
                        <th scope="col" className="Details">
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {spinLoad ? (
                        <tr>
                          {[0, 1, 2, 3]?.map((item, index) => {
                            return (
                              <div className="mt-3" key={index}>
                                <Skeleton count={5} />
                              </div>
                            );
                          })}
                        </tr>
                      ) : refundOrderData?.length > 0 ? (
                        <>
                          {refundOrderData?.map((item) => {
                            return (
                              <tr key={item?.id}>
                                {/* <td className="orderid">#{item?.order_detail_id}</td> */}
                                <td className="orderid">
                                  #{item?.order_detail?.order_id}
                                </td>
                                <td className="billingname">
                                  {item?.user?.name} {item?.user?.last_name}
                                </td>
                                <td className="amount">
                                  ${item?.order_detail?.total}
                                </td>
                                <td className="details">
                                  {item?.order_detail?.qty}
                                </td>
                                <td className="Available">
                                  {" "}
                                  <span className="request">
                                    {" "}
                                    {item?.status == 0
                                      ? "Pending"
                                      : item?.status == 1
                                      ? "accepted"
                                      : item?.status == 2
                                      ? "rejected"
                                      : item?.status == 3
                                      ? "Product Received"
                                      : item?.status == 4
                                      ? "voucher generate"
                                      : null}
                                  </span>{" "}
                                </td>

                                <td className="Detailsu">
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/seller-refund-details/${item?.id}`
                                      )
                                    }
                                    className="btn details-btn"
                                  >
                                    View Details
                                  </button>{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <NoRecord />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="paginations_results">
                  {/* <div className="showing_results">
                    <p>
                      Showing <b>7</b> of over 50 <b>results</b>
                    </p>
                  </div> */}
                  <ul className="paginations">
                    <div className="pagination-container mt-5">
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_Refund_Orders;
