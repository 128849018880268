import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { ad1 } from "../../constants";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/view-banner-ad.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import {
  GetSellerBannerList,
  GetUserBanner,
  PostDeleteBanner,
} from "../../network/Network";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import NoRecord from "../../components/NoRecord/NoRecord";
import ReactPaginate from "react-paginate";
import BannerCard from "../../components/Banner/BannerCard";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const View_Banner_Ad = () => {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [bannerData, setBannerData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [textCopy, setTextCopy] = useState();
  const [copied, setCopied] = useState(false);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetSellerBannerList(currentPage, Token)
      .then((res) => {
        setBannerData(res?.data?.response?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);

  const DeleteBannerHandler = (e, id) => {
    e.preventDefault();
    setSpinLoad(true);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this banner!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let data;
        PostDeleteBanner(id, data, Token)
          .then((res) => {
            setSpinLoad(false);
            setBannerData((preval) => preval.filter((val) => val?.id != id));
          })
          .catch((err) => {
            setSpinLoad(false);
          });
      } else {
        swal("Your banner is safe!");
        setSpinLoad(false);
      }
    });
  };

  return (
    <>
      <Header Header_Cate={false} />
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* View banner ads starts here */}
              <div className="banner-ads">
                <div className="view-banner-ads-heading-wrapper">
                  <h2>View Banner Ads</h2>
                  <h4>
                    {" "}
                    <h4>( {bannerData?.length} )</h4>
                  </h4>
                </div>
                {spinLoad ? (
                  <tr>
                    {[0, 1, 2, 3]?.map((item, index) => {
                      return (
                        <div className="mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })}
                  </tr>
                ) : bannerData?.length > 0 ? (
                  <>
                    {bannerData?.map((item) => {
                      return (
                        <BannerCard
                          item={item}
                          DeleteBannerHandler={DeleteBannerHandler}
                          setTextCopy={setTextCopy}
                          copied={copied}
                          setCopied={setCopied}
                        />
                      );
                    })}
                  </>
                ) : (
                  <NoRecord />
                )}

                {/* <div className="view-card-wrapper">
									<div className="ads-content-wrapper">
										<div className="ads-date-list-wrapper">
											<div className="single-date-wrapper">
												<div className="date">
													<h5>Start Date</h5>
													<h6>12 _ Nov _2022</h6>
												</div>
											</div>
											<div className="single-date-wrapper">
												<div className="date">
													<h5>End Date</h5>
													<h6>22 _ Nov _2022</h6>
												</div>
											</div>
											<div className="single-date-wrapper">
												<div className="date">
													<h5>Banner Price</h5>
													<h6>$ 40.00</h6>
												</div>
											</div>
										</div>
										<div className="ads-brand-link-wrapper">
											<label htmlFor="">Your Brand Link </label>
											<div className="fields-wrapper">
												<input
													type="text"
													placeholder="Lorem ipsum dolor sit amet, consectetur "
													className="form-control"
												/>
												<button className="btn"> Copy Link</button>
											</div>
										</div>
									</div>
									<div className="ads-img-wrapper">
										<div className="ad-img">
											<figure>
												<img src={ad1} className="img-fluid" alt="" />
											</figure>
											<div className="btn-wrapper d-flex justify-content-between w-100 align-items-center px-3">
												<a href="/edit-ad-form" className="edit-btn">
													Edit Ads
												</a>
												<button className="delete">
													<i className="fa fa-trash"></i>
												</button>
											</div>
										</div>
										<div className="expire-date-wrapper">
											<h6>Expire Date :</h6>
											<h6>10 Nov 2022</h6>
										</div>
									</div>
								</div> */}

                <div className="pagination-container mt-5">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>

                <div className="upload-btn-wrapper">
                  <Link to="/banner-ad-form" className="btn">
                    Upload New Banner
                  </Link>
                </div>
              </div>
              {/* View banner ads ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* My adresses ends here */}
      <Footer />
    </>
  );
};

export default View_Banner_Ad;
