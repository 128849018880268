import React, { useState } from "react";
import "../../assets/css/seller-info.css";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { PostSellerBusinessInfo } from "../../network/Network";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CircleLoad from "../../components/Loader/CircleLoad";
import InputField from "../../components/Form/InputField";
import { EditProfileData } from "../../redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";

const Seller_edit_info = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [editLoading, setEditLoading] = useState(false);
  const [userData, setUserData] = useState({
    shop_name: userReduxData?.seller_info?.shop_name
      ? userReduxData?.seller_info?.shop_name
      : "",
    business_contact_no: userReduxData?.seller_info?.business_contact_no
      ? userReduxData?.seller_info?.business_contact_no
      : "",
    trade_license_number: userReduxData?.seller_info?.trade_license_number
      ? userReduxData?.seller_info?.trade_license_number
      : "",
    main_category: userReduxData?.seller_info?.main_category
      ? userReduxData?.seller_info?.main_category
      : "",
    busliness_contact_email: userReduxData?.seller_info?.business_contact_email
      ? userReduxData?.seller_info?.business_contact_email
      : "",
    business_name: userReduxData?.seller_info?.business_name
      ? userReduxData?.seller_info?.business_name
      : "",
    owner_name: userReduxData?.seller_info?.owner_name
      ? userReduxData?.seller_info?.owner_name
      : "",
    Head_office_address: userReduxData?.seller_info?.Head_office_address
      ? userReduxData?.seller_info?.Head_office_address
      : "",
    business_type: userReduxData?.seller_info?.business_type
      ? userReduxData?.seller_info?.business_type
      : "",
  });

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  }

  const EditBusinessInfoHandler = (e) => {
    e.preventDefault();
    setEditLoading(true);
    if (
      !userData?.shop_name ||
      !userData?.business_contact_no ||
      !userData?.trade_license_number ||
      !userData?.main_category ||
      !userData?.business_contact_email ||
      !userData?.business_name ||
      !userData?.owner_name ||
      !userData?.Head_office_address ||
      !userData?.business_type
    ) {
      toast.error("Please Enter All Field");
      setEditLoading(false);
      return;
    }

    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userData?.business_contact_email
      )
    ) {
      toast.error("Invalid Business Email");
      setEditLoading(false);
      return;
    }

    if (
      userData?.business_contact_no?.length < 10 ||
      userData?.business_contact_no?.length > 17
    ) {
      toast.error("Contact no limit is min 10 and max 17 digits");
      setEditLoading(false);
      return;
    }

    let data = new FormData();
    data.append("shop_name", userData?.shop_name);
    data.append("business_contact_no", userData?.business_contact_no);
    data.append("trade_license_number", userData?.trade_license_number);
    data.append("main_category", userData?.main_category);
    data.append("business_contact_email", userData?.business_contact_email);
    data.append("business_name", userData?.business_name);
    data.append("owner_name", userData?.owner_name);
    data.append("Head_office_address", userData?.Head_office_address);
    data.append("business_type", userData?.business_type);
    PostSellerBusinessInfo(data, Token)
      .then((res) => {
        setEditLoading(false);
        toast.success(res?.data?.message);
        navigate("/seller-account");
        dispatch(EditProfileData(res?.data?.response?.data));
      })
      .catch((e) => {
        setEditLoading(false);
        if (e.response.status === 401) {
          toast?.error(e.response.data.message);
          if (e.response.data.errors.length > 0 && e.response.data.message) {
            toast.error(e.response.data.message);
          }
        } else if (e.response.status === 409) {
          e.response.data.errors.map((val) => {
            toast.error(val);
          });
          if (e.response.data.errors.length === 0 && e.response.data.message) {
            toast.error(e.response.data.message);
          }
        } else if (e.response.status === 422 || e.response.status === 405) {
          for (let key in e.response.data.errors) {
            if (e.response.data.errors.hasOwnProperty(key)) {
              toast.error(e.response.data.errors[key][0]);
            }
          }
        } else if (e.response.status === 400 && e.response.errors) {
          for (let key in e.response.data.errors) {
            if (e.response.data.errors.hasOwnProperty(key)) {
              toast.error(e.response.data.errors[key][0]);
            }
          }

          if (e.response.data.errors.length === 0 && e.response.data.message) {
            toast.error(e.response.data.message);
          }
        } else if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.error(e.message);
        }
        //   return data;
      });
  };

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8 refunr-ore pus">
              <Seller_tabs_list />
              <div className="seller-info-wrapper">
                <div className="edit-head-wrapper">
                  <h6>Add Business Info</h6>
                </div>
                <div className="info-form-wrappe">
                  <form action="">
                    <div className="form-group row">
                      <div className="col-lg-5">
                        <label htmlFor="First Name:">Business name</label>
                        <InputField
                          type="text"
                          value={userData?.business_name}
                          onChange={handleChange}
                          placeholder="Business name"
                          name="business_name"
                        />
                      </div>
                      <div className="col-lg-2  px-0"></div>
                      <div className="col-lg-5">
                        <label htmlFor="First Name">Trade License Number</label>
                        <InputField
                          type="text"
                          value={userData?.trade_license_number}
                          onChange={handleChange}
                          placeholder="Trade License Number"
                          name="trade_license_number"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="Email">Email</label>
                        <InputField
                          type="text"
                          value={userData?.business_contact_email}
                          onChange={handleChange}
                          placeholder="Email"
                          name="business_contact_email"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">Owner Name</label>
                        <InputField
                          type="text"
                          value={userData?.owner_name}
                          onChange={handleChange}
                          placeholder="Owner Name"
                          name="owner_name"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">Business Type</label>
                        <InputField
                          type="text"
                          value={userData?.business_type}
                          onChange={handleChange}
                          placeholder="Business Type"
                          name="business_type"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">
                          Business Contact Number
                        </label>
                        <InputField
                          type="text"
                          value={userData?.business_contact_no}
                          onChange={handleChange}
                          placeholder="Business Contact Number"
                          name="business_contact_no"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">Shop Name</label>
                        <InputField
                          type="text"
                          value={userData?.shop_name}
                          onChange={handleChange}
                          placeholder="Shop Name"
                          name="shop_name"
                        />
                      </div>
                      {/* <div className="col-lg-2  px-0"></div> */}
                      <div className="col-lg-4">
                        <label htmlFor="First Name:">
                          Main Products Catagories
                        </label>
                        <InputField
                          type="text"
                          value={userData?.main_category}
                          onChange={handleChange}
                          placeholder="Main Products Catagories"
                          name="main_category"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <label htmlFor="First Name:">Head Office Address</label>
                        <InputField
                          type="text"
                          value={userData?.Head_office_address}
                          onChange={handleChange}
                          placeholder="Head Office Address"
                          name="Head_office_address"
                        />
                      </div>
                    </div>
                    <div
                      className="form-group row"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="col-lg-8">
                        {/* <div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														value=""
														id="flexCheckDefault"
													/>
													<label
														className="form-check-label"
														for="flexCheckDefault"
													>
														Lorem ipsum dolor sit amet, consetetur sadipscing
														elitr,
													</label>
												</div> */}
                      </div>
                      <div className="col-lg-4">
                        <div className="button-group text-end">
                          <button
                            className="btn"
                            onClick={(e) => EditBusinessInfoHandler(e)}
                            disabled={editLoading}
                          >
                            {editLoading ? <CircleLoad /> : "Save Changes"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_edit_info;
