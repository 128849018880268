import React, { useEffect, useState } from "react";
import "../../assets/css/Seller_Inventory.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { detail_order_img, product_inventory } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { DeleteSellerProduct, GetSellerProducts } from "../../network/Network";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../components/NoRecord/NoRecord";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Seller_Inventory = () => {
  const navigate = useNavigate()
  const Token = useSelector((state) => state.AuthReducer.token);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [sellerProducts, setSellerProducts] = useState([]);
  const [search, setSearch] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const SellerProductHandler = (e = null) =>{
    if (e) {
			e.preventDefault();
		}
    setSpinLoad(true)
    GetSellerProducts(currentPage, Token, search)
      .then((res) => {
        setSellerProducts(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
        setSearch("")
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }

  useEffect(() => {
    SellerProductHandler(null)
  }, [currentPage]);



  const DeleteProductsHandler = (e,id) => {
    e.preventDefault()
    // setSpinLoad(true);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let data;
        DeleteSellerProduct(id, data, Token)
          .then((res) => {
            // setSpinLoad(false);
            setSellerProducts((preval) =>
              preval.filter((val) => val?.id != id)
            );
          })
          .catch((err) => {
          });
      } else {
        swal("Your product is safe!");
        setSpinLoad(false);
      }
    });
  };


  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <Seller_tabs_list />

              <div className="sellers_orders">
                <div className="heading_wrapper d-flex justify-content-between">
                  <h4>Product Inventory</h4>
                  <div className="input-group">
                    <div className="form-outline">
                      <input
                        type="search"
                        placeholder="Search Products"
                        id="form1"
                        className="form-control"
                        value={search}
                        onChange={(e)=>setSearch(e.target.value)}
                      />
                    </div>
                    <button type="button" className="btn btn-primary"
                      onClick={(e)=>SellerProductHandler(e)}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                  <div className="add-upload-wrapper">
                    <button className="add-products" onClick={()=>navigate('/seller-add-product')}>
                      Add
                    </button>
                  </div>
                </div>

                <div className="table-resposnive mt-4">
                  <table className="table">
                    <thead style={{ width: "946px" }}>
                      <tr className="topbar">
                        <th scope="col" className="Status">
                          Status
                        </th>
                        <th scope="col" className="billingname">
                          Product Name
                        </th>
                        <th scope="col" className="amount">
                          SKU number
                        </th>
                        <th scope="col" className="Image">
                          Image
                        </th>
                        <th scope="col" className="shipmentstatus">
                          Date
                        </th>
                        <th scope="col" className="Price">
                          Price
                        </th>
                        <th scope="col" className="Stock">
                          Stock Available
                        </th>
                        <th scope="col" className="Details">
                          Details
                        </th>
                        <th scope="col" className="Action">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {spinLoad ? (
                        <tr>
                          {[0, 1, 2, 3]?.map((item, index) => {
                            return (
                              <div className="mt-3" key={index}>
                                <Skeleton count={5} />
                              </div>
                            );
                          })}
                        </tr>
                      ) : sellerProducts?.length > 0 ? (
                        <>
                          {sellerProducts?.map((item, index) => {
                            return (
                              <tr style={{ width: "946px" }} key={index}>
                                <td className="orderid">{item?.status == 1 ? "Active" : "Deactivated"}</td>
                                <td className="billingname">{item?.title}</td>
                                <td className="amount">CA12345</td>
                                <td className="items">
                                  <img
                                    src={item?.image_url}
                                    className="pro-table-img"
                                    alt=""
                                  />
                                </td>
                                <td className="shipmentstatus">
                                  {moment(item?.created_at).format(
                                    "MMMM Do YYYY, h:mm a"
                                  )}
                                </td>
                                <td className="details">
                                  ${item?.latest_price}{" "}
                                </td>
                                <td className="Available">{item?.stock}</td>

                                <td className="Detailsu">
                                  <button
                                    onClick={() =>navigate(`/seller-inventory-product/${item?.id}`)}
                                    className="btn details-btn"
                                  >
                                    View Details
                                  </button>{" "}
                                </td>
                                <td className="Detailsu">
                                  <div className="button-wrap">
                                    <button className="edit"
                                      onClick={()=>navigate(`/seller-edit-product/${item?.id}`)}
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button className="edit"
                                      onClick={(e)=>DeleteProductsHandler(e,item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <NoRecord />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="paginations_results">
                  <div className="showing_results">
                    <p>
                      Showing <b>{sellerProducts?.length}</b> <b>results</b>
                      {/* of over 50 */}
                    </p>
                  </div>
                  <ul className="paginations">
                    <div className="pagination-container mt-5">
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_Inventory;
