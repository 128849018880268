import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/checkout.css";
import { check1, check2, visa } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  GetAllAddress,
  PostMakeDefaultAddress,
  PostPlaceOrder,
} from "../../network/Network";
import { AllAddress } from "../../redux/actions/AuthActions";
import { ClearCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";
import CheckoutProductList from "../../components/Checkout/CheckoutProductList";
import Coupon from "../../components/Checkout/Coupon";
import StripeForm from "../../components/PaymentMethod/StripeForm";

const Checkout = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const Location = useLocation();
  const SiteSettingsRedux = useSelector(
    (state) => state.AuthReducer.siteSettings
  );
  const Token = useSelector((state) => state.AuthReducer.token);
  const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const AddressDataRedux = useSelector((state) => state.AuthReducer.address);
  const userData = useSelector((state) => state.AuthReducer.users);
  const CheckOutData = Location?.state?.data;
  const [subTotal, setSubTotal] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState();
  const [postAddress, setPostAddress] = useState();

  const [selectedDefaultAddressId, setSelectedDefaultAddressId] =
    useState(null);

  const [loading, setLoading] = useState(false);

  const TotalAmount = () => {
    let i;
    let total = 0;
    for (i = 0; i < CheckOutData?.CartData?.length; i++) {
      // console.log(CheckOutData?.CartData[i]);
      total =
        total +
        CheckOutData?.CartData[i]?.price * CheckOutData?.CartData[i]?.qty;
    }
    setSubTotal(total);
  };

  useEffect(() => {
    TotalAmount();
  }, [CartDataRedux]);

  useEffect(() => {
    // setSpinLoad(true);
    GetAllAddress(Token)
      .then((res) => {
        dispatch(AllAddress(res?.data?.response?.data));
        const extractDefaultAddress = res?.data?.response?.data?.filter(
          (obj) => obj.is_default === 1
        );

        const selectedDefaultAddressIds = extractDefaultAddress?.map(
          (obj) => obj.id
        );

        const selectedDefaultAddressId = selectedDefaultAddressIds?.[0];

        setSelectedDefaultAddressId(selectedDefaultAddressId);
      })
      .catch((err) => {
      });
  }, [postAddress]);

  const MakeDefault_Handler = (e, id) => {
    e.preventDefault();
    let data = {
      id: id,
    };
    PostMakeDefaultAddress(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setPostAddress(res?.data);
      })
      .catch((err) => {
      });
  };

  const PlaceOrderHandler = (e, stripe_token) => {
    e.preventDefault();
    setLoading(true);
    let data = new FormData();
    data.append("token", stripe_token);
    data.append("payment_method", "stripe");
    data.append("shipping", SiteSettingsRedux?.shippingFees);
    data.append("address_id", selectedDefaultAddressId);
    data.append(
      "discount_amount",
      couponData?.value ? couponData?.value : 0
    );
    data.append("discount_type", "coupon");
    data.append("total_amount", subTotal);
    data.append("coupon", couponData?.code ? couponData?.code : " ");
    data.append("cart", JSON.stringify(CheckOutData?.CartData));
    PostPlaceOrder(data, Token)
      .then((res) => {
        setLoading(false);
        dispatch(ClearCart());
        toast.success(res?.data?.message);
        setTimeout(() => {
          Navigate("/thank_you", {
            state: {
              data: res?.data?.response?.data,
            },
          });
        }, 600);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.errors[0]?.code == "stock") {
          toast?.error(err?.response?.data?.errors[0]?.message);
        }
        if (err?.response?.data?.errors) {
          toast?.error(err?.response?.data?.errors);
        }
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  const styling = {
    marginTop: "10px",
    width: "100%",
    padding: "8px 16px",
    background: "#9a3407",
    color: "#fff",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectDefaultHandler = (e, addressId) => {
    setSelectedDefaultAddressId(addressId);
  };

  return (
    <>
      <Header Header_Cate={false} />
      <section className="checkout">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="main-heading-wrapper">
                <h3>Order Checkout</h3>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
          <form action="/Thank_you">
            <div className="row">
              <div className="col-lg-6">
                {/* <div className="first-top-bar"> */}
                {/* <h3>Shipment Details</h3> */}
                {/* <!-- <p>Already Have an account? <a href="login.php">Log in</a> </p> --> */}
                {/* </div> */}

                <div className="saved-address">
                  <div className="title">
                    <h5>Use Saved Addresses</h5>
                  </div>
                  {AddressDataRedux?.map((item, index) => {
                    return (
                      <label className="container" key={index}>
                        <input
                          type="radio"
                          checked={item.id === selectedDefaultAddressId}
                          onChange={() => setSelectedDefaultAddressId(item.id)}
                          name="radio"
                        />
                        <span className="checkmark">
                          <div className="select-address">
                            <div className="selection">
                              <i className="fa fa-check" aria-hidden="true"></i>
                            </div>
                            <div className="radio-btn">
                              <span></span>
                            </div>
                            <div className="address-detail">
                              <h5>
                                <span className="property">Country:</span>
                                <span className="value">{item?.country}</span>
                                <span className="property pl-4">City:</span>
                                <span className="value">{item?.city}</span>
                                <span className="property pl-4">State:</span>
                                <span className="value">{item?.state}</span>
                              </h5>
                              <h5>
                                <span className="property">Address:</span>
                                <span className="value">
                                  {item?.address}, {item?.appartment}
                                </span>
                              </h5>
                              <h5>
                                <span className="property">Phone:</span>
                                <span className="value">+{item?.phone}</span>
                              </h5>
                              {
                                item?.is_default === 1 ? (null) : (
                                  <button
                                  className="btn default"
                                  disabled={item?.is_default === 1 ? true : false}
                                  onClick={(e) =>
                                    MakeDefault_Handler(e, item?.id)
                                  }
                                >
                                  Default
                                </button>
                                )
                              }
                            
                            </div>
                          </div>
                        </span>
                      </label>
                    );
                  })}
                  <Link to="/my-address">
                    <button
                      style={{
                        marginTop: "10px",
                        width: "100%",
                        padding: "8px 16px",
                        background: "rgb(154, 52, 7)",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      {" "}
                      Add New Address
                    </button>
                  </Link>
                </div>
                {/* <div className="add-new-address">
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label for="fname">First Name</label>
												<input
													type="text"
													id="fname"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label for="lname">Last Name</label>
												<input
													type="text"
													id="lname"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label for="phone">Phone</label>
												<input
													type="text"
													id="phone"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label for="email">Email</label>
												<input
													type="text"
													id="email"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label for="address">Address</label>
												<input
													type="text"
													id="address"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label for="apartmnt">Suite, Apartment, etc </label>
												<input
													type="text"
													id="apartmnt"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label for="city">City</label>
												<input type="text" id="city" className="form-control" />
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<label for="region">Country/Region</label>
												<input
													type="text"
													id="region"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<label for="state">State</label>
												<input
													type="text"
													id="state"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<label for="zip-code">Zip Code</label>
												<input
													type="text"
													id="zip-code"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-check">
												<input
													type="checkbox"
													className="form-check-input"
													id="exampleCheckssd"
												/>
												<label
													className="form-check-label label_ssd"
													for="exampleCheckssd"
												>
													Save Shipping Details
												</label>
											</div>
										</div>
									</div>
								</div> */}
                <div className="payment-details">
                  <div className="heading">
                    <h3>Payment Details</h3>
                  </div>
                  <div className="saved-payment-method">
                    {/* <h5>Use Saved Payments</h5> */}
                    {/* <!-- Saved payments Method --> */}
                    <StripeForm
                      styling={styling}
                      paynow="Confirm Order"
                      PaymentHandler={PlaceOrderHandler}
                      loading={loading}
                    />
                    {/* <!-- Saved payments Method --> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                <div className="second-top-bar">
                  <div className="title pb-4">
                    <h3>Order Summary</h3>
                  </div>

                  <Coupon
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    couponData={couponData}
                    setCouponData={setCouponData}
                    subTotal={subTotal}
                  />
                </div>
                <CheckoutProductList
                  CheckOutData={CheckOutData}
                  subTotal={subTotal}
                  couponData={couponData}
                  shippingFees={SiteSettingsRedux?.shippingFees}
                />
              </div>
            </div>
            <div className="border-last"></div>
          </form>
        </div>
      </section>
      {/* checkout ends here */}
      {/* Footer starts here */}
      <Footer />
      {/* Footer ends here */}
    </>
  );
};

export default Checkout;
