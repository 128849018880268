import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SellerProfile, logout } from "../../../redux/actions/AuthActions";
import { Link } from "react-router-dom";
import { GetSellerProfile, onBoardingApi } from "../../../network/Network";
import SellerDashboardOnBoardModal from "../../../components/Modal/SellerDashboardOnBoardModal";

const Customer_profile = ({ profileReviews }) => {
	const dispatch = useDispatch();
	const userReduxData = useSelector((state) => state.AuthReducer.users);
	const Token = useSelector((state) => state.AuthReducer.token);
	// const allReviews = userReduxData?.Ratings;
	const [logout_Load, setLogout_Load] = useState(false);

	const [isOpenPaymentLinkModal, setIsOpenPaymentLinkModal] = useState(false);
	const [modalData, setModalData] = useState();

	// const LogoutHandler = (e) => {
	//   setLogout_Load(true);
	//   e.preventDefault();
	//   setTimeout(async () => {
	//     let x = await dispatch(logout());
	//     setLogout_Load(false);
	//     window.location.href = "/";
	//   }, 600);
	// };

	const LogoutHandler = (e) => {
		setLogout_Load(true);
		e.preventDefault();
		setTimeout(async () => {
			let x = await dispatch(logout());
			setLogout_Load(false);
			window.location.href = "/sign-in";
		}, 600);
	};

	const onBoardingHandler = (e) => {
		e.preventDefault();
		let data ={}
		onBoardingApi(data,Token)
			.then((res) => {
				setIsOpenPaymentLinkModal(true);
				setModalData(res?.data?.response?.data);
			})
			.catch((err) => {
			});
	};

	return (
		<>
			{/* customer review starts here */}
			<div className="customer-review-wrapper">
				<div className="heading-wrapper">
					<h3>Customer Reviews</h3>
					<Link to="/seller-reviews">View More</Link>
				</div>
				<div className="ratings-wrap">
					{profileReviews?.allReviews !== undefined &&
						Object.keys(profileReviews?.allReviews)?.map(function (key, i) {
							const total = profileReviews?.allReviews["total"];
							let stars = key?.split("_")[0];
							if (stars !== "" || stars !== null) {
								if (stars === "one") {
									stars = "1";
								} else if (stars === "two") {
									stars = "2";
								} else if (stars === "three") {
									stars = "3";
								} else if (stars === "four") {
									stars = "4";
								} else {
									stars = "5";
								}
							}
							if (key !== "total" && profileReviews?.allReviews[key] !== 0) {
								return (
									<React.Fragment key={i}>
										<div className="single-rate">
											<div className="progress-wrapper">
												<h6>
													{" "}
													{`${stars} Star ( ${profileReviews?.allReviews[key]} )`}
												</h6>
												<div className="pro">
													<div
														className="per"
														style={{
															width: `${
																Math.round(
																	(profileReviews?.allReviews[key] * 100) /
																		total,
																) || 0
															}%`,
														}}
													></div>
												</div>
											</div>
											<div className="percent">
												<h6>
													{Math.round(
														(profileReviews?.allReviews[key] * 100) / total,
													) || 0}{" "}
													%
												</h6>
											</div>
										</div>
									</React.Fragment>
								);
							}
							return null;
						})}
				</div>
			</div>
			<div className="btns-wrapper">
				{profileReviews?.isOnboarding !== undefined &&
				profileReviews?.isOnboarding === 0 ? (
					<a
						className="btn btn-green"
						onClick={(e) => {
							onBoardingHandler(e);
						}}
					>
						<i className="fa-brands fa-cc-stripe"></i>
						{/* <span>Complete on boarding</span>{" "} */}
						<span>Complete on boarding</span>
					</a>
				) : null}

				<a onClick={(e) => LogoutHandler(e)} className="btn btn-yellow">
					<i className="fa-solid fa-arrow-right-from-bracket"></i>
					<span>{logout_Load ? "Loading.." : "Log Out"}</span>{" "}
				</a>
			</div>
			{/* customer review ends here */}

			<SellerDashboardOnBoardModal 
  isOpenPaymentLinkModal={isOpenPaymentLinkModal}
  setIsOpenPaymentLinkModal={setIsOpenPaymentLinkModal}
  modalData={modalData}
/>
		</>
	);
};

export default Customer_profile;
