import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { orderimg1, return_pic } from "../../constants";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/return-detail.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetUserRefundOrderDetails,
  GetUserRefundOrders,
} from "../../network/Network";
import moment from "moment";

const Return_detail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [refundOrderData, setRefundOrderData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  let currentUrl = window.location.href.split("/");

  useEffect(() => {
    setSpinLoad(true);
    GetUserRefundOrderDetails(currentUrl[4], Token)
      .then((res) => {
        setRefundOrderData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  return (
    <>
      <Header Header_Cate={false} />
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* return request starts here */}

              <div className="return-detail-wrapper">
                <div className="return-heading-wrapper">
                  <h3>Return Requests</h3>
                </div>
                <div className="order-return-wrapper">
                  <div className="order-id-wrapper">
                    <span>
                      Order #{refundOrderData?.order_detail?.order_id} was
                      placed on{" "}
                      {moment(refundOrderData?.created_at).format(
                        "MMMM Do YYYY, h:mm a"
                      )}
                    </span>
                  </div>
                  <div className="return-details-wrapper">
                    <h5>Refund Detail</h5>
                  </div>
                  <div className="retun-pro-headi-wrapper">
                    <h5>Return Product</h5>
                  </div>
                  <div className="product-refund-wrapper">
                    <div className="img-wrapper">
                      <figure>
                        <img
                          src={
                            refundOrderData?.order_detail?.product?.image_url
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div className="refund-content-wrapper">
                      <div className="head-date-wrapper">
                        <h5>
                          {refundOrderData?.order_detail?.product?.title}{" "}
                        </h5>
                        <h6>Delivery Date</h6>
                      </div>
                      <div className="size-wrapper">
                        <div className="size-ql-wrapper">
                          <ul>
                            <li>
                              Size {refundOrderData?.order_detail?.variant?.sku}
                            </li>
                            <li>
                              Quantity {refundOrderData?.order_detail?.qty}
                            </li>
                          </ul>
                        </div>
                        <div className="size-date-wrapper">
                          <span>
                            {" "}
                            {moment(
                              refundOrderData?.order_detail?.updated_at
                            ).format("MMMM Do YYYY, h:mm a")}
                          </span>
                        </div>
                      </div>
                      <div className="price-wrapper">
                        <h5>${refundOrderData?.order_detail?.total}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="return-reason-wrapper">
                    <div className="reason-heading-wrapper">
                      <h5>Return Reason</h5>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      disabled={true}
                      placeholder={refundOrderData?.reason}
                    />
                  </div>
                  <div className="return-picctures-wrapper">
                    <div className="pictures-head-wrapper">
                      <h5>Return Products Pictures</h5>
                    </div>
                    <div className="pictures-wrapper">
                      {refundOrderData?.multimedia?.map((item, index) => {
                        return (
                          <img
                            key={item}
                            src={item?.image_url}
                            className="img-fluid"
                            alt=""
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="comment-head-wrapper">
                    <h5>Comment</h5>
                  </div>
                  <div className="comment-wrapper">
                    <p>{refundOrderData?.note}</p>
                  </div>
                </div>
              </div>
              {/* return request ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* My adresses ends here */}
      <Footer />
    </>
  );
};

export default Return_detail;
