import React, { useEffect, useState } from "react";
import "../../assets/css/seller-dashboard.css";
import "../../assets/css/seller-order-details.css";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { detail_order_img } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetSellerOrdersById } from "../../network/Network";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const Seller_order_detail = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  let currentUrl = window.location.href.split("/");
  const [orderData, setOrderData] = useState();
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetSellerOrdersById(currentUrl[4], Token)
      .then((res) => {
        setOrderData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);
  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <Seller_tabs_list />

              <div className="order_details" style={{ marginTop: "30px" }}>
                <div className="order-no">
                  <p>
                    Order #
                    {orderData?.order_details
                      ? orderData?.order_details[0]?.order_id
                      : " "}{" "}
                    was placed on{" "}
                    {orderData
                      ? moment(orderData?.created_at).format(
                          "MMMM Do YYYY, h:mm a"
                        )
                      : " "}
                  </p>
                  {/* <p className="m-0">Status: {" "}{orderData?.status == 0
                                        ? "Pending"
                                        : orderData?.status == 1
                                        ? "accepted"
                                        : orderData?.status == 2
                                        ? "rejected"
                                        : orderData?.status == 3
                                        ? "Product Received"
                                        : orderData?.status == 4
                                        ? "voucher generate"
                                        : null}</p> */}
                </div>
                <div className="about-order">
                  <div className="title">
                    <h4>Order Detail</h4>
                  </div>

                  {spinLoad ? (
                    <>
                      {[0, 1, 2, 3]?.map((item, index) => {
                        return (
                          <div className="mt-3" key={index}>
                            <Skeleton count={5} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {orderData?.order_details?.map((item, index) => {
                        return (
                          <>
                          <div className="product detailed" key={index}>
                            <div className="property">
                              <p>Product :</p>
                            </div>
                            <div className="value">
                              <img src={item?.product?.image_url} alt="" />
                            </div>
                            <div className="property">
                              <p>Product Name :</p>
                            </div>
                            <div className="value">
                              <p>{item?.product?.title} </p>
                            </div>
                          </div>

                          <div className="product detailed">
                          <div className="property">
                            <p>Quantity :</p>
                          </div>
                          <div className="value">
                          <p>{item?.qty}</p>
                          </div>
                          <div className="property">
                            <p>variant :</p>
                          </div>
                          <div className="value">
                            <p>{item?.variant} </p>
                          </div>
                          <div className="property">
                            <p>Price :</p>
                          </div>
                          <div className="value">
                            <p>{item?.price} </p>
                          </div>
                        </div>
                        </>
                        );
                      })}
                    </>
                  )}

                  {/* <div className="product-name detailed">
										<div className="property">
											<p>Product Name :</p>
										</div>
										<div className="value">
											<p>Koizuma Massage Gun </p>
										</div>
									</div> */}
                  <div className="subtotal detailed">
                    <div className="property">
                      <p>Sub Total :</p>
                    </div>
                    <div className="value">
                      <p>$ {orderData?.sellerSubTotal}</p>
                    </div>
                  </div>
                  <div className="shipping detailed">
                    <div className="property">
                      <p>Shipping</p>
                    </div>
                    <div className="value">
                      <p>$ {orderData?.shipping}</p>
                    </div>
                  </div>
                  <div className="payment-method detailed">
                    <div className="property">
                      <p>Payment method</p>
                    </div>
                    <div className="value">
                      <p>{orderData?.payment_method}</p>
                    </div>
                  </div>

                  <div className="net-total detailed">
                    <div className="property">
                      <p>Admin Commission</p>
                    </div>
                    <div className="value">
                      <p>$ 0</p>
                    </div>
                  </div>
                  <div className="net-total detailed">
                    <div className="property">
                      <p>Total</p>
                    </div>
                    <div className="value">
                      <p>
                        ${orderData?.sellerSubTotal}
                        {/* {parseInt(orderData?.shipping) +
                          parseInt(orderData?.sub_total)} */}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  <Link to="/seller-order" className="print_order btn">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_order_detail;
