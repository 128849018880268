import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/banner-ad-form.css";
import { PostUserBanner } from "../../network/Network";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "../../components/Form/InputField";
import { useState } from "react";
import { useSelector } from "react-redux";
import StripeForm from "../../components/PaymentMethod/StripeForm";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import SellerHeader from "../../components/SellerHeader/SellerHeader";

const Banner_ads_form = () => {
	const navigate = useNavigate()
	const Token = useSelector((state) => state.AuthReducer.token);
	const userReduxData = useSelector((state) => state.AuthReducer.users);
	const SiteSetting = useSelector((state) => state.AuthReducer.siteSettings);
	const new_date = new Date().toISOString().slice(0, 10);
	const [logo, setLogo] = useState("");
	const [logoFileupload, setLogoFileupload] = useState("");
	const [loading, setLoading] = useState(false);
	const [price, setPrice] = useState();
	const [userData, setUserData] = useState({
		text: "",
		link: "",
		start_date: "",
		end_date: "",
		shortDescription: "",
	  });
	
	  const styling = {
		width: "100%",
		backgroundImage: "linear-gradient( to bottom, #71966d, #6a9166, #648b5f, #5d8659, #578152 )",
		color: "#fff",
		marginTop: "20px",
		fontFamily: "muli-bold",
		textTransform: "uppercase",
		fontSize: "14px",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "50px",
	  };

	
	  function handleChange(e) {
		const value = e.target.value;
		setUserData({
		  ...userData,
		  [e.target.name]: value,
		});
	  }

	const HandleBannerUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
		  setLogo(file);
		  setLogoFileupload(reader.result);
		};
		reader.readAsDataURL(file);
	  };

	const PostProductHandler = (e, stripe_token) => {
		e.preventDefault();
		setLoading(true);
		setPrice(userData?.start_date && userData?.end_date ? SiteSetting?.bannerFee * ToTalDaysHandler() : 0)
		if(!userData?.text){
			toast.error("Please enter banner text")
			setLoading(false);
			return
		}
		if(userData?.text?.length < 8 || userData?.text?.length > 30){
			toast.error("Text length must between 8 to 30 characters ")
			setLoading(false);
			return
		}
		if(!userData?.link){
			toast.error("Please enter your link")
			setLoading(false);
			return
		}
		if(!logo){
			toast.error("Please upload your banner image")
			setLoading(false);
			return
		}
		if(!userData?.start_date){
			toast.error("Please select banner starting date")
			setLoading(false);
			return
		}
		if(!userData?.end_date){
			toast.error("Please select banner end date")
			setLoading(false);
			return
		}
		if(!price){
			toast.error("Please select banner end date")
			setLoading(false);
			return
		}
		let data = new FormData();
		data.append("text", userData?.text);
		data.append("link", userData?.link);
		data.append("image", logo);
		data.append("start_date", userData?.start_date);
		data.append("end_date",userData?.end_date );
		data.append("is_active", 1);
		data.append("added_by", userReduxData?.user_type == 2 ? "seller" : "user");
		data.append("token", stripe_token);
		data.append("price",  price);
		PostUserBanner(data, Token)
		  .then((res) => {
			toast.success(res?.data?.message);
			setLoading(false);
			if(userReduxData?.user_type == 2){
				navigate('/seller-banner-ads')
			}
			else{
				navigate('/view-banner-ads')
			}
		  })
		  .catch((err) => {
			setLoading(false);
		  });
	  };

	  const ToTalDaysHandler = () =>{
		var start = new Date(userData?.start_date)
		var end =  new Date(userData?.end_date)
		var time =Math.abs(end - start)
		var days = Math.ceil(time / (1000 * 60 * 60 *24))
		// console.log("Total number of days between dates  <br>"
		// 		   +  start + "<br> and <br>" 
		// 		   + end + " is: <br> " 
		// 		   + days);
		if(days){
			return days
		}
	  }

	  useEffect(() => {
		if(!Token ){
		  navigate('/')
		}
	  }, [])

	return (
		<>
			{/* Modal popup starts here */}
			<div
				className="modal fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-content-wrapper">
							<div className="icon-wrapper">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="75.292"
									height="75.301"
									viewBox="0 0 75.292 75.301"
								>
									<path
										id="Icon_awesome-check-double"
										data-name="Icon awesome-check-double"
										d="M74.264,25.706l-5.823-5.823a3.515,3.515,0,0,0-4.985,0L28.238,55.1l-16.381-16.4a3.515,3.515,0,0,0-4.985,0L1.033,44.544a3.532,3.532,0,0,0,0,5l24.7,24.719a3.515,3.515,0,0,0,4.985,0L74.25,30.706A3.546,3.546,0,0,0,74.264,25.706ZM26.576,41.294a2.334,2.334,0,0,0,3.323,0L60.486,10.678a2.361,2.361,0,0,0,0-3.323L53.839.693a2.334,2.334,0,0,0-3.323,0L28.238,22.971,20.091,14.81a2.334,2.334,0,0,0-3.323,0l-6.661,6.661a2.361,2.361,0,0,0,0,3.323Z"
										transform="translate(0.004 0.002)"
										fill="#3e7439"
									/>
								</svg>
							</div>
							<div className="pop-content-wrapper">
								<h3>your Ads Banner Payment Was Successful </h3>
								<p>
									Your Banner Successful to Upload For <br /> This Website.
								</p>
								<a href="#" className="btn">
									Go To Home Page
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Modal popup ends here */}
			{/* Header starts here */}
			{
			 userReduxData?.user_type == 2	? (	<SellerHeader  />) : (<Header />) 
			}
		
			{/* Header ends here */}
			{/* Banner ad form starts here */}
			<section className="banner-ad-form">
				<div className="container">
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="row">
								<div className="col-lg-3"></div>
								<div className="col-lg-6">
									<div className="banner-main-head-wrapper">
										<h2>Banner Ads</h2>
									</div>
									<div className="banner-form-wrapper">
										<div className="banner-add-heading-wrapper">
											<h3>Advertise Your Brand</h3>
										</div>
										<div className="per-day-wrapper">
											<span className="property">
												Per Day Charges Your Brand Promotion :
											</span>
											<span className="value">$ {SiteSetting?.bannerFee}</span>
										</div>
										<form action="">
											<div className="form-group row">
												<div className="col-lg-6">
													<label htmlFor="">Start Date</label>
													<input
														type="date"
														className="form-control"
														name="start_date"
														id=""
														min={new_date}
														onChange={handleChange}
														onKeyDown={(e) => e.preventDefault()}
													/>
												</div>
												<div className="col-lg-6">
													<label htmlFor="">End Date</label>
													<input
														type="date"
														className="form-control"
														name="end_date"
														id=""
														min={new_date}
														onChange={handleChange}
														onKeyDown={(e) => e.preventDefault()}
													/>
												</div>
											</div>
											<div className="form-group">
												<label htmlFor="">Upload Banner Image</label>
												<label className="tag" htmlFor="inputTag">
													<div className="fiel-wrapper">
														<span>Attach File</span>
														<i className="fa fa-paperclip"></i>
													</div>
													<input
														id="inputTag"
														type="file"
														name=""
														className="form-comtrol"
														accept="image/*"
														onChange={HandleBannerUpload}
														multiple={false}
													/>
													<span id="imageName"></span>
												</label>
												<div className="img-cont">
												{logo ? (
                              <img
                                src={logoFileupload}
                                className="image-fluid image-width"
                                alt=""
                              />
                            ) : null}
												</div>
											</div>
											<div className="form-group">
												<label htmlFor="">Your Brand Link </label>
												<InputField
													type="text"
													value={userData?.link}
													onChange={handleChange}
													placeholder="Paste Here"
													className="form-control"
													name="link"
												/>
												
											</div>
											<div className="form-group">
												<label htmlFor="">Text </label>
												<InputField
													type="text"
													value={userData?.text}
													onChange={handleChange}
													placeholder="Text Here"
													className="form-control"
													name="text"
												/>
												
											</div>
											<div className="adver-wrapper">
												<ul>
													<li>
														<span className="property">
															Advertise Banner Duration :
														</span>
														<span className="value">{ userData?.start_date && userData?.end_date ? ToTalDaysHandler() : 0} Days</span>
													</li>
													<li>
														<span className="property">Total Ads Price :</span>
														<span className="value">$ {userData?.start_date && userData?.end_date ? SiteSetting?.bannerFee * ToTalDaysHandler() : 0}</span>
													</li> 
												</ul>
											</div>
											<div className="form-group">
											<StripeForm
												styling={styling}
												paynow="Pay Now"
												PaymentHandler={PostProductHandler}
												loading={loading}
											/>
											</div>
										
										</form>
									</div>
								</div>
								<div className="col-lg-3"></div>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			{
			 userReduxData?.user_type == 2	? (	<SellerFooter />) : (<Footer />) 
			}

		</>
	);
};

export default Banner_ads_form;
