import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logo } from "../../constants";
import {
  GetAllWishList,
  GetSiteInfo,
  GetSubCategories,
} from "../../network/Network";
import { logout, WebSiteSettings } from "../../redux/actions/AuthActions";
import { ClearCart } from "../../redux/actions/CartActions";

const Header = (props) => {
  const { Header_Cate, setCate_id, setSearchText, page } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let currentUrl = window.location.href.split("/");
  const Token = useSelector((state) => state.AuthReducer.token);
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const CategoriesData = useSelector((state) => state.AuthReducer.categories);
  const SiteSettingsRedux = useSelector(
    (state) => state.AuthReducer.siteSettings
  );
  const [logout_Load, setLogout_Load] = useState(false);
  const [search, setSearch] = useState();
  const [category_id, setCategory_id] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fvtData, setFvtData] = useState([]);
  const [pageCount, setPageCount] = useState();

  const SearchFilterHandler = (e) => {
    if (e.key === "Enter" || e) {
      e.preventDefault();
      if (currentUrl[3] == "shop") {
        // setCate_id(category_id)
        // setSearchText(e.target.value)
        navigate("/shop", {
          state: {
            paramCat_id: category_id,
            paramSearch: search,
            type: "cate",
          },
        });
        window.location.reload();
        return;
      }
      navigate("/shop", {
        state: {
          paramCat_id: category_id,
          paramSearch: search,
          type: "cate",
        },
      });
    }
  };

  const SearchByCateHandler = (e, id) => {
    // e.preventDefault();
    if (currentUrl[3] == "shop") {
      navigate("/shop", {
        state: {
          paramSubCat_id: id,
          type: "cate",
        },
      });
      window.location.reload();
      return;
    }
    navigate("/shop", {
      state: {
        paramCat_id: id,
        type: "cate",
      },
    });
  };

  // site Setting
  useEffect(() => {
    GetSiteInfo()
      .then((res) => {
        dispatch(WebSiteSettings(res?.data?.response?.data));
        // setFvtData(res?.data?.response?.data);
      })
      .catch((err) => {
      });
  }, []);

  const LogoutHandler = (e) => {
    setLogout_Load(true);
    e.preventDefault();
    setTimeout(async () => {
      let x = await dispatch(logout());
      setLogout_Load(false);
      window.location.href = "/sign-in";
    }, 600);
  };

  const [category, showcategory] = useState(false);
  function showcard() {
    showcategory(true);
  }
  function hidecard() {
    showcategory(false);
  }

  // Get wishlist Data
  useEffect(() => {
    setSpinLoad(true);
    GetAllWishList(currentPage, Token)
      .then((res) => {
        setFvtData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      {/* <!-- feedback modals ends here --> */}
      <div
        className="modal fade rev"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {/* <span aria-hidden="true">&times;</span> */}
            </button>
            <div className="modal-body text-center">
              <h4>Feedback</h4>
              <form action="">
                <div className="form-group">
                  <textarea
                    name=""
                    placeholder="Lorem Ipsum"
                    className="form-control"
                    id=""
                    cols="30"
                    rows="5"
                  ></textarea>
                </div>
                <div className="form-group mt-3">
                  <button className="btn form-control">Send Feedback</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- feedback modals ends here --> */}
      <header id="header">
        <div className="top-header-wrapper">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-2">
                <div className="logo-wrapper">
                  <Link to="/">
                    <img src={logo} className="img-fluid" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="search-wrapper">
                  <select
                    name="categorys"
                    id=""
                    onChange={(e) => {
                      setCategory_id(e.target.value);
                    }}
                  >
                    <option defaultValue="ALL CATEGORIES" selected disabled>
                      ALL CATEGORIES
                    </option>
                    {CategoriesData?.map((item, index) => {
                      return (
                        <option value={item?.id} key={index}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="search-bar-wrapper">
                    <a
                      className="searchicon"
                      onClick={(e) => SearchFilterHandler(e)}
                    >
                      <i class="fa-solid fa-magnifying-glass "></i>
                    </a>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="I’m searching for..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      // onKeyPress={(e)=>SearchFilter(e)}
                      // onKeyDown={(e) => SearchFilterHandler(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="header-menu-icons-wrapper">
                  <ul>
                    <li className="phone-no">
                      <h6>{SiteSettingsRedux?.contactPhone}</h6>
                      <span>Support 24/7</span>
                    </li>
                    <li className="icon-list">
                      <Link
                        to={
                          Token && userReduxData?.user_type == 1
                            ? "/my-profile"
                            : Token && userReduxData?.user_type == 2
                            ? "/seller-dashboard"
                            : !Token
                            ? "/sign-in"
                            : null
                        }
                      >
                        {/* <i className="fa fa-user" aria-hidden="true"></i> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                        >
                          <g id="person-dash" transform="translate(0 -4.5)">
                            <path
                              id="Path_20"
                              data-name="Path 20"
                              d="M24.75,31.5A2.2,2.2,0,0,0,27,29.25c0-2.25-2.25-9-13.5-9S0,27,0,29.25A2.2,2.2,0,0,0,2.25,31.5ZM2.261,29.376v0ZM2.3,29.25H24.7l.032-.005.018,0A6.007,6.007,0,0,0,22.879,25.5c-1.468-1.466-4.229-3-9.379-3s-7.911,1.53-9.378,3A6.025,6.025,0,0,0,2.25,29.241l.049.009Zm22.442.126v0ZM13.5,15.75A4.5,4.5,0,1,0,9,11.25,4.5,4.5,0,0,0,13.5,15.75Zm6.75-4.5A6.75,6.75,0,1,1,13.5,4.5,6.75,6.75,0,0,1,20.25,11.25Z"
                              fillRule="evenodd"
                            />
                          </g>
                        </svg>
                      </Link>
                    </li>
                    <li className="icon-list">
                      <Link to={Token ? "/my-wishlist" : "/sign-in"}>
                        {/* <i className="fa fa-heart-o"></i> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26.328"
                          height="23.258"
                          viewBox="0 0 26.328 23.258"
                        >
                          <path
                            id="Icon_feather-heart"
                            data-name="Icon feather-heart"
                            d="M24.5,6.35a6.322,6.322,0,0,0-8.943,0L14.337,7.569,13.118,6.35a6.324,6.324,0,1,0-8.943,8.943l1.218,1.218,8.943,8.943,8.943-8.943L24.5,15.293a6.322,6.322,0,0,0,0-8.943Z"
                            transform="translate(-1.173 -3.347)"
                            fill="none"
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.3"
                          />
                        </svg>
                        {/* <span className="count">{fvtData?.length}</span> */}
                      </Link>
                    </li>
                    <li className="icon-list">
                      <Link to="/cart">
                        <div className="shopping-wrapper">
                          {/* <i className="fa fa-shopping-cart"></i> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26.53"
                            height="25.429"
                            viewBox="0 0 26.53 25.429"
                          >
                            <g
                              id="Icon_feather-shopping-cart"
                              data-name="Icon feather-shopping-cart"
                              transform="translate(-0.35 -0.35)"
                            >
                              <path
                                id="Path_17"
                                data-name="Path 17"
                                d="M14.2,31.1A1.1,1.1,0,1,1,13.1,30,1.1,1.1,0,0,1,14.2,31.1Z"
                                transform="translate(-2.79 -7.574)"
                                fill="none"
                                stroke="#000"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2.3"
                              />
                              <path
                                id="Path_18"
                                data-name="Path 18"
                                d="M30.7,31.1A1.1,1.1,0,1,1,29.6,30,1.1,1.1,0,0,1,30.7,31.1Z"
                                transform="translate(-7.175 -7.574)"
                                fill="none"
                                stroke="#000"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2.3"
                              />
                              <path
                                id="Path_19"
                                data-name="Path 19"
                                d="M1.5,1.5H5.906L8.857,16.248a2.2,2.2,0,0,0,2.2,1.773H21.765a2.2,2.2,0,0,0,2.2-1.773L25.73,7.007H7.007"
                                fill="none"
                                stroke="#000"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2.3"
                              />
                            </g>
                          </svg>

                          <span className="count">{cartDataRedux?.length}</span>
                          {/* <div className="cart-content-wrapper">
														<h6>Your Cart</h6>
														<span>$650.59</span>
													</div> */}
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-header-wrapper">
          <div className="container-fluid">
            <div className="row">
              {Header_Cate ? (
                <div className="col-lg-2">
                  <div className="category-bar-wrapper">
                    <button onClick={showcard} className="category-btn">
                      Shop By Category
                    </button>
                    {category == true ? (
                      <button className="close" onClick={hidecard}>
                        <i className="fa fa-close"></i>
                      </button>
                    ) : null}
                  </div>

                  {category == true ? (
                    <div className="category-list-wrapper">
                      <div className="accordion" id="accordionExample">
                        {CategoriesData?.map((item) => {
                          return (
                            <div className="accordion-item" key={item?.id}>
                              <h2
                                className="accordion-header"
                                id={`heading${item?.id}`}
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${item?.id}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse${item?.id}`}
                                >
                                  {item?.name}
                                </button>
                              </h2>
                              <div
                                id={`collapse${item?.id}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`heading${item?.id}`}
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <ul className="list-wrapper">
                                    {item?.sub_category?.map((inner) => {
                                      return (
                                        <li key={inner?.id}>
                                          <a
                                            onClick={(e) =>
                                              SearchByCateHandler(e, inner?.id)
                                            }
                                          >
                                            {inner?.name}
                                          </a>{" "}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="col-lg-2"></div>
              )}

              <div className="col-lg-6"></div>
              <div className="col-lg-4">
                <div className="menu-items-wrapper">
                  <ul>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/shop" className="nav-link">
                        Shop
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about" className="nav-link">
                        About
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact" className="nav-link">
                        Contact
                      </Link>
                    </li>
                    <li className="nav-item">
                      {Token ? (
                        <a
                          className="nav-link"
                          onClick={(e) => LogoutHandler(e)}
                          style={{ cursor: "pointer" }}
                        >
                          {logout_Load ? "Loading" : "Log out"}
                        </a>
                      ) : (
                        <Link to="/sign-in" className="nav-link">
                          Sign in
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
