import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/Share.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const shareUrl = "code";

const Share = () => {
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const [textCopy, setTextCopy] = useState();
  const [copied, setCopied] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  return (
    <>
      <Header />
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* Share starts here */}
              <div className="share-code-wrapper">
                <div className="share-heading-wrapper">
                  <div className="headings-wrapper">
                    <h2>
                      Share Code With Your Family <br /> Or Friend
                    </h2>
                    <span>
                      <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="copy-code-wrapper">
                    <form>
                      <div className="copy-field-wrapper">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="lorem olor sit amet, consectetur"
                          value={userReduxData?.share_referral_code}
                          onChange={() => {
                            setTextCopy(userReduxData?.share_referral_code);
                            setCopied(false);
                          }}
                        />
                        <CopyToClipboard
                          text={userReduxData?.share_referral_code}
                          onCopy={() => setCopied(true)}
                        >
                          <button
                            className="btn"
                            onClick={(e) => e.preventDefault()}
                          >
                            Copy
                          </button>
                        </CopyToClipboard>
                      </div>
                      {copied ? <p style={{ color: "red" }}>Copied</p> : null}
                    </form>
                  </div>
                  {/* <div className="refrelas-wrappe">
                    <div className="ref-head-wrapper">
                      <h4>Your Referrals</h4>
                    </div>
                    <div className="ref-stats-wrapper">
                      <ul>
                        <li>
                          <div className="stats">
                            <h3>45k</h3>
                            <p>Registered</p>
                          </div>
                        </li>
                        <li>
                          <div className="stats">
                            <h3>45k</h3>
                            <p>Successfull</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
                <div className="share-via-wrapper">
                  <div className="share-icons-wrapper">
                    <div className="heading-wrapper">
                      <h4>Share Via</h4>
                    </div>
                    <div className="social-icons-wrapper">
                      <ul>
                        <li>
                          <WhatsappShareButton
                            url={userReduxData?.share_referral_code}
                          >
                            <a>
                              <i className="fa-brands fa-whatsapp"></i>
                            </a>
                          </WhatsappShareButton>
                        </li>
                        <li>
                          <TwitterShareButton
                            url={userReduxData?.share_referral_code}
                          >
                            <a>
                              <i className="fa-brands fa-twitter"></i>
                            </a>
                          </TwitterShareButton>
                        </li>
                        <li>
                          <FacebookShareButton
                            url={userReduxData?.share_referral_code}
                          >
                            <a>
                              <i className="fa-brands fa-instagram"></i>
                            </a>
                          </FacebookShareButton>
                        </li>
                        <li>
                          <FacebookShareButton
                            url={userReduxData?.share_referral_code}
                          >
                            <a>
                              <i className="fa-brands fa-facebook-f"></i>
                            </a>
                          </FacebookShareButton>
                        </li>
                        <li>
                          <FacebookMessengerShareButton
                            url={userReduxData?.share_referral_code}
                          >
                            <a>
                              <i className="fa-brands fa-facebook-messenger"></i>
                            </a>
                          </FacebookMessengerShareButton>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* My adresses ends here */}
      <Footer />
    </>
  );
};

export default Share;
