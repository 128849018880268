import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/product_page.css";
import { seller_img } from "../../constants";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GetProductsDetails, PostAddWishList } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import PostReview from "../../components/Review/PostReview";
import ProductReviews from "../../components/Review/ProductReviews";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SimilarProducts from "../../components/ProductbyFilter/SimilarProducts";
import { AddToCart } from "../../redux/actions/CartActions";
import swal from "sweetalert";
import { Rating } from "react-simple-star-rating";
import { ImageUrl } from "../../network/ApiUrl";
import { ramdomImage } from "../../constants/ConstantFunction";

const Product_page = () => {
  const dispatch = useDispatch();
  let currentUrl = window.location.href.split("/");
  const Token = useSelector((state) => state.AuthReducer.token);
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const [productDetails, setProductDetails] = useState(null);
  const [spinLoad, setSpinLoad] = useState(false);
  const [submitReview, setSubmitReview] = useState(false);
  const [wishLoading, setWishLoading] = useState(false);

  const [amount, setAmount] = useState("");
  const [selectedVariation, setSelectedVariation] = useState("");
  const [selectedVariation_id, setSelectedVariation_id] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState();

  const [Count, SetCount] = useState(0);
  if (Count < 0) {
    SetCount(0);
  }
  const settings_nav = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    focusOnSelect: true,
  };
  const settings_for = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // product Details
  useEffect(() => {
    setSpinLoad(true);
    GetProductsDetails(currentUrl[4])
      .then((res) => {
        setProductDetails(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  const AddWishListHandler = (e) => {
    e.preventDefault();
    setWishLoading(true);
    let data = {
      product_id: currentUrl[4],
    };
    PostAddWishList(data, Token)
      .then((res) => {
        setWishLoading(false);
        // setActive(!active);
        toast.success("Product Add to your Wishlist");
        // dispatch(WishListData(res?.data?.data?.wishlist))
      })
      .catch((err) => {
        setWishLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const AddToCartHandler = (item) => {
    if (!selectedVariation) {
      toast.error("Please Select variant");
      return;
    }
    if (productDetails?.variation) {
      let newVarient = productDetails?.variation?.filter(
        (val) => val?.type == selectedVariation
      );
      if (newVarient[0]?.qty > 0) {
        let checkItemAlreadyExist = cartDataRedux.filter(
          (val) => val?.id === item?.id
        );
        // if (checkItemAlreadyExist.length > 0) {
        // 	swal({
        // 		title: "Item Already Exist in Cart!!!",
        // 	});
        // }

        // else {
        let data = {
          id: item?.id,
          price: amount ? amount : item?.latest_price,
          qty: quantity,
          // variation: selectedVariation,
          variant_name: newVarient[0]?.type,
          productitem: item,
          variant_id: selectedVariation_id,
        };
        dispatch(AddToCart(data));
        toast("Product successfully added");
        // }
      } else {
        toast("Selected Variation is out of stock");
        return;
      }
    }
  };

  const SelectPacks = (e, item) => {
    if (amount == item?.latest_price) {
      setAmount("");
      setQuantity(1);
    } else {
      setAmount(e.target.value);
      setQuantity(parseInt(item?.type, 10));
    }
  };

  const filterVariationQty = () => {
    let varient = selectedVariation;
    if (productDetails?.variation) {
      let newVarient = productDetails?.variation?.filter(
        (val) => val?.type == varient
      );
      if (newVarient.length > 0) {
        return newVarient[0].qty;
      }
      return;
    }
    return;
  };

  const VariationPrice = () => {
    let varient = selectedVariation;
    if (productDetails?.variation) {
      let data = productDetails?.variation?.filter(
        (val) => val?.type === varient
      );
      if (data?.length > 0) {
        return data[0]?.price;
      }
      return;
    }
    return;
  };

  const Increment = (id) => {
    setQuantity(quantity + 1);
  };
  const Decrement = (id) => {
    setQuantity(quantity - 1);
  };

  if (productDetails == null) {
    return (
      <div className="container">
        <div className="row loader-container">
          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
            return (
              <div className="col-md-12 mt-3" key={index}>
                <Skeleton count={5} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />

      <section className="product-detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-slider-wrapper">
                <div className="single-slider">
                  {productDetails?.multimedia?.map((item, index) => {
                    return (
                      <img
                        src={item?.image_url}
                        className="img-fluid"
                        alt=""
                        onClick={() => setSelectedImage(item?.image_url)}
                      />
                    );
                  })}
                </div>
                <div className="main-slider-wrapper">
                  <Zoom>
                    <img
                      src={
                        selectedImage
                          ? selectedImage
                          : productDetails?.image_url
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </Zoom>

                  <div className="zoom-wrapper">
                    <h6>
                      <i className="fa fa-search-plus"></i> Mouse over to zoom
                      in
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-8">
                  <div className="product-detail-content-wrapper">
                    {/* <div className="product-breadcrumbs-wrapper">
											<ul>
												<li>
													<Link to="/">Home</Link>
												</li>
												<li>
													<Link href="/shop">Shop</Link>
												</li>
												<li>
													<a className="active">{productDetails?.title}</a>
												</li>
											</ul>
										</div> */}
                    <div className="detail-wrapper">
                      <h3>{productDetails?.title}</h3>
                      <h4>{productDetails?.short_description}</h4>
                      <div className="reviews-wrapper mb-3 mt-3">
                        <Rating
                          size={20}
                          readonly={true}
                          allowFraction={true}
                          initialValue={productDetails?.average_rating}
                          // ratingValue={}
                        />
                      </div>
                      {productDetails?.seller_info ? (
                        <div className="best-seller-wrapper">
                          <h4>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.334"
                                height="19.203"
                                viewBox="0 0 15.334 19.203"
                              >
                                <g
                                  id="badge-svgrepo-com_1_"
                                  data-name="badge-svgrepo-com (1)"
                                  transform="translate(-49.24 0)"
                                >
                                  <g
                                    id="Group_8003"
                                    data-name="Group 8003"
                                    transform="translate(49.24 0)"
                                  >
                                    <path
                                      id="Path_16690"
                                      data-name="Path 16690"
                                      d="M64.378,8.692l-.236-.322a1.014,1.014,0,0,1-.008-1.19l.232-.326a1.023,1.023,0,0,0-.35-1.489l-.354-.189a1.018,1.018,0,0,1-.523-1.069l.067-.4a1.017,1.017,0,0,0-.963-1.187l-.4-.016a1.021,1.021,0,0,1-.935-.735l-.11-.385A1.018,1.018,0,0,0,59.416.736l-.369.157A1.022,1.022,0,0,1,57.883.638l-.267-.3a1.019,1.019,0,0,0-1.528.012l-.263.3a1.025,1.025,0,0,1-1.159.275L54.3.775a1.018,1.018,0,0,0-1.371.672l-.106.385a1.024,1.024,0,0,1-.923.75l-.4.024a1.018,1.018,0,0,0-.943,1.2l.071.393a1.022,1.022,0,0,1-.507,1.076l-.35.2a1.019,1.019,0,0,0-.33,1.493l.236.322a1.014,1.014,0,0,1,.008,1.19l-.232.326a1.023,1.023,0,0,0,.35,1.489l.354.189a1.018,1.018,0,0,1,.523,1.069l-.067.4a1.017,1.017,0,0,0,.963,1.187l.4.016a1.021,1.021,0,0,1,.935.735l.11.385a1.018,1.018,0,0,0,1.383.652l.369-.157a1.022,1.022,0,0,1,1.163.255l.267.3a1.019,1.019,0,0,0,1.528-.012l.263-.3a1.025,1.025,0,0,1,1.159-.275l.369.153a1.018,1.018,0,0,0,1.371-.672l.106-.385a1.024,1.024,0,0,1,.923-.75l.4-.024a1.018,1.018,0,0,0,.943-1.2l-.071-.393a1.022,1.022,0,0,1,.507-1.076l.35-.2A1.018,1.018,0,0,0,64.378,8.692ZM56.9,12.935a5.107,5.107,0,1,1,5.107-5.107A5.112,5.112,0,0,1,56.9,12.935Z"
                                      transform="translate(-49.24 0)"
                                    />
                                    <path
                                      id="Path_16691"
                                      data-name="Path 16691"
                                      d="M293.383,361.126l-.177.012-.086.314a2.148,2.148,0,0,1-2.888,1.418l-.3-.126-.094.11,1.458,2.652a.594.594,0,0,0,.409.295l1.756.326a.591.591,0,0,0,.656-.361l.664-1.658a.58.58,0,0,0-.031-.5Z"
                                      transform="translate(-280.386 -346.938)"
                                    />
                                    <path
                                      id="Path_16692"
                                      data-name="Path 16692"
                                      d="M76.558,364.819a2.107,2.107,0,0,1-.849.177,2.156,2.156,0,0,1-2.063-1.548l-.09-.31-.314-.012-1.32,2.4a.58.58,0,0,0-.031.5l.664,1.658a.591.591,0,0,0,.656.361l1.756-.326a.6.6,0,0,0,.409-.295l1.5-2.723-.012-.012Z"
                                      transform="translate(-70.959 -348.86)"
                                    />
                                    <path
                                      id="Path_16693"
                                      data-name="Path 16693"
                                      d="M162.831,103.637a.644.644,0,0,0-.086-.809c-.475-.436-1.768-.145-1.768-.145h0c-.09.016-.185.035-.287.059,0,0-.448.208,0-1.147s-.3-1.568-.613-1.568-.208.672-.208.672a7.28,7.28,0,0,1-1.312,2.342,1.45,1.45,0,0,0-.31.523.575.575,0,0,0-.373-.138h-.86a.574.574,0,0,0-.574.574v2.294a.574.574,0,0,0,.574.574h.86a.555.555,0,0,0,.318-.1.6.6,0,0,0,.134-.452V104.89c0,.055.008,1.768.994,1.874.354.024.79.043,1.226.035a4.293,4.293,0,0,0,1.155-.035c1.139-.244.856-1.037.856-1.037a.781.781,0,0,0,.267-1.041A.7.7,0,0,0,162.831,103.637Z"
                                      transform="translate(-152.227 -96.096)"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                            {productDetails?.seller_info?.badge?.name} |{" "}
                            {productDetails?.seller_info?.user?.name}{" "}
                            {productDetails?.seller_info?.user?.last_name}
                          </h4>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  {productDetails?.seller_info ? (
                    <Link
                      to={`/seller-store/${productDetails?.seller_info?.user?.id}`}
                    >
                      <div className="seller-card-wrapper">
                        <div className="img-wrapper">
                          <figure>
                            {productDetails?.seller_info?.image &&
                            productDetails?.seller_info?.image != null ? (
                              <img
                                src={`${ImageUrl}/${"uploads"}/${"seller"}/${
                                  productDetails?.seller_info?.user?.image
                                }`}
                                className="image-fluid"
                                alt=""
                              />
                            ) : (
                              <img
                                src={`${ramdomImage(
                                  `${productDetails?.seller_info?.user?.name}{""}${productDetails?.seller_info?.user?.last_name}`
                                )}`}
                                className="image-fluid "
                                alt=""
                              />
                            )}
                          </figure>
                        </div>
                        <div className="seller-content-wrapper">
                          <h4>
                            {" "}
                            {productDetails?.seller_info?.user?.name}{" "}
                            {productDetails?.seller_info?.user?.last_name}
                          </h4>
                          <h6>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.334"
                                height="19.203"
                                viewBox="0 0 15.334 19.203"
                              >
                                <g
                                  id="badge-svgrepo-com_1_"
                                  data-name="badge-svgrepo-com (1)"
                                  transform="translate(-49.24 0)"
                                >
                                  <g
                                    id="Group_8003"
                                    data-name="Group 8003"
                                    transform="translate(49.24 0)"
                                  >
                                    <path
                                      id="Path_16690"
                                      data-name="Path 16690"
                                      d="M64.378,8.692l-.236-.322a1.014,1.014,0,0,1-.008-1.19l.232-.326a1.023,1.023,0,0,0-.35-1.489l-.354-.189a1.018,1.018,0,0,1-.523-1.069l.067-.4a1.017,1.017,0,0,0-.963-1.187l-.4-.016a1.021,1.021,0,0,1-.935-.735l-.11-.385A1.018,1.018,0,0,0,59.416.736l-.369.157A1.022,1.022,0,0,1,57.883.638l-.267-.3a1.019,1.019,0,0,0-1.528.012l-.263.3a1.025,1.025,0,0,1-1.159.275L54.3.775a1.018,1.018,0,0,0-1.371.672l-.106.385a1.024,1.024,0,0,1-.923.75l-.4.024a1.018,1.018,0,0,0-.943,1.2l.071.393a1.022,1.022,0,0,1-.507,1.076l-.35.2a1.019,1.019,0,0,0-.33,1.493l.236.322a1.014,1.014,0,0,1,.008,1.19l-.232.326a1.023,1.023,0,0,0,.35,1.489l.354.189a1.018,1.018,0,0,1,.523,1.069l-.067.4a1.017,1.017,0,0,0,.963,1.187l.4.016a1.021,1.021,0,0,1,.935.735l.11.385a1.018,1.018,0,0,0,1.383.652l.369-.157a1.022,1.022,0,0,1,1.163.255l.267.3a1.019,1.019,0,0,0,1.528-.012l.263-.3a1.025,1.025,0,0,1,1.159-.275l.369.153a1.018,1.018,0,0,0,1.371-.672l.106-.385a1.024,1.024,0,0,1,.923-.75l.4-.024a1.018,1.018,0,0,0,.943-1.2l-.071-.393a1.022,1.022,0,0,1,.507-1.076l.35-.2A1.018,1.018,0,0,0,64.378,8.692ZM56.9,12.935a5.107,5.107,0,1,1,5.107-5.107A5.112,5.112,0,0,1,56.9,12.935Z"
                                      transform="translate(-49.24 0)"
                                      fill="#ed9521"
                                    />
                                    <path
                                      id="Path_16691"
                                      data-name="Path 16691"
                                      d="M293.383,361.126l-.177.012-.086.314a2.148,2.148,0,0,1-2.888,1.418l-.3-.126-.094.11,1.458,2.652a.594.594,0,0,0,.409.295l1.756.326a.591.591,0,0,0,.656-.361l.664-1.658a.58.58,0,0,0-.031-.5Z"
                                      transform="translate(-280.386 -346.938)"
                                      fill="#ed9521"
                                    />
                                    <path
                                      id="Path_16692"
                                      data-name="Path 16692"
                                      d="M76.558,364.819a2.107,2.107,0,0,1-.849.177,2.156,2.156,0,0,1-2.063-1.548l-.09-.31-.314-.012-1.32,2.4a.58.58,0,0,0-.031.5l.664,1.658a.591.591,0,0,0,.656.361l1.756-.326a.6.6,0,0,0,.409-.295l1.5-2.723-.012-.012Z"
                                      transform="translate(-70.959 -348.86)"
                                      fill="#ed9521"
                                    />
                                    <path
                                      id="Path_16693"
                                      data-name="Path 16693"
                                      d="M162.831,103.637a.644.644,0,0,0-.086-.809c-.475-.436-1.768-.145-1.768-.145h0c-.09.016-.185.035-.287.059,0,0-.448.208,0-1.147s-.3-1.568-.613-1.568-.208.672-.208.672a7.28,7.28,0,0,1-1.312,2.342,1.45,1.45,0,0,0-.31.523.575.575,0,0,0-.373-.138h-.86a.574.574,0,0,0-.574.574v2.294a.574.574,0,0,0,.574.574h.86a.555.555,0,0,0,.318-.1.6.6,0,0,0,.134-.452V104.89c0,.055.008,1.768.994,1.874.354.024.79.043,1.226.035a4.293,4.293,0,0,0,1.155-.035c1.139-.244.856-1.037.856-1.037a.781.781,0,0,0,.267-1.041A.7.7,0,0,0,162.831,103.637Z"
                                      transform="translate(-152.227 -96.096)"
                                      fill="#ed9521"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span>{productDetails?.seller?.badge?.name}</span>
                          </h6>
                        </div>
                      </div>
                    </Link>
                  ) : null}
                  {/* <div className="become-seller">
                <h6>
                  Become a Seller? <a href="#">Register now</a>{" "}
                </h6>
              </div> */}
                </div>
                <div className="col-lg-12">
                  <div className="product-detail-content-wrapper">
                    <div className="price-wrapper">
                      <h3>
                        $
                        {selectedVariation && amount
                          ? amount
                          : selectedVariation
                          ? VariationPrice()
                          : productDetails?.latest_price}{" "}
                      </h3>
                    </div>
                    {/* variations starts  here */}

                    <div className="variation-main">
                      {(productDetails?.variation?.length > 0) &
                      (productDetails?.variation !== undefined)
                        ? productDetails?.variation?.map((item, index) => {
                            return (
                              <>
                                <div className="sizes-wrapper">
                                  <div className="sizes-heading-wrapper">
                                    {/* <h6 key={index}>Variations</h6> */}
                                  </div>
                                  <div className="size-content-wrapper">
                                    <input
                                      key={index}
                                      type="radio"
                                      name="radio"
                                      id={index}
                                      value={item?.type}
                                      onClick={(e) => {
                                        setSelectedVariation(e.target.value);
                                        filterVariationQty();
                                        setQuantity(1);
                                        VariationPrice();
                                        setAmount(item?.price);
                                        setSelectedVariation_id(item?.id);
                                      }}
                                    />
                                    <label for={index}>{item?.type}</label>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : null}
                    </div>

                    <div className="mt-2">
                      {selectedVariation ? (
                        <p>
                          {" "}
                          {selectedVariation} : only {filterVariationQty()}{" "}
                          {filterVariationQty() > 1 ? "items" : "item"} left
                        </p>
                      ) : null}
                    </div>
                    {/* variations ends  here */}
                    <div className="quantity-card-wrapper">
                      <div className="quantity-wrapper">
                        <h4>Quantity:</h4>
                        <div className="quantity-input-wrapper">
                          <button
                            onClick={() => Decrement(productDetails?.id)}
                            disabled={quantity <= 1}
                            className="back"
                          >
                            <i className="fa fa-angle-left"></i>
                          </button>
                          <input type="number" value={quantity} />
                          <button
                            onClick={() => Increment(productDetails?.id)}
                            disabled={filterVariationQty() == quantity}
                            className="back"
                          >
                            <i className="fa fa-angle-right"></i>
                          </button>
                        </div>
                      </div>
                      <div className="btn-wrapper">
                        <a
                          className="btn"
                          onClick={(e) => AddToCartHandler(productDetails)}
                        >
                          Add To Cart
                        </a>
                      </div>
                    </div>
                    <div className="wishlist-wrapper">
                      <a
                        onClick={(e) => AddWishListHandler(e)}
                        disabled={wishLoading}
                        style={{ cursor: "pointer" }}
                      >
                        <h5>
                          <i className="fa fa-heart"></i>{" "}
                          <span>
                            {" "}
                            {wishLoading ? "Loading..." : "Add To Wishlist"}
                          </span>
                        </h5>
                      </a>
                    </div>
                    <div className="other-deatils-wrapper">
                      <ul>
                        {selectedVariation ? (
                          <li>
                            <span className="property">SKU:</span>
                            <span className="value">{selectedVariation}</span>
                          </li>
                        ) : null}

                        <li>
                          <span className="property">Category:</span>
                          <span className="value">
                            {productDetails?.category?.name}
                          </span>
                        </li>
                        {/* <li>
                        <span className="property">Tags:</span>
                        <span className="value">
                          beef, meat, frozen, fresh, organic, foods
                        </span>
                      </li> */}
                        <li>
                          <span className="property">Seller Name :</span>
                          <span className="value">
                            {productDetails?.seller_info?.user?.name}{" "}
                            {productDetails?.seller_info?.user?.last_name}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* product detail ends here */}
      {/* products tabs starts here */}
      <section className="products-tab-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="details-tab-wrapper">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="Description-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Description"
                      type="button"
                      role="tab"
                      aria-controls="Description"
                      aria-selected="true"
                    >
                      Description
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Reviews-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Reviews"
                      type="button"
                      role="tab"
                      aria-controls="Reviews"
                      aria-selected="false"
                    >
                      Reviews{" "}
                      <span>{`(${productDetails?.total_reviews})`}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="Description"
                  role="tabpanel"
                  aria-labelledby="Description-tab"
                >
                  <div className="description-wrapper">
                    <p>{productDetails?.description}</p>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Reviews"
                  role="tabpanel"
                  aria-labelledby="Reviews-tab"
                >
                  <div className="reviews-wrapper">
                    <div className="heading-wrapper">
                      <h5>Our Clients Reviews</h5>
                      {/* <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et
                        dolore.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore.Lorem ipsum dolor sit amet,Lorem ipsum
                        dolor sit amet
                      </p> */}
                    </div>

                    <ProductReviews
                      submitReview={submitReview}
                      setSubmitReview={setSubmitReview}
                    />
                  </div>
                  <div className="add-review-wrapper">
                    <div className="heading-wrapper">
                      <h3>Add Review</h3>
                    </div>
                    <PostReview
                      submitReview={submitReview}
                      setSubmitReview={setSubmitReview}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* products tabs ends here */}
      {/* products starts here */}
      <section className="product-sec pro-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {productDetails?.similar_products?.length > 0
                  ? productDetails?.similar_products?.map((item, index) => {
                      return (
                        <>
                          <div className="col-lg-3 px-0" key={index}>
                            <SimilarProducts
                              similarProductsData={{
                                id: item.id,
                                image: item?.image_url,
                                brandName: item?.title,
                                title: item?.title,
                                averageRating: item?.average_rating,
                                variation: item?.variation,
                                oldPrice: item?.old_price,
                                totalPrice: item?.latest_price,
                              }}
                            />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* products ends here */}
      {/* Footer starts here */}
      <Footer />
      {/* Footer ends here */}
    </>
  );
};

export default Product_page;
