import React from "react";
import { Link, useLocation } from "react-router-dom";

const Seller_tabs_list = () => {
  const location = useLocation();
  return (
    <>
      {/* Tab navlink starts here */}
      <div className="seller-tabs-list-wrapper">
        <ul>
          <li>
            <Link
              className={
                location?.pathname == "/seller-dashboard"
                  ? "nav-item active"
                  : "nav-item"
              }
              to="/seller-dashboard"
            >
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname == "/seller-customer"
                  ? "nav-item active"
                  : "nav-item"
              }
              to="/seller-customer"
            >
              Customer
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname == "/seller-Inventory"
                  ? "nav-item active"
                  : "nav-item"
              }
              to="/seller-Inventory"
            >
              Product Inventory
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname == "/seller-order"
                  ? "nav-item active"
                  : "nav-item"
              }
              to="/seller-order"
            >
              Orders
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname == "/seller-account"
                  ? "nav-item active"
                  : "nav-item"
              }
              to="/seller-account"
            >
              My Account
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname == "/seller-banner-ads"
                  ? "nav-item active"
                  : "nav-item"
              }
              to="/seller-banner-ads"
            >
              Banner Ads
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname == "/seller-reviews"
                  ? "nav-item active"
                  : "nav-item"
              }
              to="/seller-reviews"
            >
              Reviews
            </Link>
          </li>
        </ul>
      </div>
      {/* Tab navlink ends here */}
    </>
  );
};

export default Seller_tabs_list;
