import React from "react";
import "../../assets/css/Seller_Inventory.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { detail_order_img, product_inventory } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";

const Seller_Refund_Requests = () => {
	function refundreq() {
		window.location.href = "/seller-refund-request";
	}
	return (
		<>
			{/* Header starts here */}
			<SellerHeader />
			{/* Header ends here */}
			<section className="dashboard">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<Seller_sidebar />
						</div>
						<div className="col-lg-8 refunr-ore">
							<Seller_tabs_list />

							<div className="sellers_orders">
								<div className="heading_wrapper d-flex justify-content-between">
									<h4>Refund Requests</h4>
									<div className="add-upload-wrapper">
										<button className="add-products" onclick={refundreq}>
											Refund Requests
										</button>
									</div>
								</div>

								<div className="table-resposnive mt-4">
									<table className="table">
										<thead>
											<tr className="topbar">
												<th scope="col" className="Status">
													Order ID
												</th>
												<th scope="col" className="billingname">
													Billing Name
												</th>
												<th scope="col" className="amount">
													Amount
												</th>
												<th scope="col" className="Price">
													Item
												</th>
												<th scope="col" className="Stock">
													Shipment Status
												</th>
												<th scope="col" className="Details">
													Details
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="orderid">#12345</td>
												<td className="billingname">Lorem Ipsum</td>
												<td className="amount">$550</td>
												<td className="details">2 </td>
												<td className="Available">
													{" "}
													<span className="Rejected">Rejected</span>{" "}
												</td>

												<td className="Detailsu">
													<button className="btn details-btn">
														View Details
													</button>{" "}
												</td>
											</tr>
											<tr>
												<td className="orderid">#12345</td>
												<td className="billingname">Lorem Ipsum</td>
												<td className="amount">$550</td>
												<td className="details">2 </td>
												<td className="Available">
													{" "}
													<span className="Rejected">Rejected</span>{" "}
												</td>

												<td className="Detailsu">
													<button className="btn details-btn">
														View Details
													</button>{" "}
												</td>
											</tr>
											<tr>
												<td className="orderid">#12345</td>
												<td className="billingname">Lorem Ipsum</td>
												<td className="amount">$550</td>
												<td className="details">2 </td>
												<td className="Available">
													{" "}
													<span className="Rejected">Rejected</span>{" "}
												</td>

												<td className="Detailsu">
													<button className="btn details-btn">
														View Details
													</button>{" "}
												</td>
											</tr>
											<tr>
												<td className="orderid">#12345</td>
												<td className="billingname">Lorem Ipsum</td>
												<td className="amount">$550</td>
												<td className="details">2 </td>
												<td className="Available">
													{" "}
													<span className="Rejected">Rejected</span>{" "}
												</td>

												<td className="Detailsu">
													<button className="btn details-btn">
														View Details
													</button>{" "}
												</td>
											</tr>
											<tr>
												<td className="orderid">#12345</td>
												<td className="billingname">Lorem Ipsum</td>
												<td className="amount">$550</td>
												<td className="details">2 </td>
												<td className="Available">
													{" "}
													<span className="Rejected">Rejected</span>{" "}
												</td>

												<td className="Detailsu">
													<button className="btn details-btn">
														View Details
													</button>{" "}
												</td>
											</tr>
											<tr>
												<td className="orderid">#12345</td>
												<td className="billingname">Lorem Ipsum</td>
												<td className="amount">$550</td>
												<td className="details">2 </td>
												<td className="Available">
													{" "}
													<span className="Rejected">Rejected</span>{" "}
												</td>

												<td className="Detailsu">
													<button className="btn details-btn">
														View Details
													</button>{" "}
												</td>
											</tr>
											<tr>
												<td className="orderid">#12345</td>
												<td className="billingname">Lorem Ipsum</td>
												<td className="amount">$550</td>
												<td className="details">2 </td>
												<td className="Available">
													{" "}
													<span className="Rejected">Rejected</span>{" "}
												</td>

												<td className="Detailsu">
													<button className="btn details-btn">
														View Details
													</button>{" "}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="paginations_results">
									<div className="showing_results">
										<p>
											Showing <b>7</b> of over 50 <b>results</b>
										</p>
									</div>
									<ul className="paginations">
										<li className="arrow prev-arrow">
											<i className="fa fa-angle-left" aria-hidden="true"></i>
										</li>
										<li className="pagi">1</li>
										<li className="pagi">2</li>
										<li className="pagi">3</li>
										<li className="pagi">..</li>
										<li className="pagi">90</li>
										<li className="arrow right-arrow">
											<i className="fa fa-angle-right" aria-hidden="true"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<SellerFooter />
			{/* Footer ends here */}
		</>
	);
};

export default Seller_Refund_Requests;
