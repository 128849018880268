import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/account.css";
import "../../assets/css/user_profile.css";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useDispatch, useSelector } from "react-redux";
import { EditProfileData, logout } from "../../redux/actions/AuthActions";
import CircleLoad from "../../components/Loader/CircleLoad";
import InputField from "../../components/Form/InputField";
import { toast } from "react-toastify";
import { UserProfileUpdate } from "../../network/Network";

const MyProfile = () => {
  const dispatch = useDispatch();
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [disabled, setDisabled] = useState(true);
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [userData, setUserData] = useState({
    f_name: userReduxData?.name ? userReduxData?.name : "",
    l_name: userReduxData?.last_name ? userReduxData?.last_name : "",
    phone: userReduxData?.phone ? userReduxData?.phone : "",
  });
  const [editLoading, setEditLoading] = useState(false);

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  }

  function handleGameClick(e) {
    e.preventDefault();
    setDisabled(!disabled);
  }

  const EditProfileHandler = (e) => {
    e.preventDefault();
    setEditLoading(true);
    if (!userData?.f_name || !userData?.l_name || !userData?.phone) {
      toast.error("Please Enter All Field");
      setEditLoading(false);
      return;
    }
    if (userData?.phone?.length < 10 || userData?.phone?.length > 17) {
      toast.error("The phone no limit is min 10 and max 17 digits");
      setEditLoading(false);
      return;
    }
    let data = new FormData();
    data.append("first_name", userData?.f_name);
    data.append("last_name", userData?.l_name);
    data.append("phone", userData?.phone);
    data.append("image", newFile);
    UserProfileUpdate(data, Token)
      .then((res) => {
        setEditLoading(false);
        toast.success(res?.data?.message);
        dispatch(EditProfileData(res?.data?.response?.data));
        setDisabled(!disabled);
      })
      .catch((err) => {
        setEditLoading(false);
      });
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  return (
    <>
      {/* <AuthHeader /> */}
      <Header Header_Cate={false} />
      {/* My Profile starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar
                uploadLoading={uploadLoading}
                fileupload={fileupload}
                handleImageUpload={handleImageUpload}
              />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* Account starts here */}
              <div className="account-form-wrapper">
                <div className="account-heading-wrapper">
                  <h3>Account</h3>
                </div>
                <form>
                  <div className="form-group row">
                    <div className="col-lg-5">
                      <label htmlFor="">First Name</label>
                      <InputField
                        type="text"
                        value={userData?.f_name}
                        onChange={handleChange}
                        placeholder="First Name"
                        name="f_name"
                        disabled={disabled}
                      />
                      {/* <input
												type="text"
												defaultValue={userReduxData?.name}
												className="form-control"
												
											/> */}
                    </div>
                    <div className="col-lg-5">
                      <label htmlFor="">last Name</label>
                      <InputField
                        type="text"
                        value={userData?.l_name}
                        onChange={handleChange}
                        placeholder="Last Name"
                        name="l_name"
                        disabled={disabled}
                      />
                      {/* <input
												type="text"
												defaultValue={userReduxData?.last_name}
												className="form-control"
												disabled={disabled}
											/> */}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-5">
                      <label htmlFor="">Phone Number</label>
                      <InputField
                        type="number"
                        value={userData?.phone}
                        onChange={handleChange}
                        placeholder="Phone Number"
                        name="phone"
                        disabled={disabled}
                      />
                      {/* <input
												type="text"
												defaultValue={userReduxData?.phone}
												className="form-control"
												disabled={disabled}
											/> */}
                    </div>
                    <div className="col-lg-5">
                      <label htmlFor="">Email</label>
                      <input
                        type="text"
                        defaultValue={userReduxData?.email}
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    {disabled ? (
                      <button className="btn" onClick={handleGameClick}>
                        {disabled ? "Edit Profile" : "Save Changes"}
                      </button>
                    ) : (
                      <button
                        className="btn"
                        onClick={(e) => EditProfileHandler(e)}
                        disabled={editLoading}
                      >
                        {editLoading ? <CircleLoad /> : "Save Changes"}
                      </button>
                    )}
                  </div>
                </form>
              </div>
              {/* Account ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* My Profile starts here */}
      <Footer />
    </>
  );
};

export default MyProfile;
