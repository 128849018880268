import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/Shop.css";
import { useSelector } from "react-redux";
import { GetAllProductsApi, PostAddWishList } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NoRecord from "../../components/NoRecord/NoRecord";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import CircleLoad from "../../components/Loader/CircleLoad";

const Shop = () => {
	const navigate = useNavigate();
	const CategoriesData = useSelector((state) => state.AuthReducer.categories);
	const Token = useSelector((state) => state.AuthReducer.token);
	const isLogin = useSelector((state) => state.AuthReducer.isLogin);
	const userReduxData = useSelector((state) => state.AuthReducer.users);
	const ParamData = useLocation();
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState();
	const [spinLoad, setSpinLoad] = useState(false);
	const [productsData, setProductsData] = useState([]);
	const [search, setSearch] = useState(
		ParamData?.state?.paramSearch ? ParamData?.state?.paramSearch : null,
	);
	const [cate_id, setCate_id] = useState(
		ParamData?.state?.paramCat_id ? ParamData?.state?.paramCat_id : null,
	);
	const [category_id, setCategory_id] = useState(ParamData?.state?.paramCat_id ? ParamData?.state?.paramCat_id : null,);
	const [sub_category_id, setSub_category_id] = useState(ParamData?.state?.paramSubCat_id ? ParamData?.state?.paramSubCat_id : null,);

	const [newArrival, setNewArrival] = useState(
		ParamData?.state?.paramNew_arrival
			? ParamData?.state?.paramNew_arrival
			: null,
	);
	const [hot, seHot] = useState(
		ParamData?.state?.paramHot ? ParamData?.state?.paramHot : null,
	);
	const [wishLoading, setWishLoading] = useState(false);
	const [grid, setGrid] = useState(3);
	const [sortBy, setSortBy] = useState("");

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	const AllProductWithFilter = (e = null, type, subCateId, sort) => {
		if (e) {
			e.preventDefault();
		}
		setSpinLoad(true);
		let data;
		if (type == "all" || type == null || type == undefined) {
			data = {
				category_id: " ",
      			sub_category_id: " ", 
				search: " ",
				hot: hot ? hot : " ",
				feature: " ",
				discounted: " ",
				new_arrival: newArrival ? newArrival : " ",
				vendor_id: " ",
				sort_by: " ",
			};
			setSearch("");
			seHot("");
			setNewArrival("");
			setSortBy("");
		} else {
			data = {
				// cate_id: cateId ? cateId : cate_id ? cate_id : " ",
				category_id: category_id ? category_id : " ",
      			sub_category_id: subCateId ? subCateId : sub_category_id ? sub_category_id : " ", 
				search: search ? search : " ",
				hot: hot ? hot : " ",
				feature: " ",
				discounted: " ",
				new_arrival: newArrival ? newArrival : " ",
				vendor_id: " ",
				sort_by: sort ? sort : " ",
			};
		}
		GetAllProductsApi(currentPage, data)
			.then((res) => {
				setProductsData(res?.data?.response?.data?.data);
				const total = res?.data?.response?.data?.total;
				const limit = res?.data?.response?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				setSpinLoad(false);
				setSub_category_id("")
				setCategory_id("");
				setSearch("");
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	};

	useEffect(() => {
		if (ParamData?.state?.type) {
			AllProductWithFilter(null, ParamData?.state?.type);
		} else {
			AllProductWithFilter(null);
		}
	}, [currentPage]);

	const AddWishListHandler = (e, id) => {
		e.preventDefault();
		setWishLoading(true);
		let data = {
			product_id: id,
		};
		PostAddWishList(data, Token)
			.then((res) => {
				setWishLoading(false);
				// setActive(!active);
				toast.success("Product Add to your Wishlist");
			})
			.catch((err) => {
				setWishLoading(false);
				toast.error(err?.response?.data?.message);
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{/* Header starts here */}
			<Header
				Header_Cate={true}
				// setCate_id={setCate_id}
				// setSearchText={setSearch}
				page={"shop"}
			/>
			{/* Header ends here */}
			{/* Page Header starts here */}
			<section className="pageheader">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							{/* <div className="content-wrapper">
								<h5>Fresh Beer 100%</h5>
								<h2>Poseidon</h2>
								<h6>Moringa</h6>
								<h3>$299</h3>
								<a href="#" className="btn">
									Shop Now
								</a>
							</div> */}
						</div>
					</div>
				</div>
			</section>
			{/* Page Header ends here */}
			{/* products starts here */}
			<section className="product-sec">
				<div className="container">
					<div className="row foods-row">
						<div className="col-lg-3">
							<div className="heading-wrapper">
								{/* <h2>Frozen Seafoods</h2> */}
							</div>
						</div>
						<div className="col-lg-9">
							<div className="sort-wrapper">
								<ul>
									<li>
										<div className="sort-field-wrapper">
											<label htmlFor="Sortby">Sort by:</label>
											<select
												name=""
												className="form-control"
												id=""
												onChange={(e) => {
													setSortBy(e.target.value);
													AllProductWithFilter(e, "cate", " ", e.target.value);
												}}
											>
												<option value="Sort by popularity" disabled selected>
													Sort by popularity
												</option>
												<option value="asc">Asc</option>
												<option value="desc">Desc</option>
											</select>
										</div>
									</li>
									<li>
										<span>view</span>
									</li>
									<li>
										<a onClick={(e) => setGrid(6)}>
											<i className="fa fa-bars" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a onClick={(e) => setGrid(3)}>
											<i className="fa fa-th-large" aria-hidden="true"></i>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3">
							<div className="sidebars-wrapper">
								<div className="heading-wrapper">
									<h4>SUB CATEGORIES</h4>
								</div>
								<div className="categories-sidebar-wrapper">
									<div className="accordion" id="accordionExample">
										<div className="cat-name">
											<ul>
												<li style={{ cursor: "pointer" }}>
													<a onClick={(e) => AllProductWithFilter(null, "all")}>
														<span>All </span>
														{/* <span className="total-cat">167</span> */}
													</a>
												</li>
											</ul>
										</div>
										{CategoriesData?.map((item, index) => {
											return (
												<div className="accordion-item" key={index}>
													<h2
														className="accordion-header"
														id={`heading${item?.id}`}
													>
														<button
															className="accordion-button collapsed"
															type="button"
															data-bs-toggle="collapse"
															data-bs-target={`#collapse${item?.id}`}
															aria-expanded="false"
															aria-controls={`collapse${item?.id}`}
														>
															<span>{item?.name}</span>
														</button>
													</h2>
													<div
														id={`collapse${item?.id}`}
														className="accordion-collapse collapse"
														aria-labelledby={`heading${item?.id}`}
														data-bs-parent="#accordionExample"
													>
														<div className="accordion-body">
															<ul>
																{item?.sub_category?.map((sub, index) => {
																	return (
																		<li key={index}>
																			<a
																				onClick={(e) => {
																					setSub_category_id(sub?.id);
																					AllProductWithFilter(
																						e,
																						"cate",
																						sub?.id,
																					);
																				}}
																				style={{ cursor: "pointer" }}
																			>
																				{sub?.name}
																			</a>
																		</li>
																	);
																})}
															</ul>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-9">
							<div className="row">
								{spinLoad ? (
									<>
										{[0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12]?.map(
											(item, index) => {
												return (
													<div className="col-lg-3 mt-3" key={index}>
														<Skeleton count={5} />
													</div>
												);
											},
										)}
									</>
								) : (
									<>
										{productsData?.length > 0 ? (
											<>
												{productsData?.map((item, index) => {
													return (
														<div
															className={
																grid == 6 ? "col-lg-6 px-0" : "col-lg-3 px-0"
															}
															key={index}
														>
															<div className="product-card-wrapper">
																<div className="product-img-wrapper">
																	<Link to={`${"/product"}/${item?.id}`}>
																		<figure>
																			<img
																				src={item?.image_url}
																				className="img-fluid"
																				alt=""
																			/>
																		</figure>
																	</Link>
																</div>
																<div className="product-content-wrapper">
																	<h5>{item?.title}</h5>
																	<span className="ice">
																		{item?.short_description}
																	</span>
																	<div className="rating-wrapper mb-2">
																		<Rating
																			size={20}
																			readonly={true}
																			allowFraction={true}
																			initialValue={item?.average_rating}
																		/>
																	</div>
																	{/* <h6>5kg</h6> */}
																	<h4>${item?.latest_price}</h4>

																	{/* <div className="counter-total-wrapper">
                                    <input type="number" min={0} />
                                    <h6>
                                      Total: <span>$38.5</span>
                                    </h6>
                                  </div> */}
																	<a
																		onClick={(e) =>
																			AddWishListHandler(e, item?.id)
																		}
																		className="btn"
																		disabled={wishLoading}
																	>
																		{wishLoading ? (
																			<CircleLoad />
																		) : (
																			"Add To Wishlist"
																		)}
																	</a>
																</div>
															</div>
														</div>
													);
												})}
											</>
										) : (
											<NoRecord />
										)}
									</>
								)}
							</div>
							<div className="pagination-wrapper">
								<div className="pagination-container mt-5">
									<ReactPaginate
										previousLabel="<"
										nextLabel=">"
										breakLabel={"..."}
										pageCount={pageCount}
										marginPagesDisplayed={2}
										pageRangeDisplayed={3}
										onPageChange={handlePageClick}
										containerClassName={"pagination justify-content-center"}
										pageClassName={"page-item"}
										pageLinkClassName={"page-link"}
										previousClassName={"page-item"}
										previousLinkClassName={"page-link"}
										nextClassName={"page-item"}
										nextLinkClassName={"page-link"}
										breakClassName={"page-item"}
										breakLinkClassName={"page-link"}
										activeClassName={"active"}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* products ends here */}
			{/* Footer starts here */}
			<Footer />
			{/* Footer ends here */}
		</>
	);
};

export default Shop;
