import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/Product_cart.css";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DecrementProductQuantity,
  deleteCartItem,
  IncrementProductQuantity,
} from "../../redux/actions/CartActions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NoRecord from "../../components/NoRecord/NoRecord";

const Product_cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SiteSettingsRedux = useSelector(
    (state) => state.AuthReducer.siteSettings
  );
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [cartData, setCartData] = useState(cartDataRedux);
  const [subTotal, setSubTotal] = useState(0);

  const Increment = (index) => {
    dispatch(IncrementProductQuantity(index));
  };
  const Decrement = (index) => {
    dispatch(DecrementProductQuantity(index));
  };

  const filterVariationQty = (item) => {
    let varient = item?.variant_name;
    if (item?.productitem?.variation) {
      let newVarient = item?.productitem?.variation?.filter(
        (val) => val?.type == varient
      );
      if (newVarient?.length > 0) {
        return newVarient[0]?.qty || 1;
      }
      return 1;
    }
    return 1;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const TotalAmount = () => {
    let i;
    let total = 0;
    for (i = 0; i < cartDataRedux.length; i++) {
      total = total + cartDataRedux[i]?.price * cartDataRedux[i]?.qty;
    }
    setSubTotal(total);
  };

  useEffect(() => {
    TotalAmount();
  }, [cartDataRedux]);

  const RemoveCartItem = (id, index) => {
    const items = cartData;
    if (items.length > 0) {
      setCartData([...items.slice(0, index), ...items.slice(index + 1)]);
      setSubTotal(0);
      dispatch(deleteCartItem(index));
    }
  };

  const LoginHandler = (e) => {
    e.preventDefault();
    if (!Token) {
      toast.error("Please Login");
      navigate("/sign-in");
      return;
    }
    if (cartDataRedux?.length < 1) {
      toast.error("Please Add Products ");
      return;
    }
  };

  return (
    <>
      {/* Header starts here */}
      <Header />
      {/* Header ends here */}
      {/* wishlist starts here */}
      <section className="wishlist-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="heading-wrapper">
                <h4>View Cart</h4>
              </div>
              <div className="wishlist-wrapper">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="pro-img">Image</th>
                        <th className="pro-name">PRODUCT NAME</th>
                        <th className="PRICE-name">UNIT PRICE</th>
                        <th className="Avalablity-name">QUANTITY</th>
                        <th className="Action-name">SUB TOTAL</th>
                        <th className="close"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartDataRedux?.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td className="product-img">
                                <figure>
                                  <img
                                    src={item?.productitem?.image_url}
                                    alt=""
                                  />
                                </figure>
                              </td>
                              <td className="product-name">
                                <div className="product-wrapper">
                                  <h6>{item?.productitem?.title}</h6>
                                </div>
                              </td>
                              <td className="price-name">
                                <h6>${item?.price} </h6>
                              </td>
                              <td className="stock-name">
                                <div className="counter-wrapper">
                                  <div className="counter-field-wrapper">
                                    <input type="number" value={item?.qty} />
                                  </div>
                                  <div className="counter-btn-wrapper">
                                    <button
                                      onClick={() =>
                                        Increment(index)
                                      }
                                      disabled={
                                        filterVariationQty(item) == item?.qty
                                      }
                                      className="plus"
                                    >
                                      <i className="fa-solid fa-angle-up"></i>
                                    </button>
                                    <button
                                      onClick={() =>
                                        Decrement(index)
                                      }
                                      disabled={item?.qty <= 1}
                                      className="minus"
                                    >
                                      <i className="fa-solid fa-angle-down"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td className="product-name">
                                <h6>${item?.qty * item?.price} </h6>
                              </td>
                              <td className="close-col">
                                <button
                                  onClick={(e) =>
                                    RemoveCartItem(item?.productitem?.id, index)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.5"
                                    height="21.214"
                                    viewBox="0 0 16.5 21.214"
                                  >
                                    <g
                                      id="cancel"
                                      transform="translate(2.25 6.698)"
                                    >
                                      <path
                                        id="Icon_material-delete-forever"
                                        data-name="Icon material-delete-forever"
                                        d="M8.679,23.357a2.364,2.364,0,0,0,2.357,2.357h9.429a2.364,2.364,0,0,0,2.357-2.357V9.214H8.679Zm2.9-8.391L13.24,13.3l2.51,2.5,2.5-2.5,1.662,1.662-2.5,2.5,2.5,2.5-1.662,1.662-2.5-2.5-2.5,2.5L11.59,19.963l2.5-2.5Zm8.3-9.287L18.7,4.5H12.8L11.625,5.679H7.5V8.036H24V5.679Z"
                                        transform="translate(-9.75 -11.198)"
                                        fill="#9a3407"
                                      />
                                    </g>
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  {cartDataRedux?.length > 0 ? null : <NoRecord />}
                </div>
                <div className="discount-row-wrapper">
                  <div className="row">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-4">
                      <div className="discount-wrapper">
                        <div className="discount-list-wrapper">
                          <ul>
                            <li>
                              <span className="property">Subtotal</span>
                              <span className="value">$ {subTotal}</span>
                            </li>
                            <li>
                              <span className="property">
                                Estimated Shipping Cost
                              </span>
                              <span className="value">{SiteSettingsRedux?.shippingFees}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="total-cost-wrapper">
                          <ul>
                            <li>
                              <span className="property"> TOTAL COST </span>
                              <span className="value">$ {subTotal + SiteSettingsRedux?.shippingFees}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="btn-wrapper fst-btn">
                    <button className="btn" onClick={(e) => navigate("/shop")}>
                      Continue Shopping
                    </button>
                    {/* <button className="btn-Clear ">Update Cart</button> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="btn-wrapper text-right">
                    {Token && cartDataRedux?.length > 0 ? (
                      <Link
                        to={`/checkout`}
                        state={{
                          data: {
                            CartData: cartDataRedux,
                            total: subTotal,
                            // couponData: couponData,
                          },
                        }}
                        className="btn"
                      >
                        Proceed to Checkout
                      </Link>
                    ) : (
                      <a className="btn" onClick={(e) => LoginHandler(e)}>
                        Proceed to Checkout
                      </a>
                    )}

                    <p className="mt-3">
                      You’ll still have a chance to review your order
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      {/* wishlist ends here */}
      {/* Footer starts here */}
      <Footer />
      {/* Footer ends here */}
    </>
  );
};

export default Product_cart;
