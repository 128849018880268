import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { PostProductReviewApi } from "../../network/Network";
import CircleLoad from "../Loader/CircleLoad";

const PostReview = (props) => {
  const { submitReview, setSubmitReview } = props;
  const Token = useSelector((state) => state.AuthReducer.token);
  let currentUrl = window.location.href.split("/");
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [reviewLoading, setReviewLoading] = useState(false);

  const handleRating = (rate) => {
    setRating(rate);
  };

  const SubmitRating = (e) => {
    e.preventDefault();
    setReviewLoading(true);
    if (!rating || !comment) {
      toast.error("Please select rating ");
      setReviewLoading(false);
      return;
    }
    if (!comment) {
      toast.error("Please enter your review ");
      setReviewLoading(false);
      return;
    }
    if (comment.length < 5 || comment.length > 300) {
      toast.error("Please enter your review between 5 to 300 characters");
      setReviewLoading(false);
      return;
    }
    let data = {
      product_id: currentUrl[4],
      comment: comment,
      rating: rating,
    };
    PostProductReviewApi(data, Token)
      .then((res) => {
        setReviewLoading(false);
        toast.success("Successfully send");
        setSubmitReview(!submitReview);
        setRating("");
        setComment("");
      })
      .catch((err) => {
        setReviewLoading(false);
        setSubmitReview(!submitReview);
        if (err?.response?.data?.message === "Unauthenticated.") {
          toast.error("Please Login");
        }
      });
  };

  return (
    <>
      <div className="review-form-wrapper">
        <form action="">
          <div className="form-group row">
            {/* <div className="col-lg-4">
														<input
															className="form-control"
															placeholder="Name"
															type="text"
														/>
													</div>

													<div className="col-lg-4">
														<input
															className="form-control"
															placeholder="Email"
															type="text"
														/>
													</div> */}
            <div className="col-lg-5">
              <div className="rate-wrapper">
                <label htmlFor="">Your Rating</label>
                <div id="full-stars-example-two">
                  <div className="rating-group">
                    <Rating
                      allowFraction={true}
                      onClick={handleRating}
                      ratingValue={rating}
                    />
                    {/* <input
																		disabled
																		checked
																		className="rating__input rating__input--none"
																		name="rating3"
																		id="rating3-none"
																		value="0"
																		type="radio"
																	/>
																	<label
																		aria-label="1 star"
																		className="rating__label"
																		for="rating3-1"
																	>
																		<i className="rating__icon rating__icon--star fa fa-star"></i>
																	</label>
																	<input
																		className="rating__input"
																		name="rating3"
																		id="rating3-1"
																		value="1"
																		type="radio"
																	/>
																	<label
																		aria-label="2 stars"
																		className="rating__label"
																		for="rating3-2"
																	>
																		<i className="rating__icon rating__icon--star fa fa-star"></i>
																	</label>
																	<input
																		className="rating__input"
																		name="rating3"
																		id="rating3-2"
																		value="2"
																		type="radio"
																	/>
																	<label
																		aria-label="3 stars"
																		className="rating__label"
																		for="rating3-3"
																	>
																		<i className="rating__icon rating__icon--star fa fa-star"></i>
																	</label>
																	<input
																		className="rating__input"
																		name="rating3"
																		id="rating3-3"
																		value="3"
																		type="radio"
																	/>
																	<label
																		aria-label="4 stars"
																		className="rating__label"
																		for="rating3-4"
																	>
																		<i className="rating__icon rating__icon--star fa fa-star"></i>
																	</label>
																	<input
																		className="rating__input"
																		name="rating3"
																		id="rating3-4"
																		value="4"
																		type="radio"
																	/>
																	<label
																		aria-label="5 stars"
																		className="rating__label"
																		for="rating3-5"
																	>
																		<i className="rating__icon rating__icon--star fa fa-star"></i>
																	</label>
																	<input
																		className="rating__input"
																		name="rating3"
																		id="rating3-5"
																		value="5"
																		type="radio"
																	/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <textarea
              name=""
              placeholder="Your Review"
              className="form-control"
              id=""
              cols="30"
              rows="5"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className="form-group row mt-4">
            {/* <div className="col-lg-10">
														<div className="form-check">
															<input
																className="form-check-input"
																type="checkbox"
																value=""
																id="flexCheckDefault"
															/>
															<label
																className="form-check-label"
																for="flexCheckDefault"
															>
																By using this form you agree with the storage
																and handling of your data by this website.
															</label>
														</div>
													</div> */}
            <div className="col-lg-2">
              <button onClick={(e) => SubmitRating(e)} disabled={reviewLoading}>
                {reviewLoading ? <CircleLoad /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PostReview;
