import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";

import { arrival3 } from "../../constants";
import { GetProductsDetails, PostAddWishList } from "../../network/Network";
import CircleLoad from "../Loader/CircleLoad";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SimilarProducts = ({ similarProductsData }) => {
  const [loading, setLoading] = useState(false);
  const Token = useSelector((state) => state.AuthReducer.token);

  const AddWishListHandler = (e, id) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      product_id: id,
    };
    PostAddWishList(data, Token)
      .then((res) => {
        setLoading(false);
        // setActive(!active);
        toast.success("Product Add to your Wishlist");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <div className="product-card-wrapper">
      <div className="product-img-wrapper">
        {/* <Link to={`${"/product"}/${similarProductsData?.id}`}> */}
        <a href={`${"/product"}/${similarProductsData?.id}`}>
          <figure>
            <img
              src={similarProductsData?.image}
              className="img-fluid"
              alt=""
            />
          </figure>
        </a>
        {/* </Link> */}
      </div>
      <div className="product-content-wrapper">
        <h5>{similarProductsData?.brandName}</h5>
        <span className="ice">Asia Green Bananas</span>
        <div className="rating-wrapper">
          {/* <ul>
            <li>
              <i className="fa fa-star"></i>
            </li>
            <li>
              <i className="fa fa-star"></i>
            </li>
            <li>
              <i className="fa fa-star"></i>
            </li>
            <li>
              <i className="fa fa-star"></i>
            </li>
            <li>
              <i className="fa fa-star"></i>
            </li>
            <li className="total-rating">
              <span>(1)</span>
            </li>
          </ul> */}
          <Rating
            size={20}
            readonly={true}
            allowFraction={true}
            initialValue={similarProductsData?.averageRating}
          />
        </div>
        {/* <h6>5kg</h6> */}
        <h4>${similarProductsData?.totalPrice}</h4>

        {/* <div className="counter-total-wrapper">
          <input type="number" min={0} />
          <h6>
            Total: <span>$38.5</span>
          </h6>
        </div> */}
        {/* <a href="#" className="btn">
          Add To Cart
        </a> */}
        <a
          onClick={(e) => AddWishListHandler(e, similarProductsData?.id)}
          className="btn"
          disabled={loading}
        >
          {loading ? <CircleLoad /> : "Add To Wishlist"}
        </a>
      </div>
    </div>
  );
};

export default SimilarProducts;
