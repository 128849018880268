import React from "react";
import { Redirect, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function SellerProtectedRoutes({ component: Component, ...restOfProps }) {
  const isLogin = useSelector((state) => state.AuthReducer.isLogin)
  const userReduxData = useSelector((state) => state.AuthReducer.users);

  // const isLogin = true 
  return <>{isLogin && userReduxData?.user_type == 2 ? <Outlet /> : <Navigate to="/" />}</>;
}

export default SellerProtectedRoutes;
