import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/Mywishlist.css";
import { wish1, wish2, wish3 } from "../../constants";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { GetAllWishList, PostWishListDelete } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../components/NoRecord/NoRecord";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { AddToCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";

const MyWishlist = () => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const [fvtData, setFvtData] = useState([]);
  const [filteredFvtData, setFilteredFvtData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedData, setSelectedData] = useState();

  const [selectedVariation, setSelectedVariation] = useState("");
  const [selectedVariation_id, setSelectedVariation_id] = useState("");

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  // Get wishlist Data
  useEffect(() => {
    setSpinLoad(true);
    GetAllWishList(currentPage, Token)
      .then((res) => {
        setFvtData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);

  const DeleteWishListHandler = (id, index) => {
    setSpinLoad(true);
    let data = {
      product_ids: JSON.stringify([id]),
    };
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        PostWishListDelete(data, Token)
          .then((res) => {
            setSpinLoad(false);
            setFvtData((preval) =>
              preval.filter((val) => val?.product?.id != id)
            );
          })
          .catch((err) => {
            setSpinLoad(false);
          });
      } else {
        swal("Your product is safe!");
        setSpinLoad(false);
      }
    });
  };

  const AddToCartHandler = (e, item, index) => {
    e.preventDefault();
    // setSpinLoad(true);
    if (!selectedVariation) {
      toast.error("Please Select variant");
      return;
    }
    if (item?.product?.variation) {
      let newVarient = item?.product?.variation?.filter(
        (val) => val?.type == selectedVariation
      );
      if (newVarient[0]?.qty > 0) {
        let checkItemAlreadyExist = cartDataRedux.filter(
          (val) => val?.id === item?.product?.id
        );

        // if (checkItemAlreadyExist.length > 0) {
        // 	swal({
        // 		title: "Item Already Exist in Cart!!!",
        // 	});
        // }

        // else {
        let data = {
          id: item?.product?.id,
          price: newVarient[0]?.price
            ? newVarient[0]?.price
            : item?.product?.latest_price,
          qty: quantity,
          // variation: selectedVariation,
          variant_name: newVarient[0]?.type,
          productitem: item?.product,
          variant_id: selectedVariation_id,
        };
        dispatch(AddToCart(data));
        toast("Product successfully added");
        let dataRemove = {
          product_ids: JSON.stringify([item?.product?.id]),
        };
        PostWishListDelete(dataRemove, Token)
          .then((res) => {
            setSpinLoad(false);
            setFvtData((preval) =>
              preval.filter((val) => val?.product?.id != item?.product?.id)
            );
          })
          .catch((err) => {
            setSpinLoad(false);
          });
        // }
      } else {
        toast("Selected Variation is out of stock");
        return;
      }
    }
  };

  return (
    <>
      <Header Header_Cate={false} />
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* wishlist starts here */}
              <div className="wishlist-wrapper">
                <div className="wishlist-heading-wrapper">
                  <h3>My Wishlist</h3>
                </div>
                <div className="wishlis-table-wrapper">
                  {spinLoad ? (
                    <>
                      {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                        return (
                          <div className="col-md-12 mt-3" key={index}>
                            <Skeleton count={5} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {fvtData?.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="cross-col"></th>
                                <th className="cross-col"></th>
                                <th className="product-col">PRODUCT NAME </th>
                                <th className="unit-col">UNIT PRICE</th>
                                <th className="btn-col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fvtData?.map((item, index) => {
                                return (
                                  <tr>
                                    <td className="cross" key={index}>
                                      <button
                                        className="cross-btn"
                                        onClick={(e) =>
                                          DeleteWishListHandler(
                                            item?.product?.id,
                                            index
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-times-circle-o"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </td>
                                    <td className="product-wrapper">
                                      <div className="img-wrapper">
                                        <img
                                          src={item?.product?.image_url}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </td>
                                    <td className="product-wrapper">
                                      <div className="content-wrapper">
                                        <h5>{item?.product?.title}</h5>
                                      </div>
                                      <div>
                                        <select
                                          className="form-control"
                                          onChange={(e) => {
                                            setSelectedVariation(
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option selected disabled>
                                            Select Variation
                                          </option>
                                          {item?.product?.variation?.map(
                                            (inner) => {
                                              return (
                                                <>
                                                  <option
                                                    key={inner?.id}
                                                    value={inner?.type}
                                                  >
                                                    {inner?.type}
                                                  </option>
                                                </>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                    </td>
                                    <td className="unit">
                                      <span className="unit-price">
                                        ${item?.product?.latest_price}{" "}
                                      </span>
                                    </td>
                                    <td className="cart-btn">
                                      <button
                                        className="btn"
                                        onClick={(e) =>
                                          AddToCartHandler(e, item, index)
                                        }
                                      >
                                        Add to cart
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <NoRecord />
                      )}
                    </>
                  )}
                </div>
                <div className="paginations-wrapper">
                  <div className="pagination-container mt-5">
                    <ReactPaginate
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
              {/* wishlist ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* My adresses ends here */}
      <Footer />
    </>
  );
};

export default MyWishlist;
