import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { PostAddressApi } from "../../network/Network";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


function SellerDashboardOnBoardModal(props) {
  const { isOpenPaymentLinkModal, setIsOpenPaymentLinkModal, modalData } = props;
  const Token = useSelector((state) => state.AuthReducer.token);
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(false);
 


  return (
    <>
      <Modal
        isOpen={isOpenPaymentLinkModal}
        toggle={() => {
            setIsOpenPaymentLinkModal(false)
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        {/* <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenAddressModal(false)}
        /> */}
        <h2 className="modal-mainTitle">Stripe OnBoarding Link</h2>
        <div>
        <div className="custom_form">
                      <div className="row">
                      
                      <a href={modalData?.url} target="_blank">{modalData?.url}</a>
                      </div>
                    </div>
    
        </div>
      </Modal>
    </>
  );
}

export default SellerDashboardOnBoardModal;
