import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/return.css";
import "../../assets/css/Myorder.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useDispatch, useSelector } from "react-redux";
import { GetAllOrder } from "../../network/Network";
import NoRecord from "../../components/NoRecord/NoRecord";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Myorder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [myOrderData, setMyOrderData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllOrder(currentPage, Token)
      .then((res) => {
        setMyOrderData(res?.data?.response?.data?.data);
          const total = res?.data?.response?.data?.total;
          const limit = res?.data?.response?.data?.per_page;
          setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);


  return (
    <>
     <Header 
				Header_Cate={false}
			/>
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* return payment starts here */}
              <div className="return-request-wrapper">
                <div className="return-heading-wrapper">
                  <h3>My Orders</h3>
                </div>
                {spinLoad ? (
                  <>
                    {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                      return (
                        <div className="col-md-12 mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })}
                  </>
                ) : myOrderData?.length > 0 ? (
                  <>
                    <div className="return-table-wrapper">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="Order-h">OrderNo</th>
                              <th className="Date-h">Order Date</th>
                              <th className="Return-h">Status</th>
                              <th className="Payment-h">Payment Status</th>
                              <th className="Total-h">Total</th>
                              <th className="detail-h">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {myOrderData?.map((item, index) => {
                              return (
                                <tr>
                                  <td className="order-id" key={index}>
                                    <span>#{item?.id}</span>
                                  </td>
                                  <td className="date-id">
                                    <span>
                                      {" "}
                                      {moment(item?.created_at).format(
                                        "MMMM Do YYYY, h:mm a"
                                      )}{" "}
                                    </span>
                                  </td>
                                  <td className="reject-id">
                                    <span className="Confirm">
                                  
                                      {
                                        item?.order_details?.length <= 0 ? null : 
                                        item?.order_details[0]?.status == 0 ? "Pending" : 
                                        item?.order_details[0]?.status == 1 ? "Process" : 
                                        item?.order_details[0]?.status == 2 ? "Shipped" : 
                                        item?.order_details[0]?.status == 3 ? "Complete" :
                                        item?.order_details[0]?.status == 4 ? "Cancel" : null
                                      }
                                   </span>
                                  </td>
                                  <td className="order-id">
                                    <span className="paid">
                                      {item?.payment_status == 1
                                        ? "Paid"
                                        : "Unpaid"}{" "}
                                      <i className="fa fa-check-circle"></i>
                                    </span>
                                  </td>
                                  <td className="total">
                                    <span>${item?.total_amount}</span>
                                  </td>
                                  <td className="btns">
                                    <button
                                      onClick={(e)=>navigate(`/order-detail/${item?.id}`)}
                                      className="btn"
                                    >
                                      view Details
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <NoRecord />
                )}

                <div className="paginations-wrapper">
                  <div className="pagination-container mt-5">
                    <ReactPaginate
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Myorder;
