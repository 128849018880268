import React, { useState, useEffect } from "react";
import "../../assets/css/seller-add-products.css";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import {
  GetProductsDetails,
  GetSellerProductAttributes,
  GetSellerProductSpecificAttributes,
  GetSellerProductsDetails,
  PostSellerProduct,
  PostSellerUpdateProduct,
  updateSellerProduct,
} from "../../network/Network";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CircleLoad from "../../components/Loader/CircleLoad";
import InputField from "../../components/Form/InputField";
import { GrAddCircle } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";
import AddVariation from "../../components/Seller/AddVariation";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import ProductVariantCombination from "../../components/Seller/ProductVariantCombination";

const SellerUpdateProduct = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const CategoriesData = useSelector((state) => state.AuthReducer.categories);

  const paramId = useParams();
  const [productDetails, setProductDetails] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selectCategory, setSelectCategory] = useState();
  const [selectSubCategory, setSelectSubCategory] = useState();
  const [logo, setLogo] = useState("");
  const [getlogo, setGetLogo] = useState("");
  const [logoFileupload, setLogoFileupload] = useState("");
  const [multiImageFiles, setMultiImageFiles] = useState([]);
  const [multiImageFiles2, setMultiImageFiles2] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [slug, setSlug] = useState();
  const [userData, setUserData] = useState({
    name: "",
    unitPrice: "",
    purchasePrice: "",
    discount: "",
    description: "",
    shortDescription: "",
  });
  const [combArray, setCombArray] = useState([]);

  // const [attributeData, setAttributeData] = useState([]);
  const [selectAttribute, setSelectAttribute] = useState();
  const [selectedVariantData, setSelectedVariantData] = useState([]);
  const [stock, setStock] = useState(0);
  // const [variantData, setVariantData] = useState({
  // 	price: "",
  // 	quantity: "",
  // 	sku: "",
  // });
  const [selectedAttributeData, setSelectedAttributeData] = useState([]);
  const [checkState, setCheckState] = useState(false);

  // Dynamic Product Variations Start
  const [attributeData, setAttributeData] = useState([]);
  const [getMainAttributeData, setMainAttributeData] = useState([]);
  const [getAllAttributeData, setAllAttributeData] = useState([]);
  const [getAttributeValuesData, setAttributeValuesData] = useState([]);
  const [variantData, setVariantData] = useState([
    {
      type: "",
      price: "",
      qty: "",
      sku: "",
    },
  ]);
  const [getAttributeWithValues, setAttributeWithValues] = useState([]);
  const [variations, setVariations] = useState([]);
  const [isOpenAttributeValuetModal, setIsOpenAttributeValuetModal] =
    useState(false);
  const [attributeValueMessage, setAttributeValueMessage] = useState();
  const [attributeValueLoad, setAttributeValueLoad] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [getAttributesChildren, setAttributesChildren] = useState([]);
  const [processedOptions, setProcessedOptions] = useState([]);
  const [choices, setChoices] = useState([
    {
      type: "",
      options: [],
    },
  ]);
  const [getIsNew, setIsNew] = useState([
    {
      type: "",
      options: [
        {
          value: "",
          isNew: false,
        },
      ],
    },
  ]);
  const [getFinalAttValues, setFinalAttValues] = useState([]);
  const [attributeMenu, setAttributeMenu] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);
  const [DefaultAtrributeValues, setDefaultAtrributeValues] = useState([]);

  // product Details
  useEffect(() => {
    setSpinLoad(true);
    GetSellerProductsDetails(paramId?.ID, Token)
      .then((res) => {
        setProductDetails(res?.data?.response?.data);
        let product = res?.data?.response?.data;
        setCombArray(product?.variation);
        setMultiImageFiles2(product?.multimedia);
        const getVarientsFromApi = product?.variant?.map((variant) => {
          return {
            price: variant?.price,
            quantity: variant?.qty,
            sku: variant?.sku,
          };
        });
        setChoices(product?.choice_options);
        setVariantData(product?.variation);
        const getProductVarientsFromApi = product?.choice_options?.map(
          (getAttribute) => {
            return {
              value: getAttribute?.type?.toLowerCase(),
              label: camelize(getAttribute?.type),
            };
          }
        );
        setSelectedOption(getProductVarientsFromApi);

        const getProductDefaultVariantChildren = product?.choice_options?.map(
          (getAttribute, index) => {
            const values = getAttribute?.options;
            const result = values?.map((attributeValue, innerIndex) => {
              return {
                value: attributeValue.toLowerCase(),
                label: camelize(attributeValue),
              };
            });

            const updatedAttributesChildren = [...getAttributesChildren];
            updatedAttributesChildren[index] = result;

            return result; // Added return statement to return the result
          }
        );

        setAttributesChildren(getProductDefaultVariantChildren);
        setDefaultAtrributeValues(product?.choice_options);

        // extract Product Main Attribute Id from product_variant
        const extractAttributeIds = product?.product_variant?.map(
          (attribute) => {
            return attribute?.attribute_id;
          }
        );

        // extract Product Main Attribute Value Id from product_variant
        const extractAttributeValueIds = product?.product_variant?.map(
          (attribute) => {
            return attribute?.attribute_value_id;
          }
        );

        // Filiter data ( Main Attribute) from All Attributes (attributeData) on the basis of Main attribute Id (extractAttributeIds)
        const filterAttributeMainValueFromApi = extractAttributeIds?.map((id) =>
          attributeData?.filter((attribute) => attribute?.id === id)
        );

        const filterAttributeValuesFromApi =
          filterAttributeMainValueFromApi?.map((values) => {
            return values[0]?.att_values?.filter((attribute) =>
              extractAttributeValueIds.includes(attribute?.id)
            );
          });

        setUserData({
          name: product?.title ? product?.title : "",
          unitPrice: product?.latest_price ? product?.latest_price : "",
          purchasePrice: product?.old_price ? product?.old_price : "",
          discount: "",
          description: product?.description ? product?.description : "",
          shortDescription: product?.short_description
            ? product?.short_description
            : "",
        });
        setStock(product?.stock);
        setGetLogo(product?.image_url);
        setSelectCategory(product?.category_id);
        setSelectSubCategory(product?.sub_category_id);
        async function AllVariation() {
          let results = [];
          const varientLength =
            product?.variant !== undefined ? product?.variant.length : 0;
          for (let i = 0; i < varientLength; i++) {
            results.push({
              price: product?.variant[i]?.price,
              quantity: product?.variant[i]?.qty,
              sku: product?.variant[i]?.sku,
            });
          }
          await setSelectedVariantData(results);
        }

        async function AllAtribute() {
          let results2 = [];
          const varientLength =
            product?.variant !== undefined
              ? product?.product_variant?.length
              : 0;
          for (let j = 0; j < varientLength; j++) {
            results2.push({
              attribute_id: product?.product_variant[j]?.attribute_id,
              attribute_value_id:
                product?.product_variant[j]?.attribute_value_id,
              sku: product?.variant[j]?.sku,
            });
          }
          await setSelectedAttributeData(results2);
        }
        AllVariation();
        AllAtribute();
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [paramId?.ID]);

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  }

  const HandleLogoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setLogo(file);
      setLogoFileupload(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setMultiImageFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
      Array.from(e.target.files).map((file) => {
        setUploadImages((preVal) => {
          return [...preVal, file];
        });
      });
    }
  };

  const renderPhotos = (source) => {
    return source?.map((photo, index) => {
      return (
        <li>
          {" "}
          <img
            src={photo}
            alt=""
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </li>
      );
    });
  };
  const renderPhotosApi = (source) => {
    return source?.map((photo, index) => {
      return (
        <li>
          {" "}
          <img
            src={photo?.image_url}
            alt=""
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </li>
      );
    });
  };

  useEffect(() => {
    GetSellerProductAttributes(Token)
      .then((res) => {
        setAttributeData(res?.data?.response?.data);
      })
      .catch((err) => {
      });
  }, []);

  function handleChange2(e) {
    const value = e.target.value;
    setVariantData({
      ...variantData,
      [e.target.name]: value,
    });
  }

  const VariantHandler = (e) => {
    e.preventDefault();
    setSelectedVariantData([...selectedVariantData, variantData]);
    setStock(parseInt(stock) + parseInt(variantData?.quantity));
    setVariantData({
      price: "",
      quantity: "",
      sku: "",
    });

    let sku_name, attribute_variations, selected_attribute_variation;
    if (variantData?.sku) {
      sku_name = variantData?.sku.split("-");
      attribute_variations = attributeData?.filter(
        (val) => val?.id == selectAttribute
      );
      if (attribute_variations[0]) {
        selected_attribute_variation =
          attribute_variations[0]?.att_values?.filter(
            (val) => val?.name == sku_name[1]
          );
        setSelectedAttributeData([
          ...selectedAttributeData,
          {
            attribute_id: selectAttribute,
            attribute_value_id: selected_attribute_variation[0]?.id,
            sku: variantData?.sku?.replaceAll(" ", ""),
          },
        ]);
      }
    }
  };

  const RemoveVariation = (e, index_no) => {
    e.preventDefault();
    let newVariation = selectedVariantData?.splice(index_no, 1);
    setVariantData(newVariation);
    setStock(parseInt(stock) - parseInt(newVariation[0]?.quantity));
    let newAttrivute = selectedAttributeData?.splice(index_no, 1);
    setSelectedAttributeData(newAttrivute);
  };

  // Variation stock
  useEffect(() => {
    if (variantData?.length > 0) {
      let total = 0;
      for (let i = 0; i < variantData?.length; i++) {
        total = parseInt(total) + parseInt(variantData[i]?.qty);
      }
      setStock(total);
    }
  }, [variantData]);

  // OverAllDynamic Product Variations Functionility Start
  const getAttributeValues = (value) => {
    setAttributesChildren(value);
  };

  const generateVariations = () => {
    const generatedVariations = [];
    const propertyOptions = getAttributeWithValues.map((obj) => {
      const property = Object.keys(obj)[0];
      const values = obj[property];
      return { property, values };
    });

    const generateCombinations = (options, currentCombination = []) => {
      if (options.length === 0) {
        generatedVariations.push(currentCombination);
        return;
      }
      if ((options !== undefined) & (options?.length > 0)) {
        const { property, values } = options[0] || {};
        values?.forEach((value) => {
          const newCombination = { ...currentCombination };

          if (Array.isArray(newCombination[property])) {
            newCombination[property].push(value);
          } else {
            newCombination[property] = [value];
          }

          generateCombinations(options.slice(1), newCombination);
        });
      }
    };

    generateCombinations(propertyOptions);

    setVariations(generatedVariations);
  };

  useEffect(() => {
    generateVariations();
  }, [getAttributeWithValues]);

  const variantDataHandler = (e, index) => {
    const { name, value } = e.target;

    setVariantData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [name]: value,
      };
      return newData;
    });
  };

  const createAttributeValueHandler = (e) => {
    e.preventDefault();
    setIsOpenAttributeValuetModal(false);
  };

  useEffect(() => {
    GetSellerProductAttributes(Token)
      .then((res) => {
        setAttributeData(res?.data?.response?.data);
        const getAttribute = res?.data?.response?.data?.map((attr) => {
          const attributeLabel =
            attr?.name?.charAt(0)?.toUpperCase() + attr?.name?.slice(1);
          return {
            value: attr?.name,
            label: attributeLabel,
          };
        });
        setMainAttributeData(getAttribute);
      })
      .catch((err) => {});
  }, []);

  const attributeMainHandler = (option, index) => {
    // Check if the option has already been processed
    if (processedOptions.includes(index)) {
      return; // Exit the function if it has already been processed
    }

    // Add the index to the processedOptions state
    setProcessedOptions([...processedOptions, index]);
    // Rest of the function logic...
    GetSellerProductSpecificAttributes(Token)
      .then((res) => {
        const result = res?.data?.response?.data;
        const filteredResults = result?.filter(
          (attribute) => attribute?.name === option
        );

        const removeArrayFilteredResults = Object.assign(
          {},
          ...filteredResults
        );

        const setAttributesOptions =
          removeArrayFilteredResults?.att_values?.map((attr) => {
            const attributeLabel =
              attr?.name?.charAt(0)?.toUpperCase() + attr?.name?.slice(1);
            return {
              value: attr?.name,
              label: attributeLabel,
            };
          });

        setAttributeValuesData((prevData) => {
          const newData = [...prevData];
          newData[index] = {
            ...newData[index],
            setAttributesOptions,
          };
          setAttributesOptions !== undefined &&
            newData[index].setAttributesOptions.push({
              value: "add_new",
              label: "Add New Value",
            });
          return newData;
        });

        const filiteredAttribute = result?.filter(
          (attribute) => attribute?.name === option
        );

        const removeArrayFiliteredAttribute = Object.assign(
          {},
          ...filiteredAttribute
        );
        const finalAttValues = removeArrayFiliteredAttribute?.att_values?.map(
          (attValues) => {
            return {
              value: attValues?.name,
              label: attValues?.name,
            };
          }
        );

        setFinalAttValues(finalAttValues);
      })
      .catch((err) => {});
  };

  const handleSelectChange = (index, selectedOptions, attributeType) => {
    const updatedChoice = [...choices];
    updatedChoice[index] = {
      type: attributeType?.label,
      options: selectedOptions?.map((selectedOptionValue) => {
        return selectedOptionValue?.value;
      }),
    };
    setChoices(updatedChoice);

    const updatedState = [...getAttributesChildren];
    updatedState[index] = selectedOptions;
    setAttributesChildren(updatedState);

    const hasAddNewOption = selectedOptions.some(
      (option) => option.value === "add_new"
    );

    if (hasAddNewOption) {
      setIsOpenAttributeValuetModal(true);
      const updatedAttributesChildren = [...getAttributesChildren];
      updatedAttributesChildren[index] = [
        ...(updatedAttributesChildren[index] || []),
        {
          value: attributeValueMessage,
          label: camelize(attributeValueMessage),
        },
      ];
      setAttributesChildren(updatedAttributesChildren);
    }
  };

  function camelize(str = "") {
    return str.replace(/[-_\s]+(.)?/g, function (match, chr) {
      return chr ? chr.toUpperCase() : "";
    });
  }

  useEffect(() => {
    const generateDataForVariations =
      getAttributesChildren !== undefined &&
      getAttributesChildren?.map((attribute, index) => {
        return {
          generatedvarients: attribute?.map((value, index2) => {
            return value?.label;
          }),
        };
      });
    setAttributeWithValues(generateDataForVariations);
  }, [getAttributesChildren]);

  // filter choices on the basis of selected options
  useEffect(() => {
    // Create a copy of choices state to modify
    let updatedChoices = [...choices];

    // Convert type to lowercase before comparison
    const selectedTypes = selectedOption
      ? selectedOption.map((option) => option.value.toLowerCase())
      : [];

    // Filter out choices that do not have a matching type in selectedOption
    updatedChoices = updatedChoices.filter((choice) =>
      selectedTypes.includes(choice.type.toLowerCase())
    );

    // Update choices state with the filtered choices
    setChoices(updatedChoices);
  }, [selectedOption]);
  // OverAllDynamic Product Variations Functionility End

  const EditProductHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !userData?.name ||
      !userData?.shortDescription ||
      !userData?.description
    ) {
      toast.error("Please enter all fields ");
      setLoading(false);
      return;
    }
    if (!selectCategory) {
      toast.error("Please Select Category");
      setLoading(false);
      return;
    }
    if (!selectSubCategory) {
      toast.error("Please Select Sub-Category");
      setLoading(false);
      return;
    }
    // if (!logo) {
    // 	toast.error("Please upload Thumbnail");
    // 	setLoading(false);
    // 	return;
    // }
    if (!userData?.purchasePrice) {
      toast.error("Old price is required");
      setLoading(false);
      return;
    }
    if (!userData?.unitPrice) {
      toast.error("Latest price is required");
      setLoading(false);
      return;
    }
    if (!uploadImages) {
      toast.error("Please upload Product images");
      setLoading(false);
      return;
    }

    if (!selectedVariantData) {
      toast.error("Please add product variation");
      setLoading(false);
      return;
    }
    if (!stock) {
      toast.error("Stock is required");
      setLoading(false);
      return;
    }
    // if (!checkState) {
    //   toast.error("Please check our condition");
    //   setLoading(false);
    //   return;
    // }
    let data = new FormData();
    data.append("category_id", selectCategory);
    data.append("sub_category_id", selectSubCategory);
    data.append("title", userData?.name);
    data.append("slug", userData?.name?.replace(/\s/g, "-"));
    data.append("short_description", userData?.shortDescription);
    data.append("image", logo);
    uploadImages.map((singlepic) => {
      data.append("multi_images[]", JSON.stringify(singlepic));
    });
    data.append("description", userData?.description);
    data.append("old_price", userData?.purchasePrice);
    data.append("latest_price", userData?.unitPrice);
    data.append("stock", stock);
    data.append("id", paramId?.ID);
    data.append("variations", JSON.stringify(variantData));
    data.append("choices", JSON.stringify(choices));
    updateSellerProduct(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setLoading(false);
        navigate("/seller-Inventory");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleMenuOpen = (index) => {
    setAttributeMenu(index);
  };

  const handleMenuClose = () => {
    setAttributeMenu(null);
  };

  const handleInputChange = (newValue, index) => {
    setInputValues((prev) => {
      const newValues = [...prev];
      newValues[index] = newValue;
      return newValues;
    });
  };

  const handleKeyDown = (event, index) => {
    if (!inputValues[index]) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValues((prev) => {
          const newValues = [...prev];
          const optionValue = createOption(inputValues[index]);

          if (typeof optionValue === "string") {
            newValues[index] = optionValue;
          }

          return newValues;
        });
        setInputValue("");
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleSelectChange2 = (index, selectedOptions, attributeType) => {
    const updatedGetIsNew = [...getIsNew];
    updatedGetIsNew[index] = {
      type: attributeType?.label,
      options: selectedOptions?.map((selectedOptionValue) => {
        return {
          value: selectedOptionValue?.value,
          isNew:
            selectedOptionValue?.__isNew__ !== undefined
              ? selectedOptionValue?.__isNew__
              : false,
        };
      }),
    };
    setIsNew(updatedGetIsNew);

    const updatedChoice = [...choices];
    updatedChoice[index] = {
      type: attributeType?.label,
      options: selectedOptions?.map((selectedOptionValue) => {
        return selectedOptionValue?.value;
      }),
    };
    setChoices(updatedChoice);
    setValue(selectedOptions);

    const removeIsNewKey = (arr) => {
      return arr.map((subArr) => {
        return subArr.map((obj) => {
          const { __isNew__, ...rest } = obj;
          return rest;
        });
      });
    };

    const updatedState = [...getAttributesChildren];
    updatedState[index] = selectedOptions;
    // const result = removeIsNewKey(updatedState);
    setAttributesChildren(updatedState);

    // Update variations when an option is removed
    generateVariations();
  };

  const defaultAtrributeValuesHandler = (attribute) => {
    const filteredValues = DefaultAtrributeValues?.filter(
      (atrribute) => atrribute?.type?.toLowerCase() === attribute?.toLowerCase()
    );

    const DefaultValues =
      filteredValues.length > 0 &&
      filteredValues[0]?.options?.map((attributeValue) => {
        return {
          value: attributeValue.toLowerCase(),
          label: camelize(attributeValue),
        };
      });
    return DefaultValues;
  };

  return (
    <>
      <SellerHeader />

      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <div className="products_adding">
                <div className="heading_wrapper">
                  <h3>Edit Products</h3>
                </div>
                <form action="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="general_info">Product Name/Title</label>
                        <InputField
                          type="text"
                          value={userData?.name}
                          onChange={handleChange}
                          placeholder="Product Name/Title"
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="general_info">Slug</label>
                        <InputField
                          type="text"
                          value={userData?.name?.replace(/\s/g, "-")}
                          onChange={(e) => setSlug(e.target.value)}
                          placeholder="slug"
                          name="slug"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="general_info">Short Description</label>
                        <InputField
                          type="text"
                          value={userData?.shortDescription}
                          onChange={handleChange}
                          placeholder="Short Description"
                          name="shortDescription"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="select_category">
                          Select categories{" "}
                        </label>
                        <select
                          id="select_category"
                          className="form-control"
                          onChange={(e) => {
                            setSelectCategory(e.target.value);
                          }}
                        >
                          <option selected disabled>
                            Select Category
                          </option>
                          {CategoriesData?.map((item, index) => {
                            return (
                              <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="sub_category">
                          Select Sub-categories
                        </label>

                        <select
                          id="select_category"
                          className="form-control"
                          onChange={(e) => {
                            setSelectSubCategory(e.target.value);
                          }}
                        >
                          <option selected disabled>
                            Select Sub Category
                          </option>
                          {CategoriesData?.map((item, index) => {
                            return (
                              <>
                                {item?.id == selectCategory
                                  ? item?.sub_category?.map((inner, index) => {
                                      return (
                                        <option value={inner?.id} key={index}>
                                          {inner?.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <h5>Select Attributes</h5>

                        <div>
                          <Select
                            defaultValue={selectedOption}
                            onChange={(selected) => {
                              setSelectedOption((prevSelected) => [
                                ...prevSelected,
                                ...selected,
                              ]);
                            }}
                            isMulti={true}
                            className="dropdown"
                            options={getMainAttributeData}
                          />
                        </div>
                        <div className="row mt-4 mb-4">
                          {selectedOption !== undefined &&
                          selectedOption?.length > 0
                            ? selectedOption?.map((option, index) => {
                                attributeMainHandler(option?.value, index);
                                return (
                                  <>
                                    <div className="col-md-6">
                                      <div className="files_flex">
                                        <div>
                                          <InputField
                                            type="text"
                                            value={option?.label}
                                            placeholder="variant"
                                            name="variant"
                                            disabled={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="files_flex">
                                        <div>
                                          <CreatableSelect
                                            defaultValue={defaultAtrributeValuesHandler(
                                              option?.label
                                            )}
                                            key={index}
                                            components={components}
                                            inputValue={inputValues[index]}
                                            isClearable
                                            isMulti
                                            onChange={(newValue) =>
                                              handleSelectChange2(
                                                index,
                                                newValue,
                                                option
                                              )
                                            }
                                            onInputChange={(newValue) =>
                                              handleInputChange(newValue, index)
                                            }
                                            onKeyDown={(e) =>
                                              handleKeyDown(e, index)
                                            }
                                            menuIsOpen={attributeMenu === index}
                                            onMenuOpen={() =>
                                              handleMenuOpen(index)
                                            }
                                            onMenuClose={handleMenuClose}
                                            // options={
                                            //   getAttributeValuesData[index]
                                            //     ?.setAttributesOptions
                                            // }
                                            placeholder="Type Attribute Value to add new value ..."
                                            labelKey="label"
                                          />

                                          {/* <Select
																						onChange={(selectedOptions) =>
																							handleSelectChange(
																								index,
																								selectedOptions,
																								option,
																							)
																						}
																						isMulti={true}
																						className="dropdown"
																						options={
																							getAttributeValuesData[index]
																								?.setAttributesOptions
																						}
																					/> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div>
                        <div className="row">
                          {combArray != undefined && combArray?.length > 0 ? (
                            <>
                              {combArray?.map((item, index) => {
                                return (
                                  <>
                                    <ProductVariantCombination
                                      item={item}
                                      index={index}
                                      variantData={variantData}
                                      variantDataHandler={variantDataHandler}
                                    />
                                  </>
                                );
                              })}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-12">
											<div className="form-group">
												<label htmlFor="sub_category">Variant Attribute</label>
												<select
													id="select_category"
													className="form-control"
													onChange={(e) => {
														setSelectAttribute(e.target.value);
													}}
												>
													<option selected disabled>
														Variant Attribute
													</option>
													{attributeData?.map((item, index) => {
														return (
															<option value={item?.id} key={index}>
																{item?.name}
															</option>
														);
													})}
												</select>
											</div>
										</div> */}

                    {/* <div className="col-md-4">
											<div className="files_flex">
												<div>
													<label>Variant SKU</label>
													<select
														className="form-control"
														onChange={handleChange2}
														name="sku"
													>
														<option selected disabled>
															Select Variant
														</option>
														{attributeData?.map((item, index) => {
															return (
																<>
																	{item?.id == selectAttribute
																		? item?.att_values?.map(
																				(inner_Item, index) => {
																					return (
																						<option
																							value={
																								item?.name +
																								"-" +
																								inner_Item?.name
																							}
																							key={index}
																						>
																							{inner_Item?.name}
																						</option>
																					);
																				},
																		  )
																		: null}
																</>
															);
														})}
													</select>
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="files_flex">
												<div>
													<label>Price</label>

													<InputField
														type="number"
														value={variantData?.price}
														onChange={handleChange2}
														placeholder="Price"
														name="price"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="files_flex">
												<div>
													<label>Quantity</label>
													<InputField
														type="number"
														value={variantData?.quantity}
														onChange={handleChange2}
														placeholder="Quantity"
														name="quantity"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-2">
											<div>
												<GrAddCircle onClick={(e) => VariantHandler(e)} />
											</div>
										</div> */}

                    {/* {selectedVariantData?.map((item, index) => {
											return (
												<AddVariation
													item={item}
													index={index}
													RemoveVariation={RemoveVariation}
												/>
											);
										})} */}

                    <hr />
                    <div className="col-md-6">
                      <div className="files_flex">
                        <div className="custom-file">
                          <label>Add Pictures</label>
                          <input
                            type="text"
                            placeholder="Upload pictures"
                            className="form-control"
                          />
                          <input
                            type="file"
                            className="custom-file-input"
                            id="attachments"
                            accept="image/*"
                            onChange={handleImageUpload}
                            multiple={true}
                          />
                        </div>
                        <ul id="fileList" className="multi-imglist">
                          {renderPhotos(multiImageFiles)}
                          {renderPhotosApi(multiImageFiles2)}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="files_flex">
                        <div className="custom-file">
                          <label>Add Thumbnail</label>
                          <input
                            type="text"
                            placeholder="Upload pictures"
                            className="form-control"
                          />
                          <input
                            type="file"
                            className="custom-file-input"
                            id="attachments"
                            accept="image/*"
                            onChange={HandleLogoUpload}
                            multiple={false}
                          />
                          <div className="mt-2">
                            {getlogo ? (
                              <img
                                src={logoFileupload ? logoFileupload : getlogo}
                                className="image-fluid image-width"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                                alt=""
                              />
                            ) : null}
                          </div>
                        </div>
                        <ul id="fileList-two" className="file-list"></ul>
                      </div>
                    </div>
                    <hr />
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="UnitPrice">Unit Price</label>
                        <InputField
                          type="number"
                          value={userData?.unitPrice}
                          onChange={handleChange}
                          placeholder="Unit Price"
                          name="unitPrice"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="PurchasePrice">Old Price</label>
                        <InputField
                          type="number"
                          value={userData?.purchasePrice}
                          onChange={handleChange}
                          placeholder="Old Price"
                          name="purchasePrice"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="quantity">Quantity</label>
                        <InputField
                          type="number"
                          value={stock}
                          // onChange={handleChange}
                          disabled={true}
                          placeholder="Quantity"
                          name="quantity"
                        />
                      </div>
                    </div>
                    <hr />

                    <div className="col-md-12">
                      <div className="text-editor">
                        <label>Product Details</label>
                        <textarea
                          name="description"
                          placeholder="Product Descriptions"
                          className="form-control"
                          value={userData?.description}
                          onChange={handleChange}
                          id=""
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="bottom_bar">
                        <div className="button-group">
                          <button
                            className="btn"
                            onClick={(e) => EditProductHandler(e)}
                            disabled={loading}
                          >
                            {loading ? <CircleLoad /> : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SellerFooter />
    </>
  );
};

export default SellerUpdateProduct;
