import React from 'react'
import { Modal } from 'reactstrap';
import CircleLoad from '../Loader/CircleLoad';

const FeedBackModal = (props) => {
    const {isOpenFeedbackModal, setIsOpenFeedbackModal, message, setMessage, feedLoad, SendFeedBack} = props
  return (
    <Modal
    isOpen={isOpenFeedbackModal}
    toggle={() => {
        setIsOpenFeedbackModal(false)
    }}
    className="custom-modal modal-width orderView-Modal"
  >
    <div
    id="staticBackdrop"
  >
    <div>
        <div className="modal-body text-center">
          <h4>Feedback</h4>
          <form action="">
            <div className="form-group">
              <textarea
                name=""
                placeholder="Please Enter your message"
                className="form-control"
                id=""
                cols="30"
                rows="5"
                value={message}
                onChange={(e)=>setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group mt-3">
              <button className="btn form-control"
              onClick={(e)=>SendFeedBack(e)}
              disabled={feedLoad}
              >
                { feedLoad ? <CircleLoad /> : "Send Feedback"}</button>
            </div>
          </form>
        </div>
    </div>
  </div>
  </Modal>

  
  )
}

export default FeedBackModal