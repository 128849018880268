import React, { useEffect, useState } from "react";
import "../../assets/css/Seller_Inventory.css";
import "../../assets/css/seller-orders.css";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { detail_order_img, product_inventory } from "../../constants";
import { useNavigate } from "react-router-dom";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { ChangeShippingStatus, GetSellerOrders } from "../../network/Network";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../components/NoRecord/NoRecord";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";

const Seller_orders = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [sellerOrders, setSellerOrders] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [search, setSearch] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const [pageCount, setPageCount] = useState();
  const [changeStatus, setChangeStatus] = useState(false);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const SellerOrderHandler = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    setSpinLoad(true);
    GetSellerOrders(currentPage, search, Token)
      .then((res) => {
        setSellerOrders(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setTotal(res?.data?.response?.data?.total);
        setSpinLoad(false);
        setSearch("");
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  };

  useEffect(() => {
    SellerOrderHandler(null);
  }, [currentPage, changeStatus]);

  const ShippingStatusHandler = (e, id) => {
    e.preventDefault();
    let data = {
      order_id: id,
      // order_id: id,
      status: parseInt(e.target?.value),
    };
    ChangeShippingStatus(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setChangeStatus(!changeStatus);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const ShippingData = [
    {
      id: 0,
      name: "Pending",
    },
    {
      id: 1,
      name: "Process",
    },
    {
      id: 2,
      name: "Shipped",
    },
    {
      id: 3,
      name: "Complete",
    },
    {
      id: 4,
      name: "Cancel",
    },
  ];

  return (
    <>
      <SellerHeader />

      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8 refunr-ore pus">
              <Seller_tabs_list />

              <div className="sellers_orders">
                <div className="heading_wrapper d-flex justify-content-between">
                  <h4>Orders</h4>
                  <div className="input-group">
                    <div className="form-outline">
                      <input
                        type="search"
                        placeholder="Search Orders"
                        id="form1"
                        className="form-control"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => SellerOrderHandler(e)}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                  <div className="add-upload-wrapper">
                    <button
                      className="add-products"
                      onClick={(e) => navigate("/seller-refund-order")}
                    >
                      Refund Orders
                    </button>
                  </div>
                </div>

                <div className="table-resposnive mt-4">
                  <table className="table">
                    <thead>
                      <tr className="topbar">
                        <th scope="col" className="Status">
                          Order ID
                        </th>
                        <th scope="col" className="billingname">
                          Billing Name
                        </th>
                        <th scope="col" className="amount">
                          Amount
                        </th>
                        <th scope="col" className="Price">
                          Item
                        </th>
                        <th scope="col" className="Stock">
                          Shipment Status
                        </th>
                        <th scope="col" className="Details">
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {spinLoad ? (
                        <tr>
                          {[0, 1, 2, 3]?.map((item, index) => {
                            return (
                              <div className="mt-3" key={index}>
                                <Skeleton count={5} />
                              </div>
                            );
                          })}
                        </tr>
                      ) : sellerOrders?.length > 0 ? (
                        <>
                          {sellerOrders?.map((item) => {
                            return (
                              <tr key={item?.id}>
                                <td className="orderid">#{item?.id}</td>
                                <td className="billingname">
                                  {item?.user?.name} {item?.user?.last_name}
                                </td>
                                <td className="amount">
                                  ${item?.sellerSubTotal}
                                </td>
                                <td className="details">
                                  {item?.order_details?.length}{" "}
                                </td>
                                <td className="Available">
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      ShippingStatusHandler(e, item?.id);
                                    }}
                                  >
                                    <option
                                      value={
                                        item?.order_details.length > 0
                                          ? item?.order_details[0]?.status
                                          : ""
                                      }
                                    >
                                      {item?.order_details.length > 0
                                        ? item?.order_details[0]?.status == 0
                                          ? "Pending"
                                          : item?.order_details[0]?.status == 1
                                          ? "Process"
                                          : item?.order_details[0]?.status == 2
                                          ? "Shipped"
                                          : item?.order_details[0]?.status == 3
                                          ? "Complete"
                                          : item?.order_details[0]?.status == 4
                                          ? "Cancel"
                                          : null
                                        : ""}
                                    </option>
                                    {ShippingData?.map((inner) => {
                                      return (
                                        <>
                                          {item?.order_details.length > 0 &&
                                          item?.order_details[0]?.status ==
                                            inner?.id ? null : (
                                            <option
                                              key={inner?.id}
                                              value={inner?.id}
                                            >
                                              {inner?.name}
                                            </option>
                                          )}
                                        </>
                                      );
                                    })}
                                  </select>
                                </td>
                                <td className="Detailsu">
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/seller-order-details/${item?.id}`
                                      )
                                    }
                                    className="btn details-btn"
                                  >
                                    View Details
                                  </button>
                                  {/* <button className="btn details-btn">
                                    Print Order
                                  </button> */}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <NoRecord />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="paginations_results">
                  <div className="showing_results">
                    <p>
                      Showing <b>{sellerOrders?.length}</b> of over {total}{" "}
                      <b>results</b>
                    </p>
                  </div>
                  <ul className="paginations">
                    <div className="pagination-container mt-5">
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_orders;
