import React, { useEffect, useState } from "react";
import "../../assets/css/seller-banner-ads.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { ad1 } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useSelector } from "react-redux";
import { GetSellerBannerList, PostDeleteBanner } from "../../network/Network";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../components/NoRecord/NoRecord";
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from "sweetalert";
import BannerCard from "../../components/Banner/BannerCard";

const Seller_banner_ad = () => {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [bannerData, setBannerData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const [textCopy, setTextCopy] = useState();
  const [copied, setCopied] = useState(false);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetSellerBannerList(currentPage, Token)
      .then((res) => {
        setBannerData(res?.data?.response?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);

  const DeleteBannerHandler = (e, id) => {
    e.preventDefault();
    setSpinLoad(true);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this banner!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let data;
        PostDeleteBanner(id, data, Token)
          .then((res) => {
            setSpinLoad(false);
            setBannerData((preval) => preval.filter((val) => val?.id != id));
          })
          .catch((err) => {
            setSpinLoad(false);
          });
      } else {
        swal("Your banner is safe!");
        setSpinLoad(false);
      }
    });
  };

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <Seller_tabs_list />
              {/* View banner ads starts here */}
              <div className="banner-ads">
                <div className="view-banner-ads-heading-wrapper">
                  <h2>View Banner Ads</h2>
                  <h4>( {bannerData?.length} )</h4>
                </div>

                {spinLoad ? (
                  <tr>
                    {[0, 1, 2, 3]?.map((item, index) => {
                      return (
                        <div className="mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })}
                  </tr>
                ) : bannerData?.length > 0 ? (
                  <>
                    {bannerData?.map((item) => {
                      return (
                        <BannerCard
                          item={item}
                          DeleteBannerHandler={DeleteBannerHandler}
                          setTextCopy={setTextCopy}
                          copied={copied}
                          setCopied={setCopied}
                        />
                      );
                    })}
                  </>
                ) : (
                  <NoRecord />
                )}

                <div className="pagination-container mt-5">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="upload-btn-wrapper">
                  <Link to="/banner-ad-form" className="btn">
                    Upload New Banner
                  </Link>
                </div>
              </div>
              {/* View banner ads ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_banner_ad;
