import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/policy.css";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useSelector } from "react-redux";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import { GetAdvertise } from "../../network/Network";
import Skeleton from "react-loading-skeleton";

const Advertise = () => {
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const [data, setData] = useState();
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetAdvertise()
      .then((res) => {
        setData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {userReduxData?.user_type == 2 ? <SellerHeader /> : <Header />}
      <section className="policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <div className="content-wrapper">
                  {spinLoad ? (
                    [0, 1, 2, 3]?.map((item, index) => {
                      return (
                        <div className="mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="heading-wrapper">
                        <h2>{data?.pageName}</h2>
                        <h6>{data?.pageTitle}</h6>
                      </div>
                      <div className="para-wrapper">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data?.content,
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Policy ends here */}
      {userReduxData?.user_type == 2 ? <SellerFooter /> : <Footer />}
    </>
  );
};

export default Advertise;
