import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/my-order-detail.css";
import { orderimg1 } from "../../constants";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { GetOrderDetail } from "../../network/Network";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

const MyorderDetail = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  let currentUrl = window.location.href.split("/");
  const [orderData, setOrderData] = useState();
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetOrderDetail(currentUrl[4], Token)
      .then((res) => {
        setOrderData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);

  const RefundHandler = (e, item) => {
    e.preventDefault();
    navigate(`/refund-order-form`, {
      state: { product_id: item?.product?.id, order_id: item?.id, item: item },
    });
  };

  return (
    <>
      <Header Header_Cate={false} />
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* return payment starts here */}
              <div className="order-request-wrapper">
                <div className="order-heading-wrapper">
                  <h3>My Orders Details</h3>
                </div>

                {spinLoad ? (
                  <>
                    {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                      return (
                        <div className="col-md-12 mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="order-detail-wrapper">
                    <div className="order-no-wrapper">
                      <h5>Order No #{orderData?.id}</h5>
                      <h6>
                        Date :{" "}
                        {moment(orderData?.created_at).format(
                          "MMMM Do YYYY, h:mm a"
                        )}{" "}
                      </h6>
                    </div>
                    <div className="order-cards-wrapper">
                      <div className="card-heading-wrapper">
                        <h5>Products</h5>
                      </div>
                      <div className="cards-wrapper">
                        {orderData?.order_details?.map((item) => {
                          return (
                            <div className="order-card-wrapper" key={item?.id}>
                              <div className="order-img-wrapper">
                                <figure>
                                  <img
                                    src={item?.product?.image_url}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="order-content-wrapper">
                                <div className="content-heading-wrapper">
                                  <h6>{item?.product?.title}</h6>
                                  {/* <span>Quantity {item?.qty}</span> */}
                                </div>
                                <div className="content-heading-wrapper">
                                  <h6>Quantity {item?.qty}</h6>
                                </div>
                                <div className="order-size-wrapper">
                                  <span className="size"> {item?.variant}</span>
                                  <h5 className="price">${item?.price}</h5>
                                </div>
                                <a
                                  onClick={(e) => RefundHandler(e, item)}
                                  className="btn"
                                >
                                  Refund
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="shipping-address-wrapper">
                      <div className="heading-wrapper">
                        <h5>Shipment Address</h5>
                      </div>
                      <div className="shipping-lists-wrapper">
                        <div className="single-ship-wrapper">
                          <ul>
                            <li>
                              <span className="property">Country:</span>
                              <span className="value">
                                {orderData?.address?.country}
                              </span>
                            </li>
                            <li>
                              <span className="property">Address: </span>
                              <span className="value">
                                {orderData?.address?.address},
                                {orderData?.address?.street}
                              </span>
                            </li>
                            <li>
                              <span className="property">State:</span>
                              <span className="value">
                                {orderData?.address?.state}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="single-ship-wrapper">
                          <ul>
                            <li>
                              <span className="property">Phone: </span>
                              <span className="value">
                                +{orderData?.address?.phone}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-wrapper">
                      <div className="invoice-heading-wrapper">
                        <h5>Invoice</h5>
                      </div>
                      <div className="invoice-list-wrapper">
                        <ul>
                          <li>
                            <span className="property">Item Total Price</span>
                            <span className="value">
                              $ {orderData?.sub_total}
                            </span>
                          </li>
                          <li>
                            <span className="property">Delivery Charges</span>
                            <span className="value">
                              ${orderData?.shipping}
                            </span>
                          </li>
                          <li>
                            <span className="property">Promo</span>
                            <span className="value">
                              - ${orderData?.discount_amount}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="total-price-wrapper">
                        <ul>
                          <li>
                            <span className="property">Total Price :</span>
                            <span className="value">
                              ${" "}
                              {orderData?.sub_total +
                                orderData?.shipping -
                                orderData?.discount_amount}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="back-btn-wrapper">
                      <Link to="/my-order" className="btn">
                        Back
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MyorderDetail;
