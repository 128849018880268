import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { review_img } from "../../constants";
import { GetProductRatingApi } from "../../network/Network";
import { ramdomImage } from "../../constants/ConstantFunction";
import { ImageUrl } from "../../network/ApiUrl";
import { Rating } from "react-simple-star-rating";

const ProductReviews = (props) => {
  const { submitReview, setSubmitReview } = props;
  let currentUrl = window.location.href.split("/");
  const [reviewSpin, setReviewSpin] = useState(false);
  const [reviewData, setReviewData] = useState([]);

  //   product Reviews
  useEffect(() => {
    setReviewSpin(true);
    GetProductRatingApi(currentUrl[4])
      .then((res) => {
        setReviewData(res?.data?.response?.data?.data);
        setReviewSpin(false);
      })
      .catch((err) => {
        setReviewSpin(false);
      });
  }, [submitReview]);
  return (
    <>
      {reviewData?.map((item, index) => {
        return (
          <>
            <div className="single-review-wrapper" key={index}>
              <div className="single-img-wrapper">
                <figure>
                  {item?.user?.image && item?.user?.image != null ? (
                    <img
                      src={`${ImageUrl}/${"uploads"}/${item?.user?.image}`}
                      className="image-fluid"
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${ramdomImage(
                        `${item?.user?.name}{""}${item?.user?.last_name}`
                      )}`}
                      className="image-fluid "
                      alt=""
                    />
                  )}
                </figure>
              </div>
              <div className="single-reviews-content-wrapper">
                <h5>
                  {item?.user?.name} {item?.user?.last_name}
                </h5>
                <h6>
                  {moment(item?.created_at).format("MMMM Do YYYY, h:mm a")}
                </h6>
                <div className="reviews-wrapper mb-3 mt-3">
                  <Rating
                    size={20}
                    readonly={true}
                    allowFraction={true}
                    initialValue={item?.rating}
                    // ratingValue={}
                  />
                </div>
                <p>{item?.comment}</p>
                {/* <button className="reply">Reply :</button> */}
              </div>

              {item?.feedback || item?.feedback != null ? (
                <div className="review-feedback">
                  <div className="single-img-wrapper">
                    <figure>
                      {item?.seller?.image && item?.seller?.image != null ? (
                        <img
                          src={`${ImageUrl}/${"uploads"}${"/seller"}/${
                            item?.seller?.image
                          }`}
                          className="image-fluid"
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${ramdomImage(
                            `${item?.seller?.name}{""}${item?.seller?.last_name}`
                          )}`}
                          className="image-fluid "
                          alt=""
                        />
                      )}
                    </figure>
                  </div>
                  <div className="single-reviews-content-wrapper">
                    <h5>
                      {item?.seller?.name} {item?.seller?.last_name}
                    </h5>
                    <p className="m-0">Seller</p>
                    {/* <h6>{moment(item?.created_at).format("MMMM Do YYYY, h:mm a")}</h6> */}
                    <p className="mt-2">{item?.feedback}</p>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        );
      })}
    </>
  );
};

export default ProductReviews;
