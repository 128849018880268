import React from 'react'
import { notFound } from '../../constants'

const NoRecord = () => {
  return (
    <div className="notFound-container">
                    <img
                      src={notFound}
                      alt="not found"
                      className="img-fluid"
                    />
                    <p className="mt-4">No Record Found</p>
                  </div>
  )
}

export default NoRecord