import React from "react";
import { useSelector } from "react-redux";
import { cat12 } from "../../../constants";
import Customer_profile from "./Customer_profile";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { ramdomImage } from "../../../constants/ConstantFunction";
import { ApiUrl, ImageUrl } from "../../../network/ApiUrl";

const Seller_sidebar = () => {
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  return (
    <>
      <div className="seller_sidebar">
        <div className="heading_wrapper">
          <h4>Seller Dashboard</h4>
        </div>
        <div className="seller_profile">
          <div className="img_box">
            <figure>
              {userReduxData?.image == null || !userReduxData?.image  ? (
                <img
                  src={`${ramdomImage(
                    `${userReduxData?.name}{""}${userReduxData?.last_name}`
                  )}`}
                  className="image-fluid image-width"
                  alt=""
                />
              ) : (
                <img
                  src={`${ImageUrl}/${"uploads"}/${"seller"}/${
                    userReduxData?.image
                  }`}
                  className="image-fluid image-width"
                  alt=""
                />
              )}
              {/* <img src={cat12} alt="" /> */}
            </figure>
          </div>
          <div className="profile_content">
            <div className="topbar">
              <h4>
                {userReduxData?.name} {userReduxData?.last_name}
              </h4>
              <ul className="stars">
                <Rating
                  size={20}
                  readonly={true}
                  allowFraction={true}
                  initialValue={5}
                  // ratingValue={}
                />
              </ul>
            </div>
            <div className="best-seller-wrapper">
              <h5>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.334"
                    height="19.203"
                    viewBox="0 0 15.334 19.203"
                  >
                    <g
                      id="Group_8002"
                      data-name="Group 8002"
                      transform="translate(-952.481 -1826)"
                    >
                      <g
                        id="badge-svgrepo-com_1_"
                        data-name="badge-svgrepo-com (1)"
                        transform="translate(903.241 1826)"
                      >
                        <g
                          id="Group_8003"
                          data-name="Group 8003"
                          transform="translate(49.24 0)"
                        >
                          <path
                            id="Path_16690"
                            data-name="Path 16690"
                            d="M64.378,8.692l-.236-.322a1.014,1.014,0,0,1-.008-1.19l.232-.326a1.023,1.023,0,0,0-.35-1.489l-.354-.189a1.018,1.018,0,0,1-.523-1.069l.067-.4a1.017,1.017,0,0,0-.963-1.187l-.4-.016a1.021,1.021,0,0,1-.935-.735l-.11-.385A1.018,1.018,0,0,0,59.416.736l-.369.157A1.022,1.022,0,0,1,57.883.638l-.267-.3a1.019,1.019,0,0,0-1.528.012l-.263.3a1.025,1.025,0,0,1-1.159.275L54.3.775a1.018,1.018,0,0,0-1.371.672l-.106.385a1.024,1.024,0,0,1-.923.75l-.4.024a1.018,1.018,0,0,0-.943,1.2l.071.393a1.022,1.022,0,0,1-.507,1.076l-.35.2a1.019,1.019,0,0,0-.33,1.493l.236.322a1.014,1.014,0,0,1,.008,1.19l-.232.326a1.023,1.023,0,0,0,.35,1.489l.354.189a1.018,1.018,0,0,1,.523,1.069l-.067.4a1.017,1.017,0,0,0,.963,1.187l.4.016a1.021,1.021,0,0,1,.935.735l.11.385a1.018,1.018,0,0,0,1.383.652l.369-.157a1.022,1.022,0,0,1,1.163.255l.267.3a1.019,1.019,0,0,0,1.528-.012l.263-.3a1.025,1.025,0,0,1,1.159-.275l.369.153a1.018,1.018,0,0,0,1.371-.672l.106-.385a1.024,1.024,0,0,1,.923-.75l.4-.024a1.018,1.018,0,0,0,.943-1.2l-.071-.393a1.022,1.022,0,0,1,.507-1.076l.35-.2A1.018,1.018,0,0,0,64.378,8.692ZM56.9,12.935a5.107,5.107,0,1,1,5.107-5.107A5.112,5.112,0,0,1,56.9,12.935Z"
                            transform="translate(-49.24 0)"
                            fill="#ed9521"
                          />
                          <path
                            id="Path_16691"
                            data-name="Path 16691"
                            d="M293.383,361.126l-.177.012-.086.314a2.148,2.148,0,0,1-2.888,1.418l-.3-.126-.094.11,1.458,2.652a.594.594,0,0,0,.409.295l1.756.326a.591.591,0,0,0,.656-.361l.664-1.658a.58.58,0,0,0-.031-.5Z"
                            transform="translate(-280.386 -346.938)"
                            fill="#ed9521"
                          />
                          <path
                            id="Path_16692"
                            data-name="Path 16692"
                            d="M76.558,364.819a2.107,2.107,0,0,1-.849.177,2.156,2.156,0,0,1-2.063-1.548l-.09-.31-.314-.012-1.32,2.4a.58.58,0,0,0-.031.5l.664,1.658a.591.591,0,0,0,.656.361l1.756-.326a.6.6,0,0,0,.409-.295l1.5-2.723-.012-.012Z"
                            transform="translate(-70.959 -348.86)"
                            fill="#ed9521"
                          />
                          <path
                            id="Path_16693"
                            data-name="Path 16693"
                            d="M162.831,103.637a.644.644,0,0,0-.086-.809c-.475-.436-1.768-.145-1.768-.145h0c-.09.016-.185.035-.287.059,0,0-.448.208,0-1.147s-.3-1.568-.613-1.568-.208.672-.208.672a7.28,7.28,0,0,1-1.312,2.342,1.45,1.45,0,0,0-.31.523.575.575,0,0,0-.373-.138h-.86a.574.574,0,0,0-.574.574v2.294a.574.574,0,0,0,.574.574h.86a.555.555,0,0,0,.318-.1.6.6,0,0,0,.134-.452V104.89c0,.055.008,1.768.994,1.874.354.024.79.043,1.226.035a4.293,4.293,0,0,0,1.155-.035c1.139-.244.856-1.037.856-1.037a.781.781,0,0,0,.267-1.041A.7.7,0,0,0,162.831,103.637Z"
                            transform="translate(-152.227 -96.096)"
                            fill="#ed9521"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>{" "}
                {userReduxData?.seller_info?.badge?.name} seller{" "}
              </h5>
              <Link to="/seller-account">View Profile</Link>
            </div>
            <div className="change-pass-link">
              <Link to="/seller-change-password">Change Password</Link>
            </div>
          </div>
        </div>
        <div className="sales_summary">
          <div className="title">
            <h3>Sales Summary</h3>
          </div>
          <div className="totals_sales">
            <div className="today-sale sales">
              <span>Today sales</span>
              <h3 className="price">
                $
                {userReduxData?.seller_info?.todaySales != 0 || null
                  ? userReduxData?.seller_info?.todaySales
                  : "0"}
              </h3>
            </div>
            <div className="week-sale sales">
              <span>Last 7 Days</span>
              <h3 className="price">
                $
                {userReduxData?.seller_info?.lastSevenDaysSales != 0 || null
                  ? userReduxData?.seller_info?.lastSevenDaysSales
                  : "0"}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seller_sidebar;
