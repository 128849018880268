import React, { useEffect, useState } from "react";
import "../../assets/css/seller-dashboard.css";
import "../../assets/css/seller-order-details.css";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { detail_order_img } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSellerRefundOrderDetail,
  GetSellerRefundOrderDetailTest,
} from "../../network/Network";
import moment from "moment";

const SellerOrderDetail2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [refundOrderData, setRefundOrderData] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  let currentUrl = window.location.href.split("/");

  useEffect(() => {
    setSpinLoad(true);
    GetSellerRefundOrderDetail(currentUrl[4], Token)
      .then((res) => {
        setRefundOrderData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <Seller_tabs_list />

              <div className="order_details" style={{ marginTop: "30px" }}>
                <div className="order-no">
                  <p className="m-0">
                    {" "}
                    Order #{refundOrderData?.order_detail?.order_id} was placed
                    on{" "}
                    {moment(refundOrderData?.order_detail?.updated_at).format(
                      "MMMM Do YYYY, h:mm a"
                    )}
                  </p>

                  <p className="m-0">Status: {" "}{refundOrderData?.status == 0
                                        ? "Pending"
                                        : refundOrderData?.status == 1
                                        ? "accepted"
                                        : refundOrderData?.status == 2
                                        ? "rejected"
                                        : refundOrderData?.status == 3
                                        ? "Product Received"
                                        : refundOrderData?.status == 4
                                        ? "voucher generate"
                                        : null}</p>
                </div>
                <div className="about-order">
                  <div className="title">
                    <h4>Order Detail</h4>
                  </div>
                  <div className="product detailed">
                    <div className="property">
                      <p>Product :</p>
                    </div>
                    <div className="value">
                      <img
                        src={refundOrderData?.order_detail?.product?.image_url}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="product-name detailed">
                    <div className="property">
                      <p>Product Name :</p>
                    </div>
                    <div className="value">
                      <p>{refundOrderData?.order_detail?.product?.title} </p>
                    </div>
                  </div>
                  <div className="subtotal detailed">
                    {/* <div className="property">
                      <p>Sub Total :</p>
                    </div>
                    <div className="value">
                      <p>$ 8.00</p>
                    </div> */}

                    <div className="property">
                      <p>Quantity :</p>
                    </div>
                    <div className="value">
                      <p>{refundOrderData?.order_detail?.qty}</p>
                    </div>
                  </div>

                  <div className="subtotal detailed">
                    <div className="property">
                      <p>Unit Price :</p>
                    </div>
                    <div className="value">
                      <p>{refundOrderData?.order_detail?.price}</p>
                    </div>
                  </div>

                  {/* <div className="shipping detailed">
                    <div className="property">
                      <p>Shipping</p>
                    </div>
                    <div className="value">
                      <p>$ 2</p>
                    </div>
                  </div> */}
                  <div className="payment-method detailed">
                    <div className="property">
                      <p>Payment method</p>
                    </div>
                    <div className="value">
                      <p>{refundOrderData?.order_detail?.order?.payment_method}</p>
                    </div>
                  </div>
                  <div className="net-total detailed">
                    <div className="property">
                      <p>Total</p>
                    </div>
                    <div className="value">
                      <p>$ {refundOrderData?.order_detail?.total}</p>
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  <a
                    onClick={() =>
                      navigate(`/seller-refund-form/${refundOrderData?.id}`)
                    }
                    className="print_order btn me-2"
                  >
                    Refund Details
                  </a>
                  <a
                    onClick={() => navigate(`/seller-refund-order`)}
                    className="print_order btn"
                  >
                    Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default SellerOrderDetail2;
