import React, { useState } from "react";
import "../../assets/css/accept.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { ad1 } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PostAddressApi, sellerAcceptRefund } from "../../network/Network";
import InputField from "../../components/Form/InputField";
import CircleLoad from "../../components/Loader/CircleLoad";

const Seller_accept_refund = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [GetData, setData] = useState({
    startVoucherDate: "",
    endVoucherDate: "",
    voucherCode: "",
    discountVoucher: "",
  });
  const [generateCode, setGenerateCode] = useState();

  let currentUrl = window.location.href.split("/");

  const generateVoucherCode = (e) => {
    e.preventDefault();
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 8; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    // return OTP;
    setGenerateCode(OTP);
    setData({
      startVoucherDate: GetData?.startVoucherDate,
      endVoucherDate: GetData?.endVoucherDate,
      voucherCode: OTP,
      discountVoucher: GetData?.discountVoucher,
    });
  };

  function handleChange(e) {
    const value = e.target.value;
    setData({
      ...GetData,
      [e.target.name]: value,
    });
  }

  const sellerAcceptRefundHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !GetData?.startVoucherDate ||
      !GetData?.endVoucherDate ||
      !GetData?.voucherCode ||
      !GetData?.discountVoucher
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (GetData?.discountVoucher < 0) {
      toast.error("Voucher amount must be greater than 0");
      setLoading(false);
      return;
    } else {
      let VoucherData = new FormData();
      VoucherData.append("id", currentUrl[4]);
      VoucherData.append("status", 1);
      VoucherData.append("start_date", GetData?.startVoucherDate);
      VoucherData.append("end_date", GetData?.endVoucherDate);
      VoucherData.append("code", GetData?.voucherCode);
      VoucherData.append("discount", GetData?.discountVoucher);
      sellerAcceptRefund(VoucherData, Token)
        .then((res) => {
          toast.success(res?.data?.message);
          setLoading(false);
          setData({
            startVoucherDate: "",
            endVoucherDate: "",
            voucherCode: "",
            discountVoucher: "",
          });
          Navigate("/seller-refund-order");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <Seller_tabs_list />
              {/* Accept refund form starts here */}
              <div className="accept-refund-wrapper">
                <button className="close">
                  <i className="fa fa-close"></i>
                </button>
                <div className="accept-form-wrapper">
                  <div className="accept-head-wrapper">
                    <h3>Accept Refund</h3>
                    {/* <div className="accept-date-wrapper">
                      <h6>Refund Request Date</h6>
                      <span className="date">10/11/22</span>
                    </div> */}
                  </div>
                  <div className="accept-schedule-wrapper">
                    <div className="schedule-head-wrapper">
                      <h4>Schedule Voucher Code</h4>
                    </div>
                    <div className="schedule-form-wrapper pt-2">
                      <form action="">
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label htmlFor="">Start Voucher Date</label>
                            {/* <input type="date" className="form-control" /> */}
                            <InputField
                              type="date"
                              value={GetData?.startVoucherDate}
                              onChange={handleChange}
                              name="startVoucherDate"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="">End Voucher Date</label>
                            {/* <input type="date" className="form-control" /> */}
                            <InputField
                              type="date"
                              value={GetData?.endVoucherDate}
                              onChange={handleChange}
                              name="endVoucherDate"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label htmlFor="Discount Voucher ">
                              Discount Voucher Code{" "}
                            </label>
                            <div className="generate-wrapper">
                              {/* <input
                                type="password"
                                className="form-control"
                                value={"password"}
                              /> */}
                              <InputField
                                type="text"
                                value={generateCode}
                                onChange={handleChange}
                                // placeholder=""
                                name="voucherCode"
                              />
                              <button
                                className="btn"
                                onClick={(e) => {
                                  generateVoucherCode(e);
                                }}
                              >
                                Generate Code
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="">Discount Voucher</label>
                            {/* <input
                              type="number"
                              placeholder="60"
                              className="form-control"
                            /> */}
                            <InputField
                              type="number"
                              value={GetData?.discountVoucher}
                              onChange={handleChange}
                              placeholder="50"
                              name="discountVoucher"
                            />
                          </div>
                        </div>
                        <div className="form-group pt-3">
                          <button
                            className="btn form-control"
                            onClick={(e) => sellerAcceptRefundHandler(e)}
                            disabled={loading}
                          >
                            {loading ? <CircleLoad /> : "Send Via Email"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* Accept refund form ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_accept_refund;
