import React from "react";
import InputField from "../Form/InputField";

const ProductVariantCombination = (props) => {
	const { item, index, variantData, variantDataHandler } = props;

	return (
		<>
			<div className="col-md-4" key={index}>
				<div className="files_flex">
					<div>
						<label>Variant</label>
						<InputField
							type="text"
							value={item?.type}
							placeholder="variant"
							name="variant"
							disabled={true}
						/>
					</div>
				</div>
			</div>
			<div className="col-md-3">
				<div className="files_flex">
					<div>
						<label>Price</label>
						<InputField
							type="number"
							// defaultValue={parseInt(item?.price)}
							value={parseInt(variantData[index]?.price)}
							onChange={(e) => variantDataHandler(e, index)}
							placeholder="Price"
							name="price"
						/>
					</div>
				</div>
			</div>
			<div className="col-md-3">
				<div className="files_flex">
					<div>
						<label>Quantity</label>
						<InputField
							type="number"
							// defaultValue={parseInt(item?.qty)}
							// value={variantData["qty"]}
							value={parseInt(variantData[index]?.qty)}
							onChange={(e) => variantDataHandler(e, index)}
							placeholder="Quantity"
							name="qty"
						/>
					</div>
				</div>
			</div>
			<div className="col-md-2">
				<div>
					<label>Sku</label>
					<InputField
						type="text"
						value={item?.type}
						onChange={(e) => variantDataHandler(e, index)}
						placeholder="sku"
						name="sku"
						disabled={true}
					/>
				</div>
			</div>
		</>
	);
};

export default ProductVariantCombination;
