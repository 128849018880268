import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/sign-in.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	forgotPassword,
	forgotPasswordOtpVerify,
} from "../../redux/actions/AuthActions";
import CircleLoad from "../../components/Loader/CircleLoad";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const OTP = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const Token = useSelector((state) => state.AuthReducer.token);
	const [getOTP, setOTP] = useState([]);
	const [getEmail, setEmail] = useState("");
	const [getPassword, setPassword] = useState("");
	const [getConfirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [getOTPBoxes, setOTPBoxes] = useState([1, 2, 3, 4]);
	const [isResendOTPDisabled, setResendOTPDisabled] = useState(false);
	const location = useLocation();

	const OTPHandler = (e, index) => {
		const newOTP = [...getOTP];
		newOTP[index] = e.target.value;
		setOTP(newOTP);
	};

	const resetPasswordHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (getOTP?.length < 1) {
			toast.error("please enter Your OTP");
			setLoading(false);
		}

		// if (!getPassword) {
		//   toast.error("please enter Your Password");
		//   setLoading(false);
		// }

		// if (!getConfirmPassword) {
		//   toast.error("please enter Your Confirm Password");
		//   setLoading(false);
		// }

		// if (getConfirmPassword?.length < 8 && getConfirmPassword?.length < 8) {
		//   toast.error("Both passwords must be at least 8 characters");
		//   setLoading(false);
		// }

		// if (getPassword != getConfirmPassword) {
		//   toast.error("Password and confirm password must be same");
		//   setLoading(false);
		// }
		else {
			// convert code to number
			const arrayToStringOtp = getOTP?.join("")?.toString();
			const stringToNumberOtp = parseInt(arrayToStringOtp);
			var data = new FormData();
			data.append("email", getEmail);
			data.append("code", stringToNumberOtp);
			setTimeout(async () => {
				setLoading(false);
				let send = await dispatch(
					forgotPasswordOtpVerify(data, navigate, setLoading),
				);
			}, 600);
		}
	};

	const resendOTPHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		let data = {
			email: location?.state?.email,
		};
		setTimeout(async () => {
			setLoading(false);
			let send = await dispatch(forgotPassword(data, setLoading));
		}, 600);

		setResendOTPDisabled(true);
		const timer = setTimeout(() => {
			setResendOTPDisabled(false);
			clearTimeout(timer);
		}, 5000);
	};

	useEffect(() => {
		setEmail(location?.state?.email);
	}, [location?.state?.email]);

	useEffect(() => {
		if (Token) {
			navigate("/");
		}
	}, []);

	return (
		<>
			<Header />

			<section className="sign-in">
				<div className="container">
					<div className="row ">
						<div className="col-lg-4">
							<div className="sign-in-form-wrapper">
								<div className="heading-wrapper">
									<h2>Enter OTP Code</h2>
									<p>
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy aliquyam erat
									</p>
								</div>
								<div className="sign-form-wrapper">
									<form action="/new-password">
										<div className="form-group">
											<label htmlFor="">OTP Code</label>
											<div className="otp-wrapper">
												{getOTPBoxes?.map((item, index) => {
													return (
														<input
															key={index}
															type="number"
															className="form-control"
															// value={getOTP}
															value={getOTP[index] || ""}
															maxlength="1"
															// onChange={(e) => OTPHandler(e)}
															onChange={(e) => OTPHandler(e, index)}
														/>
													);
												})}
											</div>
										</div>
										{/* <div className="form-group">
                      <label htmlFor="">Password</label>
                      <input
                        className="form-control"
                        type="Password"
                        placeholder="**********"
                        value={getPassword}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Confirm Password</label>
                      <input
                        className="form-control"
                        type="Password"
                        placeholder="**********"
                        value={getConfirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div> */}
										<div className="form-group">
											<button
												className="btn"
												type="submit"
												onClick={(e) => resetPasswordHandler(e)}
												disabled={loading}
											>
												{loading ? <CircleLoad /> : "Reset Password"}
												{/* OTP Verifiy */}
											</button>
										</div>
										<div className="form-group">
											<button
												className="btn-Resend"
												disabled={isResendOTPDisabled}
												onClick={(e) => resendOTPHandler(e)}
											>
												{isResendOTPDisabled
													? `You can resend the OTP after 50 seconds`
													: "Resend Code"}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-1"></div>
						<div className="col-lg-7 align-self-center"></div>
					</div>
				</div>
			</section>
			{/* sign in ends here */}
			{/* Footer starts here */}
			<Footer />
			{/* Footer ends here */}
		</>
	);
};

export default OTP;
