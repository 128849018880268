import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/sign-in.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/Form/InputField";
import CircleLoad from "../../components/Loader/CircleLoad";
import { toast } from "react-toastify";
import { signUp } from "../../redux/actions/AuthActions";

const Sign_up = () => {
	const dispatch = useDispatch();
	const Navigate = useNavigate();
	const Token = useSelector((state) => state.AuthReducer.token);
	const [btnLoading, setBtnLoading] = useState(false);
	const [userData, setUserData] = useState({
	  f_name: "",
	  l_name: "",
	  email: "",
	  phone: "",
	  password: "",
	  confirm_password: "",
	  referral_code: "",
	});
  
	function handleChange(e) {
	  const value = e.target.value;
	  setUserData({
		...userData,
		[e.target.name]: value,
	  });
	}
  
	const SignUpHandler = async (e) => {
	  e.preventDefault();
	  setBtnLoading(true);
	  if (
		!userData?.f_name ||
		!userData?.l_name ||
		!userData?.phone ||
		!userData?.email ||
		!userData?.password ||
		!userData?.confirm_password
	  ) {
		toast.error("Please Enter All Fields");
		setBtnLoading(false);
		return;
	  }
	  if (
		!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		  userData?.email
		)
	  ) {
		toast.error("Invalid Email");
		setBtnLoading(false);
		return;
	  }
  
	  if (userData?.phone?.length < 10 || userData?.phone?.length > 15) {
		toast.error("The phone no limit is min 10 and max 15 digits")
		setBtnLoading(false);
		return;
	  }
	  if (userData?.password != userData?.confirm_password) {
		toast.error("Password and confirm password must be same");
		setBtnLoading(false);
		return;
	  } else {
		var data = new FormData();
		data.append("first_name", userData?.f_name);
		data.append("last_name", userData?.l_name);
		data.append("email", userData?.email);
		data.append("phone", userData?.phone);
		data.append("password", userData?.password);
		data.append("confirm_password", userData?.confirm_password);
		data.append("user_type", 1);
		data.append("referral_code", userData?.referral_code);
		let send = await dispatch(signUp(data, Navigate, setBtnLoading));
	  }
	};

	useEffect(() => {
		if (Token) {
		  Navigate("/");
		}
	  }, []);


	return (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header ends here */}
			{/* sign in starts here */}
			<section className="sign-in">
				<div className="container">
					<div className="row ">
						<div className="col-lg-4">
							<div className="sign-in-form-wrapper">
								<div className="heading-wrapper">
									<h2>Sign Up As A Buyer</h2>
									<p>
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy aliquyam erat
									</p>
								</div>
								<div className="sign-form-wrapper">
									<form >
										<div className="form-group row">
											<div className="col-lg-6">
												<label htmlFor="">First Name</label>
												<InputField
													type="text" 
													value={userData?.f_name}
													onChange={handleChange}
													placeholder="First Name"
													name="f_name"
												/>
											</div>
											<div className="col-lg-6">
												<label htmlFor="">Last Name</label>
												<InputField 
													type="text"
													value={userData?.l_name}
													onChange={handleChange}
													placeholder="Last Name"
													name="l_name"
												/>
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="">Email Address</label>
											<InputField 
												type="text"
												value={userData?.email}
												onChange={handleChange}
												placeholder="John Doe@gmail.com"
												name="email"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Phone Number</label>
											<InputField 
												type="number"
												value={userData?.phone}
												onChange={handleChange}
												placeholder="Phone Number"
												name="phone"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Referral code (Optional)</label>
											<InputField 
												type="text"
												value={userData?.referral_code}
												onChange={handleChange}
												placeholder="Referral code"
												name="referral_code"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Password</label>
											<InputField 
											type="password"
											value={userData?.password}
											onChange={handleChange}
											placeholder="**********"
											name="password"
										/>
										</div>
										<div className="form-group">
											<label htmlFor="">Confirm Password</label>
											<InputField 
												type="password"
												value={userData?.confirm_password}
												onChange={handleChange}
												placeholder="**********"
												name="confirm_password"
											/>
										</div>
										<div className="form-group">
										<button className="btn" 
											onClick={(e)=>SignUpHandler(e)}
											disabled={btnLoading}
										>
										{btnLoading ? <CircleLoad /> : "Sign Up"} 
										</button>
										</div>
										<div className="form-group">
											<div className="sign-up-tag-line-wrapper">
												<p>
													Already Have An Account{"? "}
													<a href="/sign-in">( Sign In )</a>
												</p>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-1"></div>
						<div className="col-lg-7 align-self-center">
							<div className="join-seller-wrapper">
								<div className="join-content-wrapper">
									<h3>
										Join As A <span>APRICOT</span> Seller Program
									</h3>
									<p>
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy eirmod tempor invidunt ut labore et dolore magna
									</p>
									<a href="/sign-up-seller" className="btn">
										Become A Seller
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* sign in ends here */}
			{/* Footer starts here */}
			<Footer />
			{/* Footer ends here */}
		</>
	);
};

export default Sign_up;
