import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
	seller_profile_img,
	arrival1,
	arrival2,
	arrival3,
	arrival7,
	arrival8,
	arrival9,
	seller1,
	seller23,
	pro2,
	review_img,
} from "../../constants";
import "../../assets/css/seller-store.css";
import {
	GetAllProductsApi,
	PostAddWishList,
	GetSellerOrders,
	GetSellerProfile,
	GetSellerById,
} from "../../network/Network";
import { SellerProfile } from "../../redux/actions/AuthActions";
import NoRecord from "../../components/NoRecord/NoRecord";
import Skeleton from "react-loading-skeleton";
import { Rating } from "react-simple-star-rating";
import CircleLoad from "../../components/Loader/CircleLoad";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../network/ApiUrl";

const Seller_store = () => {
	const Token = useSelector((state) => state.AuthReducer.token);
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState();
	const [spinLoad, setSpinLoad] = useState(false);
	const [productsData, setProductsData] = useState([]);
	const [wishLoading, setWishLoading] = useState(false);
	const [sellerById, setSellerById] = useState();
	const [sellerReviews, setSellerReviews] = useState([]);

	let currentUrl = window.location.href.split("/");

	const AllProductWithFilter = () => {
		setSpinLoad(true);
		let data;
		data = {
			cate_id: " ",
			search: " ",
			hot: " ",
			feature: " ",
			discounted: " ",
			new_arrival: " ",
			vendor_id: currentUrl[4],
		};

		GetAllProductsApi(currentPage, data)
			.then((res) => {
				setProductsData(res?.data?.response?.data?.data);
				// const total = res?.data?.response?.data?.total;
				// const limit = res?.data?.response?.data?.per_page;
				// setPageCount(Math.ceil(total / limit));
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	};

	useEffect(() => {
		AllProductWithFilter();
	}, []);

	const AddWishListHandler = (e, id) => {
		e.preventDefault();
		setWishLoading(true);
		let data = {
			product_id: id,
		};
		PostAddWishList(data, Token)
			.then((res) => {
				setWishLoading(false);
				// setActive(!active);
				toast.success("Product Add to your Wishlist");
			})
			.catch((err) => {
				setWishLoading(false);
				toast.error(err?.response?.data?.message);
			});
	};

	useEffect(() => {
		setSpinLoad(true);
		GetSellerById(currentUrl[4])
			.then((res) => {
				setSellerById(res?.data?.response?.data);
				const reviewsLength =
					res?.data?.response?.data?.seller_info?.total_reviews;
				const TotalReviews = new Array(Math.trunc(reviewsLength)).fill(1);
				setSellerReviews(TotalReviews);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, [currentUrl[4]]);

	return (
		<>
			<Header />
			<section className="about_pageheader">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="pageheader-content-wrapper">
								<h2>Seller Store</h2>
								<h5>{sellerById?.name ?? sellerById?.name} ( Best Seller )</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About pageHeader ends here */}
			{/* Seller profile starts here */}
			<section className="seller-profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-5">
							<div className="profile-wrapper">
								<div className="profile-img-wrapper">
									<img
										src={`${ImageUrl}/${"uploads"}/${"seller"}/${
											sellerById?.image
										}`}
										className="img-fluid"
										alt=""
									/>
								</div>
								<div className="profile-content-wrapper">
									<h6>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15.334"
											height="19.203"
											viewBox="0 0 15.334 19.203"
										>
											<g
												id="badge-svgrepo-com_1_"
												data-name="badge-svgrepo-com (1)"
												transform="translate(-49.24 0)"
											>
												<g
													id="Group_8003"
													data-name="Group 8003"
													transform="translate(49.24 0)"
												>
													<path
														id="Path_16690"
														data-name="Path 16690"
														d="M64.378,8.692l-.236-.322a1.014,1.014,0,0,1-.008-1.19l.232-.326a1.023,1.023,0,0,0-.35-1.489l-.354-.189a1.018,1.018,0,0,1-.523-1.069l.067-.4a1.017,1.017,0,0,0-.963-1.187l-.4-.016a1.021,1.021,0,0,1-.935-.735l-.11-.385A1.018,1.018,0,0,0,59.416.736l-.369.157A1.022,1.022,0,0,1,57.883.638l-.267-.3a1.019,1.019,0,0,0-1.528.012l-.263.3a1.025,1.025,0,0,1-1.159.275L54.3.775a1.018,1.018,0,0,0-1.371.672l-.106.385a1.024,1.024,0,0,1-.923.75l-.4.024a1.018,1.018,0,0,0-.943,1.2l.071.393a1.022,1.022,0,0,1-.507,1.076l-.35.2a1.019,1.019,0,0,0-.33,1.493l.236.322a1.014,1.014,0,0,1,.008,1.19l-.232.326a1.023,1.023,0,0,0,.35,1.489l.354.189a1.018,1.018,0,0,1,.523,1.069l-.067.4a1.017,1.017,0,0,0,.963,1.187l.4.016a1.021,1.021,0,0,1,.935.735l.11.385a1.018,1.018,0,0,0,1.383.652l.369-.157a1.022,1.022,0,0,1,1.163.255l.267.3a1.019,1.019,0,0,0,1.528-.012l.263-.3a1.025,1.025,0,0,1,1.159-.275l.369.153a1.018,1.018,0,0,0,1.371-.672l.106-.385a1.024,1.024,0,0,1,.923-.75l.4-.024a1.018,1.018,0,0,0,.943-1.2l-.071-.393a1.022,1.022,0,0,1,.507-1.076l.35-.2A1.018,1.018,0,0,0,64.378,8.692ZM56.9,12.935a5.107,5.107,0,1,1,5.107-5.107A5.112,5.112,0,0,1,56.9,12.935Z"
														transform="translate(-49.24 0)"
														fill="#ed9521"
													/>
													<path
														id="Path_16691"
														data-name="Path 16691"
														d="M293.383,361.126l-.177.012-.086.314a2.148,2.148,0,0,1-2.888,1.418l-.3-.126-.094.11,1.458,2.652a.594.594,0,0,0,.409.295l1.756.326a.591.591,0,0,0,.656-.361l.664-1.658a.58.58,0,0,0-.031-.5Z"
														transform="translate(-280.386 -346.938)"
														fill="#ed9521"
													/>
													<path
														id="Path_16692"
														data-name="Path 16692"
														d="M76.558,364.819a2.107,2.107,0,0,1-.849.177,2.156,2.156,0,0,1-2.063-1.548l-.09-.31-.314-.012-1.32,2.4a.58.58,0,0,0-.031.5l.664,1.658a.591.591,0,0,0,.656.361l1.756-.326a.6.6,0,0,0,.409-.295l1.5-2.723-.012-.012Z"
														transform="translate(-70.959 -348.86)"
														fill="#ed9521"
													/>
													<path
														id="Path_16693"
														data-name="Path 16693"
														d="M162.831,103.637a.644.644,0,0,0-.086-.809c-.475-.436-1.768-.145-1.768-.145h0c-.09.016-.185.035-.287.059,0,0-.448.208,0-1.147s-.3-1.568-.613-1.568-.208.672-.208.672a7.28,7.28,0,0,1-1.312,2.342,1.45,1.45,0,0,0-.31.523.575.575,0,0,0-.373-.138h-.86a.574.574,0,0,0-.574.574v2.294a.574.574,0,0,0,.574.574h.86a.555.555,0,0,0,.318-.1.6.6,0,0,0,.134-.452V104.89c0,.055.008,1.768.994,1.874.354.024.79.043,1.226.035a4.293,4.293,0,0,0,1.155-.035c1.139-.244.856-1.037.856-1.037a.781.781,0,0,0,.267-1.041A.7.7,0,0,0,162.831,103.637Z"
														transform="translate(-152.227 -96.096)"
														fill="#ed9521"
													/>
												</g>
											</g>
										</svg>
										<span>
											{sellerById?.seller_info?.badge?.name ??
												sellerById?.seller_info?.badge?.name}
										</span>
									</h6>
									<h3>{sellerById?.name ?? sellerById?.name}</h3>
									<h4>{sellerById?.seller_info?.shop_name}</h4>
									<div className="rating-wrapper">
										<ul>
											{/* {sellerReviews.length > 0 &&
												sellerReviews.map((review) => {
													return (
														<li>
															<i className="fa fa-star"></i>
														</li>
													);
												})} */}

											{/* <li>
												<span className="total-rating">
													{sellerById?.seller_info?.total}
												</span>
											</li> */}
											
											
											<li>
												Reviews
												<span className="total-reviews">
													{`(${
														sellerById?.reviews > 0 ? sellerById?.reviews : 0
													})`}
												</span>
											</li>
											<li>
												Products
												<span className="total-rating">
												{`(${
														sellerById?.seller_info?.total_products
													})`}
												
												</span>
											</li> 
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-1 right-border"></div>
						<div className="col-lg-1"></div>
						<div className="col-lg-5">
							<div className="seller-content-wrapper">
								<h2>About Our Seller</h2>
								<p>{sellerById?.email}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Seller profile ends here */}
			{/* products starts here */}
			<section className="product-sec store-seller">
				<div className="container">
					<div className="row">
						{spinLoad ? (
							<>
								{[0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12]?.map((item, index) => {
									return (
										<div className="col-lg-3 mt-3" key={index}>
											<Skeleton count={5} />
										</div>
									);
								})}
							</>
						) : productsData?.length > 0 ? (
							<>
								{productsData?.map((item) => {
									return (
										<div className="col-lg-3 px-0" key={item?.id}>
											<Link to={`${"/product"}/${item?.id}`}>
												<div className="product-card-wrapper">
													<div className="product-img-wrapper">
														<figure>
															<img
																src={item?.image_url}
																className="img-fluid"
																alt=""
															/>
														</figure>
													</div>
													<div className="product-content-wrapper">
														<h5>{item?.title}</h5>
														<span className="ice">{item?.category?.name} </span>
														<div className="rating-wrapper mb-2">
															<Rating
																size={20}
																readonly={true}
																allowFraction={true}
																initialValue={item?.average_rating}
															/>
															<p className="rate-value">
																{parseFloat(
																	item?.average_rating
																		? item?.average_rating
																		: 0,
																).toFixed(1)}
															</p>
														</div>
														{/* <h6>5kg</h6> */}
														<h4>${item?.latest_price}</h4>

														{/* <div className="counter-total-wrapper">
                        <input type="number" />
                        <h6>
                          Total: <span>$38.5</span>
                        </h6>
                      </div> */}
														<a
															onClick={(e) => AddWishListHandler(e, item?.id)}
															className="btn"
															disabled={wishLoading}
														>
															{wishLoading ? <CircleLoad /> : "Add To Wishlist"}
														</a>
													</div>
												</div>
											</Link>
										</div>
									);
								})}
							</>
						) : (
							<NoRecord />
						)}
					</div>
				</div>
			</section>
			{/* products ends here */}
			{/* Footer starts here */}
			<Footer />
			{/* Footer ends here */}
		</>
	);
};

export default Seller_store;
