
export const ApiUrl =
"https://admin-apriccot.developer-ourbase-camp.com/api" ||
"https://localhost:4000";

export const ImageUrl =
"https://admin-apriccot.developer-ourbase-camp.com" ||
"https://localhost:4000";

// export const ApiUrl =
// "https://7a29-39-50-165-111.ngrok-free.app/api"








// export const ApiUrl =
// "http://http://192.168.18.140/franchise_velocity/public/api/v1" ||
// "http://localhost:4000";



