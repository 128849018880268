import React from "react";

const CheckoutProductList = (props) => {
	const { CheckOutData, subTotal, couponData, shippingFees } = props;

	return (
		<div className="cart-product-detail">
			<div className="title">
				<h3>Products Details</h3>
			</div>
			<div className="checkout-products">
				{CheckOutData?.CartData?.map((item, index) => {
					return (
						<div className="products br-right" key={index}>
							<div className="abt-product">
								<div className="img-box">
									<figure>
										<img src={item?.productitem?.image_url} alt="123" />
									</figure>
								</div>
								<div className="pro-dtl">
									<h5 className="name">{item?.productitem?.title}</h5>
									<ul className="variations">
										<li>Variant {item?.variant_name}</li>
									</ul>
								</div>
							</div>
							<div className="product-price">
								<h4 className="price">{item?.qty} x</h4>
							</div>
							<div className="product-price">
								<h4 className="price">${item?.price}</h4>
							</div>
						</div>
					);
				})}

				{/* <div className="products">
            <div className="abt-product">
                <div className="img-box">
                    <figure>
                        <img src={check2} alt="123" />
                    </figure>
                </div>
                <div className="pro-dtl">
                    <h5 className="name">JSC FASHION WOMEN'S BAG</h5>
                    <ul className="variations">
                        <li> X3 | 2XL | Sliver</li>
                    </ul>
                </div>
            </div>
            <div className="product-price">
                <h4 className="price">$539.00</h4>
            </div>
        </div> */}
			</div>
			<div className="pricing-details">
				<div className="item-total">
					<h4 className="property">Item Total :</h4>
					<h4 className="value">${subTotal}</h4>
				</div>
				<div className="item-total">
					<h4 className="property">Shipment & Delivery :</h4>
					<h4 className="value">${shippingFees}</h4>
				</div>
				<div className="item-total">
					<h4 className="property">Promo Code Applied :</h4>
					<h4 className="value">
						${couponData?.value ? couponData?.value : 0}
					</h4>
				</div>
			</div>

			<div className="order-total">
				<h3 className="property">Order Total</h3>
				<h3 className="value">
					${" "}
					{couponData || shippingFees
						? parseInt(subTotal) -
						  parseInt(couponData?.value ? couponData?.value : 0) +
						  shippingFees
						: subTotal}
				</h3>
			</div>
		</div>
	);
};

export default CheckoutProductList;
