import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	SIGNUP_REQUEST,
	SIGNUP_SUCCESS,
	SIGNUP_ERROR,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_ERROR,
	SET_PASSWORD_REQUEST,
	SET_PASSWORD_SUCCESS,
	SET_PASSWORD_ERROR,
	// GET_ME_SUCCESS,
	// LOGOUT_REQUEST,
	// LOGOUT_SUCCESS,
	// LOGOUT_ERROR,
	LOGOUT,
	USER_TOKEN,
	CATEGORIES,
	SUB_CATEGORIES,
	GET_SELLER_PROFILE,
	URLS,
	BRANDS,
	SITE_SETTINGS,
	EDIT_PROFILE,
	USER_ADDRESS,
	DONATE_CAMPAIGN,
	SOCIAL_LINKS,
	STEP_DATA,
} from "../Types";
import {
	forgotPasswordApi,
	forgotPasswordOtp,
	postSignIn,
	postUserSignUp,
	ResetPasswordApi,
	VerifyOtpApi,
} from "../../network/Network";
import { toast } from "react-toastify";
import { setError } from "../actions/AlertAction";
import { responses, responsesLogin } from "../../constants/ConstantFunction";

export const signUp =
	(data, Navigate, setBtnLoading, setIsOpenPaymentLinkModal, setModalData) =>
	(dispatch) => {
		new Promise(async (resolve, reject) => {
			dispatch({ type: SIGNUP_REQUEST });
			postUserSignUp(data)
				.then((res) => {
					setBtnLoading(false);
					if (res?.data?.response?.data?.stripe_account_link?.url) {
						setIsOpenPaymentLinkModal(true);
						setModalData(res?.data?.response?.data);
					} else {
						Navigate("/sign-in");
					}
					// Navigate('/sign-in', { state: { data: res?.data?.response?.data } })
					dispatch(setError(responses(res)));
					dispatch({ type: SIGNUP_SUCCESS, payload: res?.data?.data });
					toast.success("Successfully Signup");
					dispatch(setError("success", "Successfully Signup!!!"));
					return resolve(true);
				})
				.catch((err) => {
					setBtnLoading(false);
					if (err?.response?.data?.errors[0]?.message) {
						toast.error(err?.response?.data?.errors[0]?.message);
					}
					if (err?.response?.data?.errors?.password[0]) {
						toast.error(err?.response?.data?.errors?.password[0]);
					}
					if (
						err?.response?.data?.statusCode == "400" &&
						err?.response?.data?.errors?.zip[0]
					) {
						toast.error(err?.response?.data?.errors?.zip[0]);
					}
					if (
						err?.response?.data?.statusCode == "400" &&
						err?.response?.data?.errors?.password[0]
					) {
						toast.error(err?.response?.data?.errors?.password[0]);
					}
					// dispatch(setError(responses(err)));
					// toast.error(err?.response?.data?.errors[0]?.message);
					return reject(false);
				})
				.finally(() => {
					dispatch({ type: SIGNUP_ERROR });
				});
		});
	};

export const login =
	(data, Navigate, setLoading, cb = () => {}) =>
	(dispatch) =>
		new Promise(async (resolve, reject) => {
			dispatch({ type: LOGIN_REQUEST });
			postSignIn(data)
				.then((res) => {
					setLoading(false);
					dispatch({
						type: LOGIN_SUCCESS,
						payload: res?.data?.response?.data?.user,
					});
					dispatch({
						type: USER_TOKEN,
						payload: res?.data?.response?.data?.accessToken,
					});
					if (res?.data?.response?.data?.user?.user_type == 1) {
						Navigate("/my-profile");
					} else {
						Navigate("/seller-dashboard");
					}
					// dispatch(setError(responsesLogin(res)));
					return resolve(true);
				})
				.catch((err) => {
					if (err?.response?.data?.errors[0]) {
						toast.error(err?.response?.data?.errors[0]?.message);
					}
					if (err?.response?.data?.errors) {
						toast.error(err?.response?.data?.errors);
					}
					setLoading(false);
					// dispatch(setError(responses(err)));
					// toast.error(err?.response?.data?.error);
					dispatch({ type: LOGIN_ERROR });
					return reject(false);
				});
		});

export const logout = (payload) => {
	return {
		type: LOGOUT,
		payload,
	};
};

export const WebSiteSettings = (payload) => {
	return {
		type: SITE_SETTINGS,
		payload,
	};
};

export const AllCategoriesData = (payload) => {
	return {
		type: CATEGORIES,
		payload,
	};
};

export const SubCategoriesData = (payload) => {
	return {
		type: SUB_CATEGORIES,
		payload,
	};
};

export const AllURls = (payload) => {
	return {
		type: URLS,
		payload,
	};
};

export const AllBrands = (payload) => {
	return {
		type: BRANDS,
		payload,
	};
};

export const EditProfileData = (payload) => {
	return {
		type: EDIT_PROFILE,
		payload,
	};
};

export const SellerProfile = (payload) => {
	return {
		type: GET_SELLER_PROFILE,
		payload,
	};
};

export const AllAddress = (payload) => {
	return {
		type: USER_ADDRESS,
		payload,
	};
};

export const AllDonationCampaign = (payload) => {
	return {
		type: DONATE_CAMPAIGN,
		payload,
	};
};

export const AllSocialLinks = (payload) => {
	return {
		type: SOCIAL_LINKS,
		payload,
	};
};

export const WorkStepData = (payload) => {
	return {
		type: STEP_DATA,
		payload,
	};
};

// hoho farm end

export const SetResetPassword = (data, Navigate) => (dispatch) => {
	new Promise(async (resolve, reject) => {
		dispatch({ type: SET_PASSWORD_REQUEST });
		ResetPasswordApi(data)
			.then((res) => {
				toast.success("Your password has been reset");
				dispatch({ type: SET_PASSWORD_SUCCESS });
				Navigate("/sign-in");
				return resolve(true);
			})
			.catch((err) => {
				toast.error(err?.response?.data?.message);
				dispatch({ type: SET_PASSWORD_ERROR });
				return reject(false);
			});
	});
};

// Franchise

export const forgotPassword =
	(data, navigate, setLoading, param) => (dispatch) => {
		new Promise(async (resolve, reject) => {
			dispatch({ type: FORGOT_PASSWORD_REQUEST });
			forgotPasswordApi(data)
				.then((res) => {
					dispatch(setError(responses(res)));
					dispatch({ type: FORGOT_PASSWORD_SUCCESS });
					toast.success(res?.data?.message);
					setLoading(false);
					navigate("/otp-code", { state: { email: param } });
					// navigate("/otp-code");
					return resolve(true);
				})
				.catch((err) => {
					dispatch(setError(responses(err)));
					toast.error(err?.response?.data?.message);
					dispatch({ type: FORGOT_PASSWORD_ERROR });
					return reject(false);
				});
		});
	};

export const forgotPasswordOtpVerify =
	(data, Navigate, setLoading, param) => (dispatch) => {
		new Promise(async (resolve, reject) => {
			dispatch({ type: FORGOT_PASSWORD_REQUEST });
			VerifyOtpApi(data)
				.then((res) => {
					dispatch(setError(responses(res)));
					dispatch({ type: FORGOT_PASSWORD_SUCCESS });
					toast.info(res?.data?.message);
					setLoading(false);
					if (res?.data?.statusCode === 200) {
						Navigate("/new-password");
						// Navigate("/login", { state: param });
					}

					// toast.success("Email has been sent to your email address");
					return resolve(true);
				})
				.catch((err) => {
					toast.error(err?.response?.data?.message);
					dispatch(setError(responses(err)));
					dispatch({ type: FORGOT_PASSWORD_ERROR });
					return reject(false);
				});
		});
	};
