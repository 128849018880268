import React, { useEffect, useState } from "react";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/MyAddresses.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  EditAddress,
  GetAllAddress,
  PostAddressApi,
  PostDeleteAddress,
  PostMakeDefaultAddress,
} from "../../network/Network";
import { toast } from "react-toastify";
import CircleLoad from "../../components/Loader/CircleLoad";
import InputField from "../../components/Form/InputField";
import { AllAddress } from "../../redux/actions/AuthActions";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../components/NoRecord/NoRecord";
import swal from "sweetalert";

const MyAddresses = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postAddress, setPostAddress] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [userAddress, setUserAddress] = useState([]);
  const [editAddress, setEditAddress] = useState(false);
  const [selectedAddress, setSelectedPostAddress] = useState();
  const [userData, setUserData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    street: "",
    address: "",
  });

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  }

  useEffect(() => {
    setSpinLoad(true);
    GetAllAddress(Token)
      .then((res) => {
        dispatch(AllAddress(res?.data?.response?.data));
        setUserAddress(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [postAddress]);

  const AddAddressHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    // if (
    //   !userData?.f_name ||
    //   !userData?.l_name ||
    //   !userData?.email ||
    //   !userData?.phone ||
    //   !userData?.country ||
    //   !userData?.state ||
    //   !userData?.city ||
    //   !userData?.zipCode ||
    //   !userData?.street ||
    //   !userData?.address
    // ) {
    //   toast.error("Please Enter All Fields");
    //   setLoading(false);
    //   return;
    // }
    if (!userData?.f_name) {
      toast.error("First name is required");
      setLoading(false);
      return;
    }
    if (!userData?.l_name) {
      toast.error("Last name is required");
      setLoading(false);
      return;
    }
    if (!userData?.email) {
      toast.error("Email is required");
      setLoading(false);
      return;
    }
    if (!userData?.phone) {
      toast.error("Phone is required");
      setLoading(false);
      return;
    }
    if (!userData?.country) {
      toast.error("Country is required");
      setLoading(false);
      return;
    }
    if (!userData?.city) {
      toast.error("City is required");
      setLoading(false);
      return;
    }
    if (!userData?.state) {
      toast.error("State is required");
      setLoading(false);
      return;
    }
    if (!userData?.zipCode) {
      toast.error("Zipcode is required");
      setLoading(false);
      return;
    }
    if (!userData?.street) {
      toast.error("Street is required");
      setLoading(false);
      return;
    }
    if (!userData?.address) {
      toast.error("Address is required");
      setLoading(false);
      return;
    }
    if (userData?.f_name?.length < 3 || userData?.f_name?.length > 25) {
      toast.error("First name must be between 3 to 25 characters");
      setLoading(false);
      return;
    }
    if (userData?.l_name?.length < 3 || userData?.l_name?.length > 25) {
      toast.error("Last name must be between 3 to 25 characters");
      setLoading(false);
      return;
    }
    // if (
    //   !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //     userData?.email
    //   )
    // ) {
    //   toast.error("Invalid Email");
    //   setLoading(false);
    //   return;
    // }

    if (
      !/^[a-z0-9._%+-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z0-9\-]+\.)+[a-z]{2,}))$/.test(
        userData?.email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }

    if (userData?.phone?.length < 8 || userData?.phone?.length > 17) {
      toast.error("Phone no limit is min 8 and max 17 digits");
      setLoading(false);
      return;
    }
    let data = new FormData();
    data.append("first_name", userData?.f_name);
    data.append("last_name", userData?.l_name);
    data.append("email", userData?.email);
    data.append("phone", userData?.phone);
    data.append("country", userData?.country);
    data.append("state", userData?.state);
    data.append("city", userData?.city);
    data.append("post_code", userData?.zipCode);
    data.append("street", userData?.street);
    data.append("address", userData?.address);
    PostAddressApi(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setPostAddress(res?.data?.response?.data);
        setLoading(false);
        setUserData({
          f_name: "",
          l_name: "",
          email: "",
          phone: "",
          country: "",
          state: "",
          city: "",
          zipCode: "",
          street: "",
          address: "",
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const DeleteAddressMethod = (id, index) => {
    setSpinLoad(true);
    let data = { id: id };
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Address!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        PostDeleteAddress(data, Token)
          .then((res) => {
            setUserAddress((preVal) => {
              return [...preVal.filter((val) => val?.id !== id)];
            });
            swal(res?.data?.message, {
              icon: "success",
            });
            setSpinLoad(false);
          })
          .catch((err) => {
            setSpinLoad(false);
          });
      } else {
        swal("Your Address is safe!");
        setSpinLoad(false);
      }
    });
  };

  const MakeDefault_Handler = (e, id) => {
    e.preventDefault();
    let data = {
      id: id,
    };
    PostMakeDefaultAddress(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setPostAddress(res?.data);
      })
      .catch((err) => {});
  };

  const StoreAddressForUpdate = (e, item) => {
    e.preventDefault();
    setEditAddress(true);
    setUserData({
      f_name: item?.first_name,
      l_name: item?.last_name,
      email: item?.email,
      phone: item?.phone,
      country: item?.country,
      state: item?.state,
      city: item?.city,
      zipCode: item?.post_code,
      street: item?.street,
      address: item?.address,
    });
    setSelectedPostAddress(item);
  };

  const EditAddressHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !userData?.f_name ||
      !userData?.l_name ||
      !userData?.email ||
      !userData?.phone ||
      !userData?.country ||
      !userData?.state ||
      !userData?.city ||
      !userData?.zipCode ||
      !userData?.street ||
      !userData?.address
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userData?.email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (userData?.phone?.length < 8 || userData?.phone?.length > 17) {
      toast.error("Phone no limit is min 8 and max 17 digits");
      setLoading(false);
      return;
    }
    let data = new FormData();
    data.append("first_name", userData?.f_name);
    data.append("last_name", userData?.l_name);
    data.append("email", userData?.email);
    data.append("phone", userData?.phone);
    data.append("country", userData?.country);
    data.append("state", userData?.state);
    data.append("city", userData?.city);
    data.append("post_code", userData?.zipCode);
    data.append("street", userData?.street);
    data.append("address", userData?.address);
    data.append("id", selectedAddress?.id);
    EditAddress(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        setPostAddress(res?.data);
        setEditAddress(false);
        setLoading(false);
        setSelectedPostAddress("");
        setUserData({
          f_name: "",
          l_name: "",
          email: "",
          phone: "",
          country: "",
          state: "",
          city: "",
          zipCode: "",
          street: "",
          address: "",
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Header Header_Cate={false} />
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-7">
              <Logout />
              <div className="address-form-wrapper">
                <div className="heading">
                  <h5>Addresses</h5>
                </div>
                <div className="form-wrapper">
                  <form action="">
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <label htmlFor="">First Name</label>
                        <InputField
                          type="text"
                          value={userData?.f_name}
                          onChange={handleChange}
                          placeholder=""
                          name="f_name"
                        />
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="">Last Name</label>
                        <InputField
                          type="text"
                          value={userData?.l_name}
                          onChange={handleChange}
                          placeholder=""
                          name="l_name"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <label htmlFor="">Phone</label>
                        <InputField
                          type="number"
                          value={userData?.phone}
                          onChange={handleChange}
                          placeholder=""
                          name="phone"
                        />
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="">Email</label>
                        <InputField
                          type="text"
                          value={userData?.email}
                          onChange={handleChange}
                          placeholder=""
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <label htmlFor="">Address</label>

                        <textarea
                          value={userData?.address}
                          onChange={handleChange}
                          name="address"
                          className="form-control"
                          id=""
                          cols="30"
                          rows="5"
                        ></textarea>
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="">Suit, Apartment, etc</label>

                        <textarea
                          value={userData?.street}
                          onChange={handleChange}
                          name="street"
                          className="form-control"
                          id=""
                          cols="30"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="">City</label>
                        <InputField
                          type="text"
                          value={userData?.city}
                          onChange={handleChange}
                          placeholder=""
                          name="city"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="">State</label>
                        <InputField
                          type="text"
                          value={userData?.state}
                          onChange={handleChange}
                          placeholder=""
                          name="state"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="">Country/Region</label>
                        <InputField
                          type="text"
                          value={userData?.country}
                          onChange={handleChange}
                          placeholder=""
                          name="country"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="">Zip Code</label>
                        <InputField
                          type="number"
                          value={userData?.zipCode}
                          onChange={handleChange}
                          placeholder=""
                          name="zipCode"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      {selectedAddress ? (
                        <button
                          className="btn"
                          onClick={(e) => EditAddressHandler(e)}
                          disabled={loading}
                        >
                          {loading ? <CircleLoad /> : "Update Address"}
                        </button>
                      ) : (
                        <button
                          className="btn"
                          onClick={(e) => AddAddressHandler(e)}
                          disabled={loading}
                        >
                          {loading ? <CircleLoad /> : "Add Address"}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div className="save-address">
                <div className="heading">
                  <h3>Saved Address</h3>
                </div>
                {/* <!-- Saved Address --> */}

                {spinLoad ? (
                  <>
                    {[0, 1, 2, 3, 4]?.map((item, index) => {
                      return (
                        <div className="col-md-12 mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })}
                  </>
                ) : userAddress?.length > 0 ? (
                  <>
                    {userAddress?.map((item, index) => {
                      return (
                        <label className="container" key={index}>
                          <input
                            type="radio"
                            name="radio"
                            checked={item?.is_default == 1 ? true : false}
                          />
                          <span className="checkmark">
                            <div className="select-address">
                              <div className="address-detail">
                                <div className="actions">
                                  <a
                                    className="editAddress edit"
                                    onClick={(e) =>
                                      StoreAddressForUpdate(e, item)
                                    }
                                  >
                                    <i className="fa-regular fa-pen-to-square"></i>
                                  </a>
                                  <a
                                    onClick={(e) =>
                                      DeleteAddressMethod(item?.id, index)
                                    }
                                  >
                                    <i className="fa-solid fa-trash-can"></i>
                                  </a>
                                </div>
                                <h5>
                                  <span className="property">Country:</span>
                                  <div className="spacer"></div>
                                  <span className="value">{item?.country}</span>
                                </h5>
                                <h5>
                                  <span className="property">City:</span>
                                  <div className="spacer"></div>
                                  <span className="value">{item?.city}</span>
                                </h5>
                                <h5>
                                  <span className="property">Address:</span>
                                  <div className="spacer"></div>
                                  <span className="value">
                                    {item?.address},{item?.street}
                                  </span>
                                </h5>
                                <h5>
                                  <span className="property">Phone:</span>
                                  <div className="spacer"></div>
                                  <span className="value">{item?.phone}</span>
                                </h5>
                                <h5>
                                  <span className="property">State:</span>
                                  <div className="spacer"></div>
                                  <span className="value">{item?.state}</span>
                                </h5>
                                <div
                                  className="deafult"
                                  onClick={(e) =>
                                    MakeDefault_Handler(e, item?.id)
                                  }
                                >
                                  <h5>Use as default</h5>
                                  <span className="dot"></span>
                                </div>
                              </div>
                            </div>
                          </span>
                        </label>
                      );
                    })}
                  </>
                ) : (
                  <NoRecord />
                )}
                {/* <!-- Saved Address --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* My adresses ends here */}
      <Footer />
    </>
  );
};

export default MyAddresses;
