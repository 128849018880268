import React from 'react'

const InputField = ({name,value,onChange, placeholder,type,disabled}) => {
  return (
    <input 
  			className="form-control" 
			type={type} 
			placeholder={placeholder} 
			onChange={onChange}
			value={value}
			name={name}
			disabled={disabled}
		/>
  )
}

export default InputField