import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/sign-in.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SetResetPassword } from "../../redux/actions/AuthActions";
import CircleLoad from "../../components/Loader/CircleLoad";

const New_password = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const Token = useSelector((state) => state.AuthReducer.token);
	const [email, setEmail] = useState("");
	const [getPassword, setPassword] = useState("");
	const [getConfirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState("");

	const resetPasswordHandler = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!email) {
			toast.error("please enter Your Email");
			setLoading(false);
		}

		if (!getPassword) {
			toast.error("please enter Your Password");
			setLoading(false);
		}

		if (!getConfirmPassword) {
			toast.error("please enter Your Confirm Password");
			setLoading(false);
		}

		if (getConfirmPassword?.length < 8 && getConfirmPassword?.length < 8) {
			toast.error("Both passwords must be at least 8 characters");
			setLoading(false);
		}

		if (getPassword != getConfirmPassword) {
			toast.error("Password and confirm password must be same");
			setLoading(false);
		} else {
			var data = new FormData();
			data.append("email", email);
			data.append("password", getPassword);
			data.append("confirm_password", getConfirmPassword);

			setTimeout(async () => {
				setLoading(false);
				let send = await dispatch(SetResetPassword(data, navigate, setLoading));
			}, 600);
		}
	};

	useEffect(() => {
		if (Token) {
			navigate("/");
		}
	}, []);

	return (
		<>
			<Header />
			<section className="sign-in">
				<div className="container">
					<div className="row ">
						<div className="col-lg-4">
							<div className="sign-in-form-wrapper">
								<div className="heading-wrapper">
									<h2>New Password</h2>
									<p>
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy aliquyam erat
									</p>
								</div>
								<div className="sign-form-wrapper">
									<form action="">
										<div className="form-group">
											<label htmlFor="">Email</label>
											<input
												className="form-control"
												type="email"
												placeholder="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Password</label>
											<input
												className="form-control"
												type="Password"
												placeholder="**********"
												value={getPassword}
												onChange={(e) => setPassword(e.target.value)}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Confirm Password</label>
											<input
												className="form-control"
												type="Password"
												placeholder="**********"
												value={getConfirmPassword}
												onChange={(e) => setConfirmPassword(e.target.value)}
											/>
										</div>
										<div className="form-group">
											<button
												className="btn"
												type="submit"
												onClick={(e) => resetPasswordHandler(e)}
												disabled={loading}
											>
												{loading ? <CircleLoad /> : " Change Password"}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-1"></div>
						<div className="col-lg-7 align-self-center"></div>
					</div>
				</div>
			</section>
			{/* sign in ends here */}
			{/* Footer starts here */}
			<Footer />
			{/* Footer ends here */}
		</>
	);
};

export default New_password;
