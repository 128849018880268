import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/faq.css";
import { GetFaqs } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useSelector } from "react-redux";

const Faq = () => {
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const [faqsData, setFaqsData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetFaqs()
      .then((res) => {
        setFaqsData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {userReduxData?.user_type == 2 ? <SellerHeader /> : <Header />}
      {/* About pageHeader starts here */}
      <section className="about_pageheader pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pageheader-content-wrapper">
                <h2>FAQS</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About pageHeader ends here */}
      {/* Faq starts here */}
      <section className="faq-sec">
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              {spinLoad ? (
                <>
                  {[0, 1, 2, 3, 4]?.map((item, index) => {
                    return (
                      <div className="col-lg-12 mt-3" key={index}>
                        <Skeleton count={5} />
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="accordion" id="accordionExample">
                  {faqsData?.map((item, index) => {
                    return (
                      <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={item?.id}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${item?.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse${item?.id}`}
                          >
                            {item?.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${item?.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={item?.id}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.answer,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
      {userReduxData?.user_type == 2 ? <SellerFooter /> : <Footer />}
    </>
  );
};

export default Faq;
