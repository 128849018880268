import React, { useState, useEffect, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/user_profile.css";
import "../../assets/css/Security.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import InputField from "../../components/Form/InputField";
import CircleLoad from "../../components/Loader/CircleLoad";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { PostChangePassword } from "../../network/Network";

const Security = () => {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [loading, setLoading] = useState(false);
  const [passData, setPassData] = useState({
    old_password: "",
    new_pass: "",
    confirm_pass: "",
  });

  function handleChange(e) {
    const value = e.target.value;
    setPassData({
      ...passData,
      [e.target.name]: value,
    });
  }

  const changePasswordHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !passData?.old_password ||
      !passData?.new_pass ||
      !passData?.confirm_pass
    ) {
      setLoading(false);
      toast.error("Please Enter All Field");
      return;
    }
    if (passData?.new_pass !== passData?.confirm_pass) {
      setLoading(false);
      toast.error("New Password and confirm Password are not same ");
      return;
    }
    let data = new FormData();
    data.append("old_password", passData?.old_password);
    data.append("password", passData?.new_pass);
    data.append("password_confirmation", passData?.confirm_pass);
    PostChangePassword(data, Token)
      .then((res) => {
        setLoading(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Header Header_Cate={false} />

      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-7">
              <Logout />
              {/* Account starts here */}
              <div className="account-form-wrapper">
                <div className="account-heading-wrapper">
                  <h3>Security</h3>
                </div>
                <form>
                  <div className="form-group row">
                    <div className="col-lg-5">
                      <label htmlFor="">Current Password</label>
                      <InputField
                        type="password"
                        value={passData?.old_password}
                        onChange={handleChange}
                        placeholder="********"
                        name="old_password"
                        disabled={false}
                      />
                    </div>
                    <div className="col-lg-5">
                      <label htmlFor="">New Password</label>
                      <InputField
                        type="password"
                        value={passData?.new_pass}
                        onChange={handleChange}
                        placeholder="********"
                        name="new_pass"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-5">
                      <label htmlFor="">Confirm Password</label>
                      <InputField
                        type="password"
                        value={passData?.confirm_pass}
                        onChange={handleChange}
                        placeholder="********"
                        name="confirm_pass"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-red"
                      onClick={(e) => changePasswordHandler(e)}
                      disabled={loading}
                    >
                      {loading ? <CircleLoad /> : "Save Changes"}
                    </button>
                  </div>
                </form>
              </div>
              {/* Account ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* My adresses ends here */}
      <Footer />
    </>
  );
};

export default Security;
