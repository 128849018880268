import React, { useEffect, useState } from "react";
import "../../assets/css/seller-dashboard.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { SellerCustomers } from "../../network/Network";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../components/NoRecord/NoRecord";
import ReactPaginate from "react-paginate";

const Seller_customer = () => {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [search, setSearch] = useState("");
  const [customers, setCustomers] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    SellerCustomers(currentPage, Token)
      .then((res) => {
        setCustomers(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8 cus">
              <Seller_tabs_list />

              <div className="row">
                <div className="col-lg-12">
                  <div className="recent-orders-wrapper">
                    <div className="content-wrapper">
                      <h5>Customer</h5>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Customer Email</th>
                            <th scope="col">Phone Number</th>
                            {/* <th scope="col">Code</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {spinLoad ? (
                            <tr>
                              {[0, 1, 2, 3]?.map((item, index) => {
                                return (
                                  <div className="col-md-12 mt-3" key={index}>
                                    <Skeleton count={5} />
                                  </div>
                                );
                              })}
                            </tr>
                          ) : customers?.length > 0 ? (
                            <>
                              {customers?.map((item) => {
                                return (
                                  <tr key={item?.id}>
                                    <th className="num" scope="row">
                                      {item?.name}
                                    </th>
                                    <td className="name">{item?.last_name}</td>
                                    <td className="price">{item?.email}</td>
                                    <td className="item">+{item?.phone}</td>
                                    {/* <td className="item">{item?.code}</td> */}
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <NoRecord />
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="paginations_results">
                      <div className="showing_results">
                        <p>
                          Showing <b>{customers?.length}</b> {/* of over 50  */}
                          <b>results</b>
                        </p>
                      </div>
                      <ul className="paginations">
                        <div className="pagination-container mt-5">
                          <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_customer;
