import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/sign-in.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { forgotPassword } from "../../redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import CircleLoad from "../../components/Loader/CircleLoad";

const Forgot_Password = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");

  const ForgetPasswordHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email) {
      toast.error("please enter Your email");
      setLoading(false);
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("please enter correct email");
      setLoading(false);
      return;
    } else {
      let data = {
        email: email,
      };
      setTimeout(async () => {
        setLoading(false);
        let send = await dispatch(
          forgotPassword(data, navigate, setLoading, email)
        );
      }, 600);
    }
  };

  useEffect(() => {
    if (Token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      {/* sign in starts here */}
      <section className="sign-in">
        <div className="container">
          <div className="row ">
            <div className="col-lg-4">
              <div className="sign-in-form-wrapper">
                <div className="heading-wrapper">
                  <h2>Forgot Password</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy aliquyam erat
                  </p>
                </div>
                <div className="sign-form-wrapper">
                  <form>
                    <div className="form-group">
                      <label htmlFor="">Enter Valid Email Address</label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Enter The Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="btn"
                        onClick={(e) => ForgetPasswordHandler(e)}
                        disabled={loading}
                      >
                        {loading ? <CircleLoad /> : "Code Send"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-7 align-self-center"></div>
          </div>
        </div>
      </section>
      {/* sign in ends here */}
      {/* Footer starts here */}
      <Footer />
      {/* Footer ends here */}
    </>
  );
};

export default Forgot_Password;
