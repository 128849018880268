import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/contact.css";
import CircleLoad from "../../components/Loader/CircleLoad";
import InputField from "../../components/Form/InputField";
import { ContactUsApi } from "../../network/Network";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import SellerHeader from "../../components/SellerHeader/SellerHeader";

const Contact = () => {
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const SiteSettingsRedux = useSelector(
    (state) => state.AuthReducer.siteSettings
  );
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    email: "",
    name: "",
    subject: "",
  });

  function handleChange(e) {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ContactUsHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!userData?.name || !userData?.email || !message) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (userData?.name.length < 3 || userData?.name.length > 20) {
      toast.error("Name must be in between 3 to 20 characters");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userData?.email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }

    if (message.length < 20) {
      toast.error("The message must be at least 20 characters");
      setLoading(false);
      return;
    }
    let data = {
      name: userData?.name,
      email: userData?.email,
      message: message,
      mobile_number: "",
      subject: userData?.subject,
    };
    ContactUsApi(data)
      .then((res) => {
        toast.success(res?.data?.message);
        setLoading(false);
        setMessage("");
        setUserData({
          email: "",
          name: "",
          subject: "",
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {userReduxData?.user_type == 2 ? <SellerHeader /> : <Header />}
      {/* contact form starts here */}
      <section className="contact-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="contact-form-info-wrapper">
                <div className="heading-wrapper">
                  <h4>Location</h4>
                </div>
                <div className="info">
                  <h5>STORE</h5>
                  <p>
                    68 Atlantic Ave St, Brooklyn, NY 90002, USA (+005) 5896 72
                    78 79 support@farmart.com
                  </p>
                </div>
                <div className="info">
                  <h5>WAREHOUSE</h5>
                  <p>
                    172 Richmond Hill Ave St, Stamford, NY 90002, USA (+005)
                    5896 03 04 05
                  </p>
                </div>
                <div className="social-icon-wrapper">
                  <div className="heading-wrapper">
                    <h5>Social</h5>
                  </div>
                  <ul>
                    {SiteSettingsRedux?.facebook ? (
                      <li>
                        <a href={SiteSettingsRedux?.facebook} target="_blank">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                    ) : null}
                    {SiteSettingsRedux?.twitter ? (
                      <li>
                        <a href={SiteSettingsRedux?.twitter} target="_blank">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                    ) : null}
                    {SiteSettingsRedux?.vimeo ? (
                      <li>
                        <a href={SiteSettingsRedux?.vimeo} target="_blank">
                          <i className="fa-brands fa-vimeo-v"></i>
                        </a>
                      </li>
                    ) : null}

                    {SiteSettingsRedux?.googlePlus ? (
                      <li>
                        <a href={SiteSettingsRedux?.googlePlus} target="_blank">
                          <i className="fa-brands fa-google-plus-g"></i>
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8 align-self-center">
              <div className="contact-heading-wrapper">
                <h5>Contact us</h5>
                {/* <p>
									Required fields are marked <span>*</span>
								</p> */}
              </div>
              <div className="contact-form-wrapper">
                <form action="">
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <InputField
                        type="text"
                        value={userData?.name}
                        onChange={handleChange}
                        placeholder="Name"
                        name="name"
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputField
                        type="text"
                        value={userData?.email}
                        onChange={handleChange}
                        placeholder="Your Email"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <InputField
                      type="text"
                      value={userData?.subject}
                      onChange={handleChange}
                      placeholder="Subject (Optional)"
                      name="subject"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      placeholder="Write your message here"
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <button
                      className="btn"
                      type="button"
                      onClick={(e) => ContactUsHandler(e)}
                      disabled={loading}
                    >
                      {loading ? <CircleLoad /> : "Send Message"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact form ends here */}
      {/* map sec starts here */}
      <section className="map-sec">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="100%"
                    height="500"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=henry%20county%20&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                  <a href="https://123movies-a.com"></a>
                  <br />
                  <a href="https://www.embedgooglemap.net"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* map sec ends here */}
      {userReduxData?.user_type == 2 ? <SellerFooter /> : <Footer />}
    </>
  );
};

export default Contact;
