import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
  about_img1,
  about_img2,
  about_img3,
  we_do1,
  we_do2,
} from "../../constants";
import "../../assets/css/about.css";
import { GetAboutPage } from "../../network/Network";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const About = () => {
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const [data, setData] = useState();
  const [spin, setSpin] = useState(false);

  // Get about Page Data
  useEffect(() => {
    setSpin(true);
    GetAboutPage()
      .then((res) => {
        setData(res?.data?.response?.data);
        setSpin(false);
      })
      .catch((err) => {
        setSpin(false);
      });
  }, []);

  return (
    <>
      {userReduxData?.user_type == 2 ? <SellerHeader /> : <Header />}

      {/* <section className="about_pageheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pageheader-content-wrapper">
                <h2>{data?.pageTitle} Apricot</h2>
                <h5>BE PART OF THE CHANGE</h5>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="about-sec">
        <div className="container">
          {/* {spin ? (
            <div className="row">
              {[0, 1, 2, 3]?.map((item, index) => {
                return (
                  <div className="col-md-12 mt-3" key={index}>
                    <Skeleton count={5} />
                  </div>
                );
              })}
            </div>
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: data?.content,
              }}
            />
          )} */}

          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper pe-5">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: data?.section_one_heading,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.section_one_content,
                  }}
                />

                <Link to="/contact" className="btn">
                  Contact us
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-wrapper">
                <figure>
                  <img
                    src={data?.section_one_image}
                    className="img-fluid"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-5 mt-5">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6">
                  <div className="img-wrapper">
                    <figure>
                      <img
                        src={data?.section_two_image_one}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="img-wrapper mt-5 pt-5">
                    <figure>
                      <img
                        src={data?.section_two_image_two}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="content-wrapper ps-5">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: data?.section_two_heading,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.section_two_content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="we-do">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12">
              <div className="content-wrapper text-center">
                <h2
                  className="sec-heading"
                  dangerouslySetInnerHTML={{
                    __html: data?.section_three_heading,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="we-color-wrapper row align-items-center">
            <div className="col-lg-6">
              <div className="img-wrapper">
                <figure>
                  <img
                    src={data?.section_three_image}
                    className="img-fluid"
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <div className="headings-wrapper">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: data?.section_four_heading,
                    }}
                  />
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: data?.section_three_sub_heading,
                    }}
                  />
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.section_three_content,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <div className="headings-wrapper">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: data?.section_four_heading,
                    }}
                  />
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: data?.section_four_sub_heading,
                    }}
                  />
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.section_four_content,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-wrapper">
                <figure>
                  <img
                    src={data?.section_four_image}
                    className="img-fluid"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      {userReduxData?.user_type == 2 ? <SellerFooter /> : <Footer />}
    </>
  );
};

export default About;
