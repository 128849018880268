import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../../constants";
import { GetSiteInfo } from "../../network/Network";
import { logout, WebSiteSettings } from "../../redux/actions/AuthActions";

const SellerHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  const SiteSettingsRedux = useSelector(
    (state) => state.AuthReducer.siteSettings
  );
  const [logout_Load, setLogout_Load] = useState(false);
  const [category, showcategory] = useState(false);
  
  // site Setting
  useEffect(() => {
    GetSiteInfo()
      .then((res) => {
        dispatch(WebSiteSettings(res?.data?.response?.data));
        // setFvtData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const LogoutHandler = (e) => {
    setLogout_Load(true);
    e.preventDefault();
    setTimeout(async () => {
      let x = await dispatch(logout());
      setLogout_Load(false);
      window.location.href = "/sign-in";
    }, 600);
  };

  
  function showcard() {
    showcategory(true);
  }
  function hidecard() {
    showcategory(false);
  }
  return (
    <>
      <header id="header">
        <div className="top-header-wrapper">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-2">
                <div className="logo-wrapper">
                  <Link to="/seller-dashboard">
                    <img src={logo} className="img-fluid" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6"></div>
              <div className="col-lg-4">
                <div className="header-menu-icons-wrapper">
                  <ul style={{ justifyContent: "end" }}>
                    <li className="phone-no">
                      <h6>8800 332 65-66</h6>
                      <span>Support 24/7</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-header-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-6"></div>
              <div className="col-lg-4">
                <div className="menu-items-wrapper">
                  <ul>
                    <li className="nav-item">
                      <Link to="/seller-dashboard" className="nav-link">
                        Dashboard
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about" className="nav-link">
                        About
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact" className="nav-link">
                        Contact
                      </Link>
                    </li>
                    <li className="nav-item">
                      {Token ? (
                        <a
                          className="nav-link"
                          onClick={(e) => LogoutHandler(e)}
                          style={{ cursor: "pointer" }}
                        >
                          {logout_Load ? "Loading" : "Log out"}
                        </a>
                      ) : (
                        <Link to="/sign-in" className="nav-link">
                          Sign in
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default SellerHeader;
