import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/product_page.css";
import "../../assets/css/Seller-inventory.css";
import {
  arrival2,
  arrival3,
  arrival7,
  arrival8,
  product_img,
  review_img,
  seller_img,
  slide1,
  slide2,
  slide3,
  slide4,
} from "../../constants";
import Slider from "react-slick";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useNavigate, useParams } from "react-router-dom";
import { GetProductsDetails } from "../../network/Network";
import { Rating } from "react-simple-star-rating";

const Seller_product_inventory_detail = () => {
  const navigate = useNavigate();
  const paramId = useParams();
  const [productDetails, setProductDetails] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  // product Details
  useEffect(() => {
    setSpinLoad(true);
    GetProductsDetails(paramId?.ID)
      .then((res) => {
        setProductDetails(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [paramId?.ID]);

  return (
    <>
      <SellerHeader />
      <section className="product-detail seller-de">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="head-content-wrapper">
                <h4>Product Inventory detail</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="product-slider-wrapper">
                <div className="single-slider">
                  {productDetails?.multimedia?.map((item, index) => {
                    return (
                      <img src={item?.image_url} className="img-fluid" alt="" />
                    );
                  })}
                </div>
                <div className="main-slider-wrapper">
                  <img
                    src={productDetails?.image_url}
                    className="img-fluid"
                    alt=""
                  />
                  <div className="zoom-wrapper">
                    <h6>
                      <i className="fa fa-search-plus"></i> Mouse over to zoom
                      in
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="product-detail-content-wrapper">
                <div className="detail-wrapper">
                  <h3>{productDetails?.title}</h3>
                  <h4>{productDetails?.short_description}</h4>
                  <div className="reviews-wrapper mb-3">
                    <Rating
                      size={20}
                      readonly={true}
                      allowFraction={true}
                      initialValue={productDetails?.average_rating}
                      // ratingValue={}
                    />
                  </div>
                  <div className="best-seller-wrapper">
                    <h4>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.334"
                          height="19.203"
                          viewBox="0 0 15.334 19.203"
                        >
                          <g
                            id="badge-svgrepo-com_1_"
                            data-name="badge-svgrepo-com (1)"
                            transform="translate(-49.24 0)"
                          >
                            <g
                              id="Group_8003"
                              data-name="Group 8003"
                              transform="translate(49.24 0)"
                            >
                              <path
                                id="Path_16690"
                                data-name="Path 16690"
                                d="M64.378,8.692l-.236-.322a1.014,1.014,0,0,1-.008-1.19l.232-.326a1.023,1.023,0,0,0-.35-1.489l-.354-.189a1.018,1.018,0,0,1-.523-1.069l.067-.4a1.017,1.017,0,0,0-.963-1.187l-.4-.016a1.021,1.021,0,0,1-.935-.735l-.11-.385A1.018,1.018,0,0,0,59.416.736l-.369.157A1.022,1.022,0,0,1,57.883.638l-.267-.3a1.019,1.019,0,0,0-1.528.012l-.263.3a1.025,1.025,0,0,1-1.159.275L54.3.775a1.018,1.018,0,0,0-1.371.672l-.106.385a1.024,1.024,0,0,1-.923.75l-.4.024a1.018,1.018,0,0,0-.943,1.2l.071.393a1.022,1.022,0,0,1-.507,1.076l-.35.2a1.019,1.019,0,0,0-.33,1.493l.236.322a1.014,1.014,0,0,1,.008,1.19l-.232.326a1.023,1.023,0,0,0,.35,1.489l.354.189a1.018,1.018,0,0,1,.523,1.069l-.067.4a1.017,1.017,0,0,0,.963,1.187l.4.016a1.021,1.021,0,0,1,.935.735l.11.385a1.018,1.018,0,0,0,1.383.652l.369-.157a1.022,1.022,0,0,1,1.163.255l.267.3a1.019,1.019,0,0,0,1.528-.012l.263-.3a1.025,1.025,0,0,1,1.159-.275l.369.153a1.018,1.018,0,0,0,1.371-.672l.106-.385a1.024,1.024,0,0,1,.923-.75l.4-.024a1.018,1.018,0,0,0,.943-1.2l-.071-.393a1.022,1.022,0,0,1,.507-1.076l.35-.2A1.018,1.018,0,0,0,64.378,8.692ZM56.9,12.935a5.107,5.107,0,1,1,5.107-5.107A5.112,5.112,0,0,1,56.9,12.935Z"
                                transform="translate(-49.24 0)"
                              />
                              <path
                                id="Path_16691"
                                data-name="Path 16691"
                                d="M293.383,361.126l-.177.012-.086.314a2.148,2.148,0,0,1-2.888,1.418l-.3-.126-.094.11,1.458,2.652a.594.594,0,0,0,.409.295l1.756.326a.591.591,0,0,0,.656-.361l.664-1.658a.58.58,0,0,0-.031-.5Z"
                                transform="translate(-280.386 -346.938)"
                              />
                              <path
                                id="Path_16692"
                                data-name="Path 16692"
                                d="M76.558,364.819a2.107,2.107,0,0,1-.849.177,2.156,2.156,0,0,1-2.063-1.548l-.09-.31-.314-.012-1.32,2.4a.58.58,0,0,0-.031.5l.664,1.658a.591.591,0,0,0,.656.361l1.756-.326a.6.6,0,0,0,.409-.295l1.5-2.723-.012-.012Z"
                                transform="translate(-70.959 -348.86)"
                              />
                              <path
                                id="Path_16693"
                                data-name="Path 16693"
                                d="M162.831,103.637a.644.644,0,0,0-.086-.809c-.475-.436-1.768-.145-1.768-.145h0c-.09.016-.185.035-.287.059,0,0-.448.208,0-1.147s-.3-1.568-.613-1.568-.208.672-.208.672a7.28,7.28,0,0,1-1.312,2.342,1.45,1.45,0,0,0-.31.523.575.575,0,0,0-.373-.138h-.86a.574.574,0,0,0-.574.574v2.294a.574.574,0,0,0,.574.574h.86a.555.555,0,0,0,.318-.1.6.6,0,0,0,.134-.452V104.89c0,.055.008,1.768.994,1.874.354.024.79.043,1.226.035a4.293,4.293,0,0,0,1.155-.035c1.139-.244.856-1.037.856-1.037a.781.781,0,0,0,.267-1.041A.7.7,0,0,0,162.831,103.637Z"
                                transform="translate(-152.227 -96.096)"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      {productDetails?.seller_info?.badge?.name} |{" "}
                      {productDetails?.seller_info?.user?.name}{" "}
                      {productDetails?.seller_info?.user?.last_name}
                    </h4>
                  </div>
                  <div className="price-wrapper">
                    <h3>${productDetails?.latest_price}</h3>
                  </div>
                  <div className="quantity-card-wrapper">
                    <div className="quantity-wrapper">
                      <h4>Stock Available:</h4>
                      <div className="quantity-input-wrapper">
                        <input
                          type="number"
                          value={productDetails?.stock}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="btn-wrapper">
                      <a
                        onClick={() =>
                          navigate(`/seller-edit-product/${productDetails?.id}`)
                        }
                        className="btn"
                      >
                        Edit Product
                      </a>
                    </div>
                  </div>
                  <div className="other-deatils-wrapper">
                    <ul>
                      <li>
                        <span className="property">SKU:</span>
                        {productDetails?.variant?.map((item, index) => {
                          return (
                            <span className="value" key={index}>
                              {item?.sku}
                            </span>
                          );
                        })}
                      </li>
                      <li>
                        <span className="property">Category:</span>
                        <span className="value">
                          {productDetails?.category?.name}
                        </span>
                      </li>
                      {/* <li>
                        <span className="property">Tags:</span>
                        <span className="value">
                          beef, meat, frozen, fresh, organic, foods
                        </span>
                      </li> */}
                      <li>
                        <span className="property">Seller Name :</span>
                        <span className="value">
                          {" "}
                          {productDetails?.seller_info?.user?.name}{" "}
                          {productDetails?.seller_info?.user?.last_name}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
      <SellerFooter />
    </>
  );
};

export default Seller_product_inventory_detail;
