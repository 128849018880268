import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userimg } from "../../constants";
import { ramdomImage } from "../../constants/ConstantFunction";
import { ApiUrl, ImageUrl } from "../../network/ApiUrl";

const Sidebar = (props) => {
  const { uploadLoading, fileupload, handleImageUpload }= props
  const userReduxData = useSelector((state) => state.AuthReducer.users);
  let currentUrl = window.location.href.split("/");
  return (
    <>
      {/* profile heading wrapper */}
      <div className="profile-heading-wrapper">
        <h2>My Profile</h2>
      </div>
      {/* profile heading wrapper */}
      <div className="sidebar-wrapper">
        <div className="user-details-wrapper">
          <div className="user-img-wrapper">
            <figure>
              {
                uploadLoading ? (
                  <img src={fileupload} className="img-fluid" alt="" />
                ) : (
                  !userReduxData?.image || userReduxData?.image == null ? (
                    <img
                    src={`${ramdomImage(
                      `${userReduxData?.name}{""}${userReduxData?.last_name}`
                    )}`}
                    className="image-fluid image-width"
                    alt=""
                  />
                 ) : (
                   <img
                   src={`${
                     fileupload
                       ? fileupload
                       : `${ImageUrl}${"/uploads/"}${userReduxData?.image}`
                   }`}
                   className="image-fluid image-width"
                   alt=""
                 />
                 )
                )
              }
             
            </figure>
            {currentUrl[3] == "my-profile" ? (
              <div className="change-img-wrapper">
                <button>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                    className="profile-upload"
                  />
                   Change image
                  </button>
              </div>
            ) : null}
          </div>
          <div className="user-name-wrapper">
            <h4>
              {userReduxData?.name} {userReduxData?.last_name}
            </h4>
          </div>
        </div>
        <div className="navlink-wrapper">
          <ul>
            <li className="nav-item">
              <Link to="/my-profile" className={ currentUrl[3] == "my-profile" ? "side_active nav-link" : "nav-link"}>
                Account
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/my-order" className={ currentUrl[3] == "my-order" ? "side_active nav-link" : "nav-link"}>
                My Order
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/my-wishlist" className={ currentUrl[3] == "my-wishlist" ? "side_active nav-link" : "nav-link"}>
                My Wishlist
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/change-password" className={ currentUrl[3] == "change-password" ? "side_active nav-link" : "nav-link"}>
                Security
              </Link>
            </li>
            {/* <li className="nav-item">
							<Link to="/Payment" className="nav-link">
								Payment
							</Link>
						</li> */}
            <li className="nav-item">
              <Link to="/my-address" className={ currentUrl[3] == "my-address" ? "side_active nav-link" : "nav-link"}>
                Address
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/share" className={ currentUrl[3] == "share" ? "side_active nav-link" : "nav-link"}>
                Referral Code
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/view-banner-ads" className={ currentUrl[3] == "view-banner-ads" ? "side_active nav-link" : "nav-link"}>
                Banner Ads
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/return-request" className={ currentUrl[3] == "return-request" ? "side_active nav-link" : "nav-link"}>
                Return Request
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
