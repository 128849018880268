import React from "react";
import { Redirect, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoutes({ component: Component, ...restOfProps }) {
  const isLogin = useSelector((state) => state.AuthReducer.isLogin)
  const Token = useSelector((state) => state.AuthReducer.token);
  const userReduxData = useSelector((state) => state.AuthReducer.users);

  // const isLogin = true 
  return <>{isLogin && Token && userReduxData?.user_type == 1 ? <Outlet /> : <Navigate to="/" />}</>;
}

export default ProtectedRoutes;
