import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import "../../assets/css/return.css";
import { GetUserRefundOrders } from "../../network/Network";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../components/NoRecord/NoRecord";
import moment from "moment";

const Return_Requests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [refundOrderData, setRefundOrderData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetUserRefundOrders(currentPage, Token)
      .then((res) => {
        setRefundOrderData(res?.data?.response?.data);
        //   const total = res?.data?.total;
        //   const limit = res?.data?.per_page;
        //   setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <Header Header_Cate={false} />
      {/* My adresses starts here */}
      <section className="my-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Logout />
              {/* return payment starts here */}
              <div className="return-request-wrapper">
                <div className="return-heading-wrapper">
                  <h3>Return Requests</h3>
                </div>
                <div className="return-table-wrapper">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          {refundOrderData.length > 0 ? (
                            <>
                              <th className="Order-h">Order Id</th>
                              <th className="Date-h">Order Date</th>
                              <th className="Return-h">Return Request</th>
                              <th className="Payment-h">Payment Status</th>
                              <th className="Total-h">Total</th>
                              <th className="detail-h"></th>
                            </>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {spinLoad ? (
                          <tr>
                            {[0, 1, 2, 3]?.map((item, index) => {
                              return (
                                <div className="mt-3" key={index}>
                                  <Skeleton count={5} />
                                </div>
                              );
                            })}
                          </tr>
                        ) : refundOrderData?.length > 0 ? (
                          <>
                            {refundOrderData?.map((item) => {
                              return (
                                <tr key={item?.id}>
                                  <td className="order-id">
                                    <span>#{item?.order_detail_id}</span>
                                  </td>
                                  <td className="date-id">
                                    <span>
                                      {" "}
                                      {moment(item?.created_at).format(
                                        "MMMM Do YYYY, h:mm a"
                                      )}
                                    </span>
                                  </td>
                                  <td className="reject-id">
                                    <span className="reject">
                                      {item?.status == 0
                                        ? "Pending"
                                        : item?.status == 1
                                        ? "Accepted"
                                        : item?.status == 2
                                        ? "Rejected"
                                        : item?.status == 3
                                        ? "Product Received"
                                        : item?.status == 4
                                        ? "Voucher generate"
                                        : null}
                                    </span>
                                  </td>
                                  <td className="order-id">
                                    <span className="paid">
                                      Paid{" "}
                                      <i className="fa fa-check-circle"></i>
                                    </span>
                                  </td>
                                  <td className="total">
                                    <span>${item?.order_detail?.total}</span>
                                  </td>
                                  <td className="btns">
                                    <button
                                      onClick={() =>
                                        navigate(`/return-detail/${item?.id}`)
                                      }
                                      className="btn"
                                    >
                                      view Details
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <NoRecord />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* return payment ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* My adresses ends here */}
      <Footer />
    </>
  );
};

export default Return_Requests;
