import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetCouponCode } from "../../network/Network";
import CircleLoad from "../Loader/CircleLoad";

const Coupon = (props) => {
  const { couponData, setCouponData, couponCode, setCouponCode, subTotal } =
    props;
  const Token = useSelector((state) => state.AuthReducer.token);
  const [couponLoading, setCouponLoading] = useState(false);

  const ApplyCouponCodeHandler = (e) => {
    e.preventDefault();
    setCouponLoading(true);
    let data = {
      code: couponCode,
      amount: subTotal,
    };
    GetCouponCode(data, Token)
      .then((res) => {
        setCouponLoading(false);
        setCouponData(res?.data?.response?.data);
        toast.success("Successfully Apply");
        setCouponCode("");
      })
      .catch((err) => {
        setCouponLoading(false);
        if (err?.response?.data?.errors[0]?.message) {
          toast.error(err?.response?.data?.errors[0]?.message);
        }
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <div className="form-group">
      <input
        type="text"
        placeholder="Enter Promo Code"
        className="form-control"
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value)}
      />
      <button
        type="button"
        onClick={(e) => ApplyCouponCodeHandler(e)}
        disabled={couponLoading}
      >
        {couponLoading ? <CircleLoad /> : "SUBMIT"}
      </button>
    </div>
  );
};

export default Coupon;
