import React, { useState } from "react";
import "../../assets/css/seller-account.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { ad1 } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Seller_account = () => {
	const dispatch = useDispatch();
	const Token = useSelector((state) => state.AuthReducer.token);
	const userReduxData = useSelector((state) => state.AuthReducer.users);
	const [sellerProfile, setSellerProfile] = useState()
	return (
		<>
			{/* Header starts here */}
			<SellerHeader />
			{/* Header ends here */}
			<section className="dashboard sob">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<Seller_sidebar />
							{/* <div className="sidebar-subcription-wrapper">
								<div className="sub-heading-wrapper">
									<h5>Subscription Package</h5>
									<a href="/Subscription_Type">Change</a>
								</div>
								<div className="monthly-wrapper">
									<div className="monthly-head-wrapper">
										<h4>Monthly</h4>
										<p>
											Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
											Aliquam tincidun
										</p>
									</div>
									<div className="monthly-per-video-wrapper">
										<h6>Monthly</h6>
										<div className="permonth-wrapper">
											<h5>Per Month</h5>
											<h6>$75.00</h6>
										</div>
									</div>
								</div>
							</div> */}
						</div>
						<div className="col-lg-8">
							<Seller_tabs_list />
							{/* View banner ads starts here */}
							<div className="bussiness_information">
								<div className="headings_warpper">
									<h4>Personal Information</h4>
									<Link to="/seller-info" className="edit-btn">
										Add Info
									</Link>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="bussiness_info_box">
											<div className="detail bname">
												<p className="property">First Name:</p>
												<span className="value">{userReduxData?.name}</span>
											</div>
											<div className="detail TLnum">
												<p className="property">Last Name:</p>
												<span className="value">{userReduxData?.last_name}</span>
											</div>
											<div className="detail Bcnum">
												<p className="property">Age:</p>
												<span className="value">{userReduxData?.seller_info?.age}</span>
											</div>
											<div className="detail bemail">
												<p className="property">City</p>
												<span className="value">{userReduxData?.seller_info?.city}</span>
											</div>
											<div className="detail owner_name">
												<p className="property">Business Type</p>
												<span className="value">{userReduxData?.seller_info?.business_type}</span>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="bussiness_info_box">
											<div className="detail Bnum">
												<p className="property">Country</p>
												<span className="value">{userReduxData?.seller_info?.country}</span>
											</div>
											<div className="detail btype">
												<p className="property">Phone</p>
												<span className="value">{userReduxData?.phone}</span>
											</div>
											<div className="detail Baddress">
												<p className="property">Email</p>
												<span className="value">{userReduxData?.email}</span>
											</div>
											<div className="detail pcategroy">
												<p className="property">Company / Business name</p>
												<span className="value">{userReduxData?.seller_info?.business_name}</span>
											</div>
											<div className="detail pcategroy">
												<p className="property">Designation</p>
												<span className="value">{userReduxData?.seller_info?.designation}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="bussiness_information">
								<div className="headings_warpper">
									<h4>Business information</h4>
									<Link to="/seller-business-info" className="edit-btn">
										Add Info
									</Link>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="bussiness_info_box">
											<div className="detail bname">
												<p className="property">Business name</p>
												<span className="value">{userReduxData?.seller_info?.business_name}</span>
											</div>
											<div className="detail TLnum">
												<p className="property">Trade LIcence Number</p>
												<span className="value">{userReduxData?.seller_info?.trade_license_number}</span>
											</div>
											<div className="detail Bcnum">
												<p className="property">Business Contact Number</p>
												<span className="value">{userReduxData?.seller_info?.business_contact_no}</span>
											</div>
											<div className="detail bemail">
												<p className="property">Email</p>
												<span className="value">{userReduxData?.seller_info?.business_contact_email}</span>
											</div>
											<div className="detail owner_name">
												<p className="property">Owner Name</p>
												<span className="value">{userReduxData?.seller_info?.owner_name}</span>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="bussiness_info_box">
											<div className="detail Bnum">
												<p className="property">Business Contact Email</p>
												<span className="value">{userReduxData?.seller_info?.business_contact_email}</span>
											</div>
											<div className="detail btype">
												<p className="property">Business Type</p>
												<span className="value">{userReduxData?.seller_info?.business_contact_no}</span>
											</div>
											<div className="detail Baddress">
												<p className="property">Head Office Address</p>
												<span className="value">
												{userReduxData?.seller_info?.Head_office_address} 
													
												</span>
											</div>
											<div className="detail pcategroy">
												<p className="property">Main Products Catagories</p>
												<span className="value">{userReduxData?.seller_info?.main_category}  </span>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* View banner ads ends here */}
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<SellerFooter />
			{/* Footer ends here */}
		</>
	);
};

export default Seller_account;
