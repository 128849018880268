import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { seller1 } from "../../constants";
import { GetAllProductsApi, PostAddWishList } from "../../network/Network";
import { toast } from "react-toastify";
import CircleLoad from "../Loader/CircleLoad";
import { Rating } from "react-simple-star-rating";

const NewArrival = () => {
  const navigate = useNavigate();
  const CategoriesData = useSelector((state) => state.AuthReducer.categories);
  const Token = useSelector((state) => state.AuthReducer.token);
  // const [cate_id, setCate_id] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [sub_category_id, setSub_category_id] = useState("");
  const [newArrivalData, setNewArrivalData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [wishLoading, setWishLoading] = useState(false);

  const NewArrivalWithFilter = (e = null, type) => {
    if (e) {
      e.preventDefault();
    }
    setLoader(true);
    let data;
    if (type == "all") {
      data = {
        category_id: " ",
        sub_category_id: " ",
        search: " ",
        hot: " ",
        feature: " ",
        discounted: " ",
        new_arrival: "1",
        vendor_id: " ",
      };
    } else {
      data = {
        category_id: category_id ? category_id : " ",
        sub_category_id: sub_category_id ? sub_category_id : " ",
        search: " ",
        hot: " ",
        feature: " ",
        discounted: " ",
        new_arrival: "1",
        vendor_id: " ",
      };
    }
    GetAllProductsApi(currentPage, data)
      .then((res) => {
        setNewArrivalData(res?.data?.response?.data?.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  // Get ALL plants
  useEffect(() => {
    NewArrivalWithFilter(null);
  }, [category_id]);

  const seller_category = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
        },
      },
    ],
  };

  const AddWishListHandler = (e, id) => {
    e.preventDefault();
    setWishLoading(true);
    let data = {
      product_id: id,
    };
    PostAddWishList(data, Token)
      .then((res) => {
        setWishLoading(false);
        // setActive(!active);
        toast.success("Product Add to your Wishlist");
      })
      .catch((err) => {
        setWishLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <section className="best-seller">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="best-heading-wrapper">
              <h2>New Arrival</h2>
              <div className="tab-wrapper">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() =>
                        navigate("/shop", {
                          state: {
                            paramNew_arrival: 1,
                            type: "all",
                          },
                        })
                      }
                      // onClick={(e)=>NewArrivalWithFilter(e, "all")}
                    >
                      All
                    </button>
                  </li>
                  {CategoriesData?.map((item, index) => {
                    return (
                      <li className="nav-item" role="presentation" key={index}>
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                          // onClick={(e)=>{NewArrivalWithFilter(e, "cate");setCate_id(item?.id);setCategory_id(item?.id)}}
                          onClick={() =>
                            navigate("/shop", {
                              state: {
                                paramCat_id: item?.id,
                                paramNew_arrival: 1,
                                type: "cate",
                              },
                            })
                          }
                        >
                          {item?.name}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="tab-content pt-5" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row">
                  {loader ? (
                    <>
                      {[0, 1, 2, 3]?.map((item, index) => {
                        return (
                          <div className="col-lg-3 mt-3" key={index}>
                            <Skeleton count={5} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <Slider {...seller_category}>
                      {newArrivalData?.map((item, index) => {
                        return (
                          <div className="col-lg-3 px-0" key={index}>
                            <div className="product-card-wrapper">
                              <div className="product-img-wrapper">
                                <Link to={`${"/product"}/${item?.id}`}>
                                  <figure>
                                    <img
                                      src={item?.image_url}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </figure>
                                </Link>
                              </div>
                              <div className="product-content-wrapper">
                                <Link to={`${"/product"}/${item?.id}`}>
                                  <h5>{item?.title}</h5>
                                </Link>
                                <span className="ice">
                                  {item?.short_description}
                                </span>
                                <div className="rating-wrapper mt-1 mb-2">
                                  <Rating
                                    size={20}
                                    readonly={true}
                                    allowFraction={true}
                                    initialValue={item?.average_rating}
                                  />
                                </div>
                                {/* <h6>5kg</h6> */}
                                <h4>${item?.latest_price}</h4>

                                {/* <div className="counter-total-wrapper">
                                              <input type="number" min={0} />
                                              <h6>
                                                Total: <span>$38.5</span>
                                              </h6>
                                            </div> */}
                                <a
                                  onClick={(e) =>
                                    AddWishListHandler(e, item?.id)
                                  }
                                  className="btn"
                                  disabled={wishLoading}
                                >
                                  {wishLoading ? (
                                    <CircleLoad />
                                  ) : (
                                    "Add To Wishlist"
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewArrival;
