import {
	AUTH,
	REGISTER,
	USER,
	PROFILE_UPDATE,
	CATEGORIES,
	PRODUCTS,
	GET_ALL_PRODUCTS,
	PRODUCT,
	REVIEWS,
	SUBMIT,
	WISH_LISTS,
	ADD,
	STORE,
	CONTACT,
	ABOUT_US,
	PAGES,
	WISHLIST,
	REMOVE,
	ADDRESS,
	ORDER,
	PLACE_ORDER,
	COUPON,
	APPLY,
	SITE,
	SITE_INFO,
	FAQS,
	GET_LIST,
	DELETE,
	DEFAULT_ADDRESS,
	UPDATE,
	GET_ORDER,
	BADGES,
	GET_SELLER_PROFILE,
	GET_SELLER_BY_ID,
	GET_ORDERS,
	UPDATE_BUSINESS_PROFILE,
	SUB_CATEGORIES,
	GET_PRODUCTS,
	PRODUCT_ATTRIBUTE,
	GET_REVIEWS,
	FEEDBACK,
	FROGOT_PASSWORD,
	BANNERS,
	GET_HOME_SLIDERS,
	UPDATE_PROFILE,
	RETURN,
	CHANGE_ORDER_STATUS,
	// Apricot
	BLOGS,
	GET_KIGI_FAQS,
	NEWS_LETTER,
	KIGI_NURSERY,
	GET_KIGI_SITESETTINGS,
	CUSTOMER,
	URLS,
	BRANDS,
	CAMPAIGN,
	GET_CAMPAIGN,
	FIND_CAMPAIGN,
	DONATE,
	MAKE_DONATION,
	MEMBERSHIP,
	GET_MEMBERSHIP,
	FIND_MEMBERSHIP,
	CSA_PRODUCT_PRICE,
	DETAILS,
	RATING,
	LIST,
	CHANGE_PASSWORD,
	BLOG_DETAILS,
	CONTACTUS,
	GALLERY,
	GET_IMAGES,
	FILTER_TYPES,
	GIFT_CODE,
	SEND_GIFT_CODE,
	GIFT_THEME,
	GET_GIT_THEME,
	GET_VIDEOS,
	MY_DONATION,
	MAKE_DEFAULT,
	GET_GIFT_CODE,
	PLACE,
	RESET_PASSWORD,
	SERVICES,
	GET_SERVICES,
	HOW_IT_WORKS,
	GET_HOME_IT_WORKS,
	PRODUCT_DATE,
	GET_KIGI_SOCIAL_MEDIA,
	FREQUENTLY_BOUGHT,
	EDIT_ADDRESS,

	// hoho end
	LOGIN,
	FORGOT,
	// End Franchise s
	CAR,
	INSTANTOFFER,
	ALL_LISTING,
	GET_CUSTOMERS,
	GET_RETURNs,
	RETURN_SELLER,
	ONBOARDING,
	GET_PRODUCT,

	// FROGOT_PASSWORD,
} from "../network/Endpoint";

import {
	doGet,
	doPost,
	doPatch,
	doDelete,
	doPostProfilePictureUpload,
	doPut,
	doPutPictureUpload,
	doPutProfilePictureUpload,
} from "./Config";

export const postUserSignUp = (data) => {
	return doPostProfilePictureUpload(`${AUTH}${REGISTER}`, data);
};

export const postSignIn = (data) => {
	return doPost(`${AUTH}${LOGIN}`, { ...data });
};

export const UserProfileUpdate = (data, token) => {
	return doPost(`${USER}${PROFILE_UPDATE}`, data, token);
};

export const GetAllCategories = () => {
	return doGet(`${CATEGORIES}`);
};

export const GetAllProductsApi = (currentPage, data) => {
	// return doGet(
	// 	`${PRODUCTS}${GET_ALL_PRODUCTS}${"?category"}=${
	// 		data?.cate_id ? data?.cate_id : " "
	// 	}${"&hot"}=${data?.hot ? data?.hot : " "}${"&feature"}=${
	// 		data?.feature ? data?.feature : " "
	// 	}${"&discounted"}=${
	// 		data?.discounted ? data?.discounted : " "
	// 	}${"&new_arrival"}=${
	// 		data?.new_arrival ? data?.new_arrival : " "
	// 	}${"&vendor_id"}=${data?.vendor_id ? data?.vendor_id : " "}${"&search"}=${
	// 		data?.search ? data?.search : " "
	// 	}${"&page"}=${currentPage}${"&sort_by"}=${
	// 		data?.sort_by ? data?.sort_by : " "
	// 	}`,
	// );
	return doGet(
		`${PRODUCTS}${GET_ALL_PRODUCTS}${"?category_id"}=${
			data?.category_id ? data?.category_id : " "
		}${"&sub_category_id"}=${
			data?.sub_category_id ? data?.sub_category_id : " "
		}${"&hot"}=${data?.hot ? data?.hot : " "}${"&feature"}=${
			data?.feature ? data?.feature : " "
		}${"&discounted"}=${
			data?.discounted ? data?.discounted : " "
		}${"&new_arrival"}=${
			data?.new_arrival ? data?.new_arrival : " "
		}${"&vendor_id"}=${data?.vendor_id ? data?.vendor_id : " "}${"&search"}=${
			data?.search ? data?.search : " "
		}${"&page"}=${currentPage}${"&sort_by"}=${
			data?.sort_by ? data?.sort_by : " "
		}`,
	);
};

export const GetProductsDetails = (id) => {
	return doGet(`${PRODUCTS}${PRODUCT}/${id}`);
};

export const GetSellerProductsDetails = (id, token) => {
	return doGet(`${USER}${GET_PRODUCT}/${id}`, token);
};

export const PostProductReviewApi = (data, token) => {
	return doPost(`${USER}${REVIEWS}${SUBMIT}`, data, token);
};

export const GetProductRatingApi = (id) => {
	return doGet(`${REVIEWS}${PRODUCT}/${id}`);
};

export const GetAllWishList = (currentPage, token) => {
	return doGet(`${USER}${WISH_LISTS}${"?page"}=${currentPage}`, token);
};

export const PostWishListDelete = (data, token) => {
	return doPost(`${USER}${WISHLIST}${REMOVE}`, data, token);
};

export const PostAddWishList = (data, token) => {
	return doPost(`${USER}${WISH_LISTS}${ADD}`, data, token);
};

export const ContactUsApi = (data) => {
	return doPost(`${CONTACT}${STORE}`, data);
};

export const PostAddressApi = (data, token) => {
	return doPost(`${USER}${ADDRESS}${STORE}`, data, token);
};

export const GetAllAddress = (token) => {
	return doGet(`${USER}${ADDRESS}`, token);
};

export const PostDeleteAddress = (data, token) => {
	return doPost(`${USER}${ADDRESS}${DELETE}`, data, token);
};

export const PostPlaceOrder = (finalData, token) => {
	return doPost(`${USER}${ORDER}${PLACE_ORDER}`, finalData, token);
};

export const GetCouponCode = (data, token) => {
	return doPost(`${USER}${COUPON}${APPLY}`,data, token);
};

export const GetSiteInfo = () => {
	return doGet(`${SITE}${SITE_INFO}`);
};

export const GetFaqs = () => {
	return doGet(`${FAQS}`);
};

export const GetAllOrder = (currentPage, token) => {
	return doGet(`${USER}${ORDER}${GET_LIST}${"?page"}=${currentPage}`, token);
};

export const GetOrderDetail = (id, token) => {
	return doGet(`${USER}${ORDER}${GET_ORDER}/${id}`, token);
};

export const PostMakeDefaultAddress = (data, token) => {
	return doPost(`${USER}${ADDRESS}${DEFAULT_ADDRESS}`, data, token);
};

export const EditAddress = (data, token) => {
	return doPost(`${USER}${ADDRESS}${UPDATE}`, data, token);
};

export const GetBadgeLevel = () => {
	return doGet(`${SITE}${BADGES}`);
};

export const GetSellerProfile = (Token) => {
	return doGet(`${USER}${GET_SELLER_PROFILE}`, Token);
};

export const GetSellerById = (id) => {
	return doGet(`${USER}${GET_SELLER_BY_ID}/${id}`);
};

export const GetSellerOrders = (currentPage, data, Token) => {
	return doGet(
		`${USER}${GET_ORDERS}${"?page"}=${currentPage}${"&search="}${
			data ? data : " "
		}`,
		Token,
	);
};

export const GetSellerOrdersById = (id, Token) => {
	return doGet(`${USER}${"/get-order"}/${id}`, Token);
};

export const PostSellerBusinessInfo = (data, token) => {
	return doPost(`${USER}${UPDATE_BUSINESS_PROFILE}`, data, token);
};

export const PostSellerProduct = (data, Token) => {
	return doPostProfilePictureUpload(`${USER}${PRODUCT}${STORE}`, data, Token);
};

export const updateSellerProduct = (data, Token) => {
	return doPost(`${USER}${PRODUCT}/update`, data, Token);
};

export const GetSellerProductAttributes = (Token) => {
	return doGet(`${USER}${PRODUCT}${PRODUCT_ATTRIBUTE}`, Token);
};

export const GetSellerProductSpecificAttributes = (Token) => {
	return doGet(`${USER}${PRODUCT}${PRODUCT_ATTRIBUTE}`, Token);
};

export const GetSubCategories = () => {
	return doGet(`${SUB_CATEGORIES}`);
};

export const GetSellerProducts = (currentPage, Token, search) => {
	return doGet(`${USER}${GET_PRODUCTS}${"?search"}=${search ? search : ""}${"&page"}=${currentPage}`, Token);
};

export const DeleteSellerProduct = (ID, data, Token) => {
	return doPost(`${USER}${PRODUCT}${DELETE}/${ID}`, data, Token);
};

export const SellerCustomers = (currentPage, Token) => {
	return doGet(`${USER}${GET_CUSTOMERS}${"?page"}=${currentPage}`, Token);
};

export const SellerReviews = (currentPage, Token) => {
	return doGet(`${USER}${GET_REVIEWS}${"?page"}=${currentPage}`, Token);
};

export const PostFeedBack = (data, Token) => {
	return doPost(`${USER}${REVIEWS}${FEEDBACK}`, data, Token);
};

export const forgotPasswordApi = (data) => {
	return doPost(`${AUTH}${FROGOT_PASSWORD}`, data);
};

export const PostUserBanner = (data, Token) => {
	return doPost(`${USER}${BANNERS}${STORE}`, data, Token);
};

export const PostUpdateBanner = (ID, data, Token) => {
	return doPostProfilePictureUpload(
		`${USER}${BANNERS}${UPDATE}/${ID}`,
		data,
		Token,
	);
};

export const GetHomeBanner = (Token) => {
	return doGet(`${BANNERS}${GET_HOME_SLIDERS}`, Token);
};

export const PostSellerProfileUpdate = (data, Token) => {
	return doPost(`${USER}${UPDATE_PROFILE}`, data, Token);
};

export const GetUserRefundOrders = (currentPage, Token) => {
	return doGet(`${USER}${RETURN}${"?page"}=${currentPage}`, Token);
};

export const GetUserRefundOrderDetails = (id, Token) => {
	return doGet(`${USER}${RETURN}/${id}`, Token);
};

export const GetUserRefundOrderDetailsSeller = (id, Token) => {
	return doGet(`${USER}${RETURN}/${id}`, Token);
};

export const PostUserReturnOrder = (data, Token) => {
	return doPost(`${USER}${RETURN}${SUBMIT}`, data, Token);
};

export const sellerAcceptRefund = (data, Token) => {
	return doPost(`${USER}${RETURN_SELLER}/accept-return`, data, Token);
};

export const sellerRejectRefund = (data, Token) => {
	return doPost(`${USER}${RETURN_SELLER}/reject-return`, data, Token);
};

export const GetSellerBannerList = (currentPage, Token) => {
	return doGet(`${USER}${BANNERS}${"?page"}=${currentPage}`, Token);
};

export const PostDeleteBanner = (ID, data, Token) => {
	return doPost(`${USER}${BANNERS}${DELETE}/${ID}`, data, Token);
};

export const PostChangePassword = (data, token) => {
	return doPost(`${USER}${CHANGE_PASSWORD}`, data, token);
};

export const ChangeShippingStatus = (data, token) => {
	return doPost(`${USER}${CHANGE_ORDER_STATUS}`, data, token);
};

export const GetAboutPage = () => {
	return doGet(`${ABOUT_US}`);
};


export const GetPrivacyPolicy = () => {
	return doGet(`${PAGES}${"/privacy-policy"}`);
};

export const GetLegalPolicy = () => {
	return doGet(`${PAGES}${"/legal-policy"}`);
};

export const GetTerms = () => {
	return doGet(`${PAGES}${"/terms-and-condition"}`);
};

export const GetAdvertise = () => {
	return doGet(`${PAGES}${"/advertise-with-us"}`);
};

export const GetSellerRefundOrders = (currentPage, Token) => {
	return doGet(
		`${USER}${"/return-seller"}${"/list"}${"?page"}=${currentPage}`,
		Token,
	);
};
// export const GetSellerRefundOrderDetail = (id, Token) => {
//   return doGet(`${USER}${RETURN}${RETURN}/${id}`, Token);
// };

export const GetSellerRefundOrderDetail = (id, Token) => {
	return doGet(`${USER}${RETURN_SELLER}/${id}`, Token);
};

export const GetSellerRefundOrderDetailTest = (id, Token) => {
	return doGet(`${USER}${RETURN}/${id}`, Token);
};

// Apricot

export const GetBlogs = () => {
	return doGet(`${BLOGS}`);
};

export const PostNewsLetter = (data) => {
	return doPost(`${NEWS_LETTER}${ADD}`, data);
};

export const GetSiteSettingsApi = (type) => {
	return doGet(
		`${KIGI_NURSERY}${GET_KIGI_SITESETTINGS}${`?type`}=${type ? type : " "}`,
	);
};

export const GetCategories = () => {
	return doGet(`${CATEGORIES}`);
};

export const GetUrls = () => {
	return doGet(`${URLS}`);
};

export const GetBrands = () => {
	return doGet(`${BRANDS}`);
};

export const GetCampaign = () => {
	return doGet(`${CAMPAIGN}${GET_CAMPAIGN}`);
};

export const PostCampaignDetails = (id) => {
	return doPost(`${CAMPAIGN}${FIND_CAMPAIGN}${"/"}${id}`);
};

export const PostCampaignDonation = (data, token) => {
	return doPost(`${DONATE}${MAKE_DONATION}`, data, token);
};

export const GetMembership = () => {
	return doGet(`${MEMBERSHIP}${GET_MEMBERSHIP}`);
};

export const PostMembershipById = (id) => {
	return doPost(`${MEMBERSHIP}${FIND_MEMBERSHIP}/${id}`);
};

export const PostCSAProductPriceById = (id) => {
	return doPost(`${MEMBERSHIP}${CSA_PRODUCT_PRICE}/${id}`);
};

export const GetPlantsApi = (currentPage, data) => {
	return doGet(
		`${CATEGORIES}${PRODUCTS}${data?.name}${"?color"}=${
			data?.color ? data?.color : " "
		}${"&species"}=${data?.species ? data?.species : " "}${"&leaf_type"}=${
			data?.leaf_type ? data?.leaf_type : " "
		}${"&growth_rate"}=${
			data?.growth_rate ? data?.growth_rate : " "
		}${"&hardiness_zone"}=${
			data?.hardiness_zone ? data?.hardiness_zone : " "
		}${"&start_price"}=${
			data?.start_price ? data?.start_price : " "
		}${"&end_price"}=${
			data?.end_price ? data?.end_price : " "
		}${"&category_id"}=${data?.category ? data?.category : " "}${"&search"}=${
			data?.search ? data?.search : " "
		}${"&page"}=${currentPage}`,
	);
};

export const GetProductDetailsApi = (id) => {
	return doGet(`${PRODUCTS}${DETAILS}/${id}`);
};

export const UpdateProfile = (data, token) => {
	return doPostProfilePictureUpload(
		`${CUSTOMER}${UPDATE_PROFILE}`,
		data,
		token,
	);
};

export const GetBlogDetails = (id) => {
	return doPost(`${BLOGS}${BLOG_DETAILS}/${id}`);
};

export const GetGalleryImage = () => {
	return doGet(`${"/kigi-nursery"}${GALLERY}${GET_IMAGES}`);
};

export const GetAllFilterApi = () => {
	return doGet(`${PRODUCTS}${FILTER_TYPES}`);
};

export const PostGiftApi = (data) => {
	return doPost(`${GIFT_CODE}${SEND_GIFT_CODE}`, data);
};

export const GetGiftThemesApi = () => {
	return doGet(`${GIFT_THEME}${GET_GIT_THEME}`);
};

export const GetVideosApi = () => {
	return doGet(`${"/kigi-nursery"}${GALLERY}${GET_VIDEOS}`);
};

export const MyDonations = (currentPage, token) => {
	return doGet(`${CUSTOMER}${MY_DONATION}${"?page"}=${currentPage}`, token);
};

export const GetGiftCode = (data, token) => {
	return doGet(
		`${GIFT_CODE}${GET_GIFT_CODE}${"?giftCode"}=${data?.code}`,
		token,
	);
};

export const ResetPasswordApi = (data) => {
	return doPost(`${AUTH}${RESET_PASSWORD}`, data);
};

export const VerifyOtpApi = (data) => {
	return doPost(`${AUTH}/verification`, data);
};

export const GetHomeServices = () => {
	return doGet(`${SERVICES}${GET_SERVICES}`);
};

export const GetWorkStep = () => {
	return doGet(`${HOW_IT_WORKS}${GET_HOME_IT_WORKS}`);
};

export const GetProductDate = () => {
	return doGet(`${PRODUCTS}${PRODUCT_DATE}`);
};

export const GetSocialMedia = () => {
	return doGet(`${KIGI_NURSERY}${GET_KIGI_SOCIAL_MEDIA}`);
};

export const GetFrequentProducts = (id) => {
	return doGet(`${PRODUCTS}${FREQUENTLY_BOUGHT}${"?product_id"}=${id}`);
};

export const forgotPasswordOtp = (data) => {
	return doPost(`${AUTH}${RESET_PASSWORD}`, data);
};

export const onBoardingApi = (data,token) => {
	return doPost(`${USER}${ONBOARDING}`,data, token);
};

export const addColorVariation = (data, Token) => {
	return doPost(`${USER}${PRODUCT}/add-attribute-value`, data, Token);
};

export const addWeightVariation = (data, Token) => {
	return doPost(`${USER}${PRODUCT}/add-attribute-value`, data, Token);
};

export const OnBoardCompleteApi = (data, Token) => {
	return doPost(`${USER}${"/on-boarding-return"}`, data, Token);
};

// ------ end

