import React, { useState } from "react";
import "../../assets/css/accept.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Seller_sidebar from "./Seller-sidebar/Seller_sidebar";
import Seller_tabs_list from "./Seller-sidebar/Seller_tabs_list";
import { ad1 } from "../../constants";
import SellerHeader from "../../components/SellerHeader/SellerHeader";
import SellerFooter from "../../components/SellerFooter/SellerFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CircleLoad from "../../components/Loader/CircleLoad";
import { PostAddressApi, sellerRejectRefund } from "../../network/Network";

const Seller_reject_refund = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [getRefundComment, setRefundComment] = useState("");
  let currentUrl = window.location.href.split("/");

  function handleChange(e) {
    setRefundComment(e.target.value);
  }

  const rejectRefundHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!getRefundComment) {
      toast.error("Please Enter comment");
      setLoading(false);
      return;
    } else {
      let rejectData = new FormData();
      rejectData.append("id", currentUrl[4]);
      rejectData.append("status", 2);
      rejectData.append("reject_comment", getRefundComment);
      sellerRejectRefund(rejectData, Token)
        .then((res) => {
          toast.success(res?.data?.message);
          setLoading(false);
          setRefundComment("");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {/* Header starts here */}
      <SellerHeader />
      {/* Header ends here */}
      <section className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Seller_sidebar />
            </div>
            <div className="col-lg-8">
              <Seller_tabs_list />
              {/* Accept refund form starts here */}
              <div className="accept-refund-wrapper">
                <button className="close">
                  <i className="fa fa-close"></i>
                </button>
                <div className="accept-form-wrapper">
                  <div className="accept-head-wrapper">
                    <h3>Reject Refund</h3>
                    <div className="accept-date-wrapper">
                      <h6>Refund Request Date</h6>
                      <span className="date">10/11/22</span>
                    </div>
                  </div>
                  <div
                    className="accept-schedule-wrapper"
                    style={{
                      borderTop: "1px solid #dedede",
                      marginTop: "15px",
                      paddingTop: "20px",
                    }}
                  >
                    <div className="schedule-form-wrapper pt-2">
                      <form action="">
                        <div className="form-group row">
                          <div className="col-lg-12">
                            <label htmlFor="">Reject Refund Comment</label>
                            <textarea
                              className="form-control"
                              id=""
                              cols="30"
                              rows="5"
                              onChange={handleChange}
                              name="refundComment"
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-group pt-3">
                          <button
                            className="btn form-control"
                            onClick={(e) => rejectRefundHandler(e)}
                            disabled={loading}
                          >
                            {loading ? <CircleLoad /> : "   Reject Request"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* Accept refund form ends here */}
            </div>
          </div>
        </div>
      </section>
      {/* Footer starts here */}
      <SellerFooter />
      {/* Footer ends here */}
    </>
  );
};

export default Seller_reject_refund;
